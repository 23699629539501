import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import getValidationErros from '../../utils/getValidationErros';
import { Page } from '../../types/Page';
import { useLocation } from 'react-router-dom';
import { BlockResourcesByPlan } from '../../utils/functions';


const FormPixelMeta: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);
    const [ dataPage, setDatapage ] = useState<any>(page.page);
    const location = useLocation();
    const formPixel = useRef<FormHandles>(null);

    const unlocked = BlockResourcesByPlan('formFonts', Number(auth.user?.planSignature));

    useEffect(() =>{
      const iniData= {
        faceCodePix: page.page?.faceCodePix ? page.page?.faceCodePix : ''
      }
      setDatapage(iniData);
    }, [page, location.pathname])

    const handleChangeSeo = useCallback(async (data: Page) => {
      if(unlocked) { 
        try {
            setLoadSave(true);
            formPixel.current?.setErrors({});
            const schema = Yup.object().shape({
                faceCodePix: Yup.string().max(20, 'Máximo de 20 caracteres'),
            });
            await schema.validate(data, { abortEarly: false, });

            const datasend = {
              ...page.page,
              'faceCodePix': data.faceCodePix,
              'idUser': auth.user?.id
            }

           
            const resp = await api.post('/page_update.php', datasend);
            if(resp.status === 200){
              const newpage = { ...page.page, 'faceCodePix': data.faceCodePix};          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
        } catch (error: any) { 
            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error);
                formPixel.current?.setErrors(errors);
                setLoadSave(false);
                return;
            }
            if(error.response.status === 500){
              toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
              localStorage.setItem('@navtome:token', '');
              window.location.href = '/';
            } 
            toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
      } else {
        toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
      }
    }, [page, auth.user?.id, unlocked]);

    return(
        <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Disponível a partir do plano pro">            
        <h4>Pixel <span className="desctitle">Integre o seu pixel a sua página.</span></h4>    
        <Form initialData={dataPage} ref={formPixel}  onSubmit={handleChangeSeo}>
          <fieldset>                   
            <Input placeholder='Código Pixel:' type="text" name="faceCodePix" maxLength={20} /> 
          </fieldset> 
          <div className='spacebutton'>
            <div className='miniaviso'>    
              Você vai encontrar este código na sua conta de anúncios / configurações do negócio / fonte de dados / pixels. 
              Selecione o pixel desejado, no topo encontre <strong>identificação</strong>, copie o código e cole aqui.
            </div>                     
            <button type='submit' disabled={loadsave} className='save'>
              {loadsave ? 
                <ActivityIndicatorBtn />
              :
                <>Salvar</>
              }                        
            </button>
          </div>     
        </Form>
        </div>
    );
}

export default FormPixelMeta;