import React from 'react';
import TopUser from '../../components/TopUser';
import { ScrollArea } from '../../styles/global';
import { BoxContact } from './styles';



const Contact: React.FC = () => {


    return (
    <>
        <div className='simpleflex minHsimpleflex'>            
            <h1 className='marg0'>
                Fale conosco
                <span>Utilize nossos canais de atendimento</span>
            </h1>
            <TopUser />
        </div>

        <div className="contentpage"> 
            <ScrollArea size={7}> 

            <div className="centerall">

            <BoxContact>
            Caso tenha alguma dúvida sugestão ou deseja relatar um problema, 
            por favor utilize nossos canais de atendimento, teremos o maior 
            prazer em ajudar:
            <div className='opcoesdecontato'>
                <a target="_blank" rel="noreferrer" title="Fale conosco no whatsapp" href="https://api.whatsapp.com/send?phone=5533988017451&amp;text=Ol%C3%A1%20gostaria%20de%20suporte%20sobre%20o%20Navto.me"><div id="whatsapp"></div></a>
                <a target="_blank" rel="noreferrer" title="Fale conosco pelo chat" href='https://jivo.chat/pDcp68DnKt' id="jivo_custom_widget"><span></span></a>
            </div>  
            </BoxContact>


            </div>

            <div className="gradfooter"></div>
            </ScrollArea>
        </div>        
    </>
    );
}

export default Contact;