import { api } from '../hooks/useApi';
import { Page } from './../types/Page';

export const UrlPatternValidation = (url: string) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    return regex.test(url);
};

export const FormatUrl = (url: string) => {
    if(url === ''){
        return '';
    } else if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
        return url.trim();
    }    
    return 'https://'+url.trim();
};

export const GettUrlFromIframe = (iframe: string) => {
    if(iframe.trim().includes('<iframe ')){
        const matches = iframe.match(/src="([^"]+)"/); 
        if(matches){
            return matches[1];
        }
    }
    return FormatUrl(iframe);
};

export const StripTags = (str: string) => {
    const strippedString = str.replace(/(<([^>]+)>)/gi, "");
    return strippedString;
}

export const CheckTitle = (title: string) => {    
    if (title.length >= 3 && title.length < 50) {
        return true;
    }    
    return false
};

export const AjustaUrl = (e:string) => {
    return e
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // eslint-disable-line 
    .replace(/\-\-+/g, '-')         // eslint-disable-line
    .trim();
};

export const IsEmpty = (str : string) => {
    return (!str || str.length === 0 );
}

interface ObjectLiteral { [key: string]: number; }
export const LimitPagesByPlan = (e: number) => {
    /*
    Limite de páginas por planos 
    0 = Plano gratis    : até 1 página
    1 = Plano básico    : até 2 páginas
    2 = Plano avançado  : até 3 páginas
    3 = Plano pro       : até 5 páginas
    */
    const Plans : ObjectLiteral  = {
      0: 1,
      1: 2,
      2: 3,
      3: 5
    };
    return Plans[e] || Plans[0];
}

interface ObjectLiteralNamePlans { [key: number]: string; }
export const NamesPlans = (e: number) => {
    const NamePlan : ObjectLiteralNamePlans  = {
      0: 'GRATUITO',
      1: 'BÁSICO',
      2: 'AVANÇADO',
      3: 'PRO'
    };
    return NamePlan[e] || NamePlan[0];
}

interface ObjectLiteralResourses {
    [key: string]: any;
}
export const BlockResourcesByPlan = (component: string, planUser: number) => {
    /* 
    Recursos on\off a depender do plano, recursos ausentes na lista são free    
    +---------------------+---------------+
    |                     |     Planos    |
    +---------------------+---+---+---+---+
    |      Componente     | 0 | 1 | 2 | 3 |
    +---------------------+---+---+---+---+
    | formAnalitcs        | 0 | 0 | 0 | 1 |
    +---------------------+---+---+---+---+
    | formPixel           | 0 | 0 | 0 | 1 |
    +---------------------+---+---+---+---+
    | formLogofooter      | 0 | 0 | 0 | 1 |
    +---------------------+---+---+---+---+
    | formSeo             | 0 | 0 | 1 | 1 |
    +---------------------+---+---+---+---+
    | countClicks         | 0 | 0 | 1 | 1 |
    +---------------------+---+---+---+---+
    | formImageBg         | 0 | 1 | 1 | 1 |
    +---------------------+---+---+---+---+
    | formFonts           | 0 | 1 | 1 | 1 |
    +---------------------+---+---+---+---+    
    */

    const matrixPlansResources : ObjectLiteralResourses = {
        'formAnalitcs':     [ false, false, false, true ],
        'formPixel':        [ false, false, false, true ],
        'formLogofooter' :  [ false, false, false, true ],
        'formSeo' :         [ false, false, true,  true ],
        'countClicks' :     [ false, false, true,  true ],
        'formImageBg' :     [ false, true,  true,  true ],
        'formFonts' :       [ false, true,  true,  true ],
    }

    return matrixPlansResources[component][planUser] || false;

}


export const GenerateNewOgImage = async (data: Page) =>{
    if(data.id){
        if(!data.imageThumb){
            let urlGenerate = 'page_generateOgImage.php?id='+data.id;
            if(data.title){
                urlGenerate = urlGenerate+'&title='+data.title;
            } else {
                urlGenerate = urlGenerate+'&title='+data.url;
            }
            /*
            if(data.imageThumb){
                urlGenerate = urlGenerate+'&src='+data.imageThumb;
            } 
            */
            if(data.subTitle){
                urlGenerate = urlGenerate+'&subtitle='+data.subTitle;
            }
            try {
                await api.post(urlGenerate);
            } catch (error) {
                //console.log(error);
            }  
        }    
    }
    console.clear();    
}