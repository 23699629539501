import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './contexts/AuthProvider';
import { ListPagesProvider } from './contexts/ListPagesProvider';
import { PageProvider } from './contexts/PageProvider';
import { DashProvider } from './contexts/DashProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <AuthProvider>
      <PageProvider>
      <ListPagesProvider>
        <DashProvider>
              
            <App />
          
        </DashProvider>
      </ListPagesProvider>
      </PageProvider>
    </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

