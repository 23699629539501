import React, { useRef, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { AjustaUrl } from '../../utils/functions';
import { api } from '../../hooks/useApi';

const FormUrl: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const inputUrl = useRef<HTMLInputElement | null>(null);
    
    const [ loadsave, setLoadSave ] = useState(false);

    useEffect(() =>{
        if(inputUrl.current){ inputUrl.current.value = page.page?.url ? page.page?.url : ''; }
    }, [page.page?.url]);

    const formataUrl = () => {
      if(inputUrl.current){
        inputUrl.current.value = AjustaUrl(inputUrl.current.value);
      }
    }
    const handleChangeUrl = async () => {
      formataUrl();
      const newurl = inputUrl.current?.value ? inputUrl.current?.value : '';
      if(newurl.length >= 2 && newurl.length <= 27) {
        setLoadSave(true);
        try {        
          const datasend = {'url': inputUrl.current?.value}
          const resp = await api.post('/page_checkexist.php', datasend);
  
          //console.log(resp);
          if(resp.status === 200){
            const newurl = {
              'idUser': auth.user?.id,
              'id': page.page?.id,
              'url': inputUrl.current?.value,
              'typeupdate': 'url'
            }
            await api.post('/page_update.php', newurl);
            
            const newpage = { ...page.page, 'url': inputUrl.current?.value };          
            page.choosePage(newpage);
  
            toast.success('Url atualizada com sucesso', { theme: 'colored' });
          } 
  
        } catch (error) {
          toast.error('Url indisponível para registro', { theme: 'colored' });
        }
        setLoadSave(false);
      } else {
        toast.error('Digite entre 2 e 27 caracteres');
      }
      
    }

    return (
    <div className="boxform">            
    <h4>Url única <span className="desctitle">Link único da sua página</span></h4>    
    <fieldset>
      <legend>Url:</legend>
      <label className='labelurl'>navto.me/</label>
      <input ref={inputUrl} placeholder={page.page?.url} className='trocaurl' defaultValue='' onChange={formataUrl} type="text" minLength={2} maxLength={27} />
    </fieldset>
    <div className='spacebutton'>
        <div className='miniaviso'>    
          Você pode alterar seu link como desejar. Caso 
          confirme alteração o caminho anterior ficará disponível para registro a qualquer momento.
        </div>                     
        <button type='submit' onClick={() => handleChangeUrl()} disabled={loadsave} className='save'>
            {loadsave ? 
              <ActivityIndicatorBtn />
            :
              <>Salvar</>
            }                        
        </button>
      </div>
    </div>
    );
}

export default FormUrl;