import React, { useRef, useState, useContext, useEffect } from 'react';
import ToggleSwitch from '../Elements/ToggleSwitch';
import { Item, EditItem, Hr } from './styles';
import { CompactPicker } from 'react-color';
import { typeShowItem } from '../../types/ShowItem';
import { PageContext } from '../../contexts/PageContext';
import { api } from '../../hooks/useApi';
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from 'react-toastify';
import { ActivityIndicatorBtn } from '../Elements/ActivityIndicator';

const ItemSeparador: React.FC<typeShowItem> = ({idx, totalitens, data, arrangeItems, updateItem, scrollIfOpen}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const colorTheme = page.page?.colorOne || '#000';
    const [ color, setColor ] = useState(data.color);
    const [ hide, setHide ] = useState(Number(data.hide));
    const [ open, setOpen] = useState(false);
    const [ loadsave, setLoadSave ] = useState(false);
    
    const formedit = useRef<any>(null);
    const collapse = () => {
        openEdit(formedit.current); 
        scrollIfOpen(idx); 
    }

    const openEdit = (e:any) =>{
        setOpen(!open);
    }

    useEffect(() => {        
        if (!open) {
            formedit.current.classList.remove('opened');
        } else {
            formedit.current.classList.add('opened');
        }
    }, [color, open]);

    useEffect(() => {
        setColor(data.url);
        setHide(Number(data.hide));
    }, [page, data])


    function handleChange(color: any) {handleUseColorTheme(color.hex);}
    function handleUseColorTheme(color: string){setColor(color);}

    async function handleUpdateItem(){        

        setLoadSave(true); 
        try {
            const dataupdated = {
                idUser : auth.user?.id,
                id : Number(data.id),
                idpage : data.idpage,
                title : data.title,
                url : data.url,
                color : color,
                hide : hide,
                image : data.image,
                partOfItem: 0,
                description: '',
            }
            await api.post('/item_update.php', dataupdated);
            
            const newitem = {
                id : data.id, 
                color : color,
                hide : hide,
            }   
            //console.log(newitem)
            updateItem(newitem, 'update');
            
            setLoadSave(false);
            toast.success('Atualização realizada com sucesso');

        } catch (error:any) {
            if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            } else {
                toast.error('Erro ao atualizar', { theme: 'colored' }); 
            };
        }
        setLoadSave(false);
    }


    return (
    <Item draggable={false} className="accordion-item"> 
        <div className="box">
            <div onClick={collapse} className="accordion-link" title="Clique para editar">
                <div className="drag" title="Arrastar">                    
                    <button>...............</button>                    
                </div>
                <div className="tipoIco">
                    <span className="material-icons-round">unfold_less_double</span>
                </div>                
                <div className='data'>
                    <div className='urllink'>Linha separadora <Hr colorTheme={colorTheme} coloritem={data.color} /></div>                    
                </div>
            </div>
            <div className="linksordem">
                <button 
                    disabled={idx > 0 ? false : true} 
                    onClick={() => arrangeItems(idx, idx-1)}>
                        <span className="material-icons-round">expand_less</span>
                </button>
                <button 
                    disabled={idx === totalitens-1 ? true : false}  
                    onClick={() => arrangeItems(idx, idx+1)}>
                        <span className="material-icons-round">expand_more</span>
                </button>
            </div>            
        </div>
        <EditItem ref={formedit} className="answer" colorOne={page.page?.colorOne} colorTwo={page.page?.colorTwo} colorThree={page.page?.colorThree} coloritem={color}>
            <div className="padform">
                <div className='flex2'>
                    <fieldset>
                        <legend>Linha:</legend>
                        <hr />
                        <div className='usecolortheme'> 
                        <div className="cor cor1" onClick={() => handleUseColorTheme(page.page?.colorOne ? page.page?.colorOne : '#000')}></div>     
                        <div className="cor cor2" onClick={() => handleUseColorTheme(page.page?.colorTwo ? page.page?.colorTwo : '#000')}></div>    
                        <div className="cor cor3" onClick={() => handleUseColorTheme(page.page?.colorThree ? page.page?.colorThree : '#000')}></div>                      
                        <button>Cores do tema</button>
                        </div>
                    </fieldset>
                    <CompactPicker onChange={(hex) => handleChange(hex) } />
                </div>
                <div className='spacebutton'>
                    <div className='spacebutton'>
                        <ToggleSwitch changeHide={(e) => setHide(e)} toggled={hide === 0 ? true : false} />
                        <button onClick={() => updateItem(data, 'delete')} className='del' title='Deletar pra sempre'>
                            <span className="material-icons-round">delete_forever</span>Deletar
                        </button>
                    </div>
                    
                    <button onClick={() => handleUpdateItem()} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                    </button>
                </div>
            </div>
        </EditItem>           
    </Item>
    );
}

export default ItemSeparador;