import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.2);
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;

    .spacebutton{
        display: flex;
        justify-content: space-between;
    }
    .boxform{
        max-width: 400px;
        z-index:2;
        margin: 0 20px;
    }
    .simpleflex{
        margin: 15px 0 25px 0;
    }
    .icodestak{
        font-size: 2.6rem;
        margin-right: 10px;
    }
`