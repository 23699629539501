import React, {useContext, useState} from 'react';
import { SideMenu, Logomarca, User, UpgradePlan, MenuMobile } from './styles';
import { PageContext } from '../../contexts/PageContext';
import { DashContext } from '../../contexts/DashContext';
import { AuthContext } from '../../contexts/AuthContext';
import LogoImg from '../../assets/navigatetome.png';
import RocketImg from '../../assets/rocket2.gif';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

const ASide: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const dash = useContext(DashContext);
    const location = useLocation();
    const [ sizeWindow, setSizeWindow ] = useState(window.innerWidth);
    const [ menumobile, setMenumobile ] = useState(false);

    //const navigate = useNavigate();
    const copyToClipboard = (url : string) =>{
        navigator.clipboard.writeText(url);
        toast.success('Url foi copiada com sucesso!');
    }

    const handleOpenCloseMenu = () => {
        if(dash.opened){
            dash.closeDash();
        } else {
            dash.openDash();
        }
    }

    function resizeListener() {
        setSizeWindow(window.innerWidth);
        if(window.innerWidth < 1160){        
            dash.closeDash();
        } 
    }

    const handleLogOut = async () => {
        await auth.signout();
    }

    window.addEventListener("resize", resizeListener);

    return (
    <>    
    <SideMenu openclosemenu={dash.opened} sizeWindow={sizeWindow}>
        <div>
        <Link to='/links'>
            <Logomarca openclosemenu={dash.opened}>
                <span><img src={LogoImg} alt="Navigate To Me" /></span>
            </Logomarca>
        </Link>

        {page.page && 
        <User openclosemenu={dash.opened}>
            {page.page?.imageThumb ?             
                <div className='thumb'><div className='boxthumb'><img className='thumb' alt='avatar' src={page.page?.imageThumb} /></div></div>
            :
                <div className='thumb initial' style={{backgroundColor: page.page?.colorTwo, color: page.page?.colorThree}}>{page.page?.title ? page.page?.title.charAt(0) :  page.page?.url.charAt(0)}</div>
            }
            <div className="nomes">
                <span>Seu link é:</span> 
                <div className='tooltip' data-title='clique para copiar url'>              
                <button onClick={() => copyToClipboard(process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url)}>                    
                    <span>navto.me/</span>
                    <span>{page.page?.url}</span>                    
                </button>
                </div> 
            </div>
            <div className="clear"></div>
        </User>
        }

        <nav className={!page.page ? 'disabledAll' : ''}>            
            <span>MENU</span>
            <ul>
                <li className={`${location.pathname === '/info' ? 'active' : ''}`}>
                    <Link to={`/info`}>
                        <span className="material-icons-round">badge</span>
                        <label>Informações</label>
                    </Link>
                </li>
                <li className={`${location.pathname === '/links' ? 'active' : ''}`}>
                    <Link to='/links'>
                        <span className="material-icons-round">polyline</span>
                        <label>Links / Itens</label>
                    </Link>
                </li>
                {/*
                <li className={`${location.pathname === '/portfolio' ? 'active' : ''}`}>
                    <Link to='/links'>
                        <span className="material-icons-round">fingerprint</span>
                        <label>Portfólio</label>
                    </Link>
                </li>
                */}
                
                <li className={`${location.pathname === '/design' ? 'active' : ''}`}>
                    <Link to='/design'>
                        <span className="material-icons-round">extension</span>
                        <label>Design</label>
                    </Link>
                </li>
                <li className={`${location.pathname === '/theme' ? 'active' : ''}`}>
                    <Link to='/theme'>
                        <span className="material-icons-round">style</span>
                        <label>Temas</label>
                    </Link>
                </li>
                <li className={`${location.pathname === '/config' ? 'active' : ''}`}>
                    <Link to={`/config`}>
                        <span className="material-icons-round">settings</span>
                        <label>Config / Seo</label>
                    </Link>
                </li>
                <li className={`${location.pathname === '/analitcs' ? 'active' : ''}`}>
                    <Link to={`/analitcs`}>
                        <span className="material-icons-round">analytics</span>
                        <label>Métricas</label>
                    </Link>
                </li>
            </ul>
            <button onClick={handleOpenCloseMenu} className="opclmenu"></button>
        </nav>
        </div>

        <div>
            <Link to='/upgrade'>
                <UpgradePlan location={location.pathname} openclosemenu={dash.opened}>        
                    <div className="rocket">
                        <img src={RocketImg} alt="" />
                    </div>
                    <span className="tit">Seja um Assinante</span>
                    <span className='desc'>Tenha muito mais recursos em uma só conta.</span>
                    <span className='call'>Assine agora!</span>      
                </UpgradePlan>
            </Link>

            <div className='termlinks'>
                <Link to='/terms'>Termos</Link>
                <span className="bol">●</span>
                <Link to='/privacy'>Políticas</Link>
            </div>
        </div>
    </SideMenu>

    <MenuMobile menumobile={menumobile}>

        <div className="hamburguer" onClick={() => setMenumobile(true)}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className='bgmenu'>

            <div className='menu'>
                <div className="cross" onClick={() => setMenumobile(false)}>+</div>
                <div style={{clear: 'both'}}></div>
                <ul onClick={() => setMenumobile(false)}>
                    <li className={`${location.pathname === '/analitcs' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to={`/analitcs`}>
                            Métricas
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/info' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to={`/info`}>
                            Informações
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/links' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to='/links'>
                            Links
                        </Link>
                    </li>                
                    <li className={`${location.pathname === '/design' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to='/design'>
                            Design
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/theme' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to='/theme'>
                            Temas
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/config' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to={`/config`}>
                            Config / Seo
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/profile' ? 'active' : ''} ${!page.page ? 'disabledAll' : ''}`}>
                        <Link to='/profile'>
                            Profile
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/upgrade' ? 'active' : ''}`}>
                        <Link to='/upgrade'>
                            Assine Agora
                        </Link>
                    </li>             
                    <li className={`${location.pathname === '/support' ? 'active' : ''}`}>
                        <Link to='/contact'>
                            Suporte
                        </Link>
                    </li>
                    <li className={`${location.pathname === '/terms' ? 'active' : ''}`}>
                        <Link to='/terms'>
                            Termos de uso
                        </Link>
                    </li>            
                    <li className={`${location.pathname === '/privacy' ? 'active' : ''}`}>
                        <Link to='/privacy'>
                            Privacidade
                        </Link>
                    </li>
                    <li>
                        <div className='link' onClick={handleLogOut}>
                            Sair
                        </div>
                    </li> 
                </ul>
            </div>
        </div>
    </MenuMobile>
    </>
    );
}

export default ASide;