import React from 'react';

import { Container, Container2 } from './styles';

export const ActivityIndicator: React.FC = () => {
  return (
      <Container>
          <div className="lds-ripple"><div></div><div></div></div>
      </Container>
  );
}

interface Typeact {
  color?: string
  estilo?: string
}
export const ActivityIndicatorBtn: React.FC<Typeact> = ({color, estilo}) => {
  return (
      <Container2 color={color} estilo={estilo}>
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </Container2>
  );
}
