import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;


    a, button{
        background: none;
        color: #ccc;
        margin: 0 5px;

        :hover, :hover span{
            color: var(--orangeBase);
        }

        span{
            font-size: 2.5rem;
            color: #ccc;
        }
        span.help{
            font-size: 2.2rem;
            margin-bottom: 0.3rem;
        }
    }
    @media only screen and (max-width:1000px){
        margin-right: 40px;
        
        border-right: dotted 1px rgba(0,0,0,0.1);
        padding-right: 10px;
    }
    @media only screen and (max-width:550px){
        display: none;
    }
`