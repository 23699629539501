import React, { useState, useContext, useRef, useCallback, useEffect } from 'react';
import TopUser from '../../components/TopUser';
import { AuthContext } from '../../contexts/AuthContext';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Input from '../../components/Elements/Input';
import getValidationErros from '../../utils/getValidationErros';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { ScrollArea } from '../../styles/global';
import { api, apiStripe } from '../../hooks/useApi';

import { TipoPlano, TipoStatus } from './styles';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../components/Elements/ConfirmDialog';

interface TypeDataForm {
    name: string;
    email: string;
    oldpassword?: string;
    newpassword?: string;
    renewpassword?: string;
}

const Profile: React.FC= () => {

    const auth = useContext(AuthContext);

    const [ loadsave, setLoadSave ] = useState(false);       
    const [ showConfirm, setShowConfirm ] = useState(false);

    const formProfile = useRef<FormHandles>(null);
    const iniData= {
        name: auth.user?.name ? auth.user?.name : '',
        email: auth.user?.email ? auth.user?.email : '',
        oldpassword: '',
        newpassword: '',
        renewpassword: ''
      }
    const [ dataForm, setDataForm ] = useState<TypeDataForm>(iniData);

    useEffect(() =>{
        const iniData= {
          name: auth.user?.name ? auth.user?.name : '',
          email: auth.user?.email ? auth.user?.email : '',
          oldpassword: '',
          newpassword: '',
          renewpassword: ''
        }
        setDataForm(iniData);
    }, [auth.user])


    const handleChangeProfle = useCallback(async (data: TypeDataForm) => {
        if(data.email !== auth.user?.email && data.oldpassword?.length === 0 ){
            toast.error('Erro: preencha sua senha', {theme: 'colored'});
        } else {
            setLoadSave(true);

            try {
                formProfile.current?.setErrors({});
                const schema = Yup.object().shape({
                    name: Yup.string().max(50, 'Máximo de 50 caracteres'),
                    email: Yup.string().required('Email obrigatório').email('Insira um email válido').max(35, 'Máximo 35 caracteres').min(5, 'Mínimo 5 caracteres'),
                    oldpassword: Yup.string().required('Digite sua senha atual').max(14, 'Máximo 14 caracteres').min(6, 'Mínimo 6 caracteres'),
                    newpassword: Yup.string(),
                    renewpassword: Yup.string().when('newpassword', {
                        is: (value : string ) => value.length > 0,
                        then: Yup.string().required('Campo Obrigatório').max(12, 'Máximo 12 caracteres').min(6, 'Mínimo 6 caracteres'),
                        otherwise: Yup.string(),
                    }).oneOf([Yup.ref('newpassword'), null], 'As novas senhas são diferentes'),
                });
                await schema.validate(data, { abortEarly: false, });
    
                const send = Object.assign({
                    "idUser": auth.user?.id,
                    "name": data.name,
                    "email": data.email, 
                    "loggedEmail": auth.user?.email,
                    "oldpassword": data.oldpassword
                }, data.newpassword ? {
                    "newpassword": data.newpassword ? data.newpassword : null
                } : {});

                
                const resp = await api.post('/user_update.php', send);
                //console.log(resp.data);
                auth.updateUser({...auth.user, ...resp.data.user});
                auth.setToken(resp.data.jwt);

                toast.success('ATUALIZAÇÃO REALIZADA COM SUCESSO', {theme: 'colored'});               
                
            } catch (err: any) {
                setLoadSave(false);
                if(err instanceof Yup.ValidationError){
                const errors = getValidationErros(err);
                formProfile.current?.setErrors(errors);
                return;
                }              
                const resp = err.response.statusText ? err.response.statusText : 'ERRO AO TENTAR ATUALIZAR';
                toast.error(resp, { theme: 'colored'});
            }
            setLoadSave(false);
        }
    }, [auth]);


    async function cancelSubscription(e:boolean){        
        if(e){
            if(auth.user?.idSubscription !== null){
                try {
                    const {data} = await apiStripe.delete('v1/subscriptions/'+auth.user?.idSubscription);
                    
                    const cancelSubscribe = {
                        "idUser": auth.user?.id,
                        "email": auth.user?.email, 
                        "planSignature": auth.user?.planSignature,
                        "dateSignature": data.current_period_start,
                        "dateValSignature": data.current_period_end,
                        "idCustomer": auth.user?.idCustomer,
                        "idSubscription": auth.user?.idSubscription,
                        "statusSignature": 'canceled'
                    }; 
                    await api.post('/user_billingupdate.php', cancelSubscribe);


                    const newUser = {
                        id: Number(auth.user?.id),
                        email: String(auth.user?.email),                        
                        statusSignature: 'canceled'
                    }
                    auth.updateUser({...auth.user, ...newUser});  

                    toast('Uma pena que você partiu. Sua assinatura foi cancela com sucesso.')
                } catch (error) {
                    toast.error('Ocorreu algum erro, Entre em contato conosco para cancelar', {theme: 'colored'})
                }
            } else {
                toast.error('Entre em contato conosco para cancelar', {theme: 'colored'})
            }
        }
        setShowConfirm(false);
    }

    return (
    <>
        { 
            showConfirm && 
            <ConfirmDialog 
              closeConfirm={() => setShowConfirm(false)} 
              title="Confirme" 
              icon="warning_amber"
              text={`&#9632; Todas as 'páginas extras' serão apagadas.<br/>&#9632; Sua assinatura seguirá ativa até ${auth.user?.dateValSignature} e não mais será renovada. <br/><br/>Tem certeza que deseja prosseguir?  `}
              labelbtn={'Sim quero cancelar'}
              returnDialog={(e: boolean) => cancelSubscription(e)}
            /> 
        }
        <div className='simpleflex minHsimpleflex'>            
            <h1 className='marg0'>
                Meu perfil
                <span>Mantenha seus dados de acesso atualizados</span>
            </h1>
            <TopUser />
        </div>

        <div className="contentpage"> 
            <ScrollArea size={7}> 

            <div className="boxform">            
                <h4>Descrição <span className="desctitle">Informações básicas da sua página</span></h4>    
                <Form initialData={dataForm} ref={formProfile} onSubmit={handleChangeProfle}>
                <fieldset>                               
                    <Input placeholder='Nome:' type="text" name="name" maxLength={50} /> 
                </fieldset> 
                <fieldset>                   
                    <Input placeholder='E-mail:' type="text" name="email" maxLength={35} /> 
                </fieldset> 
                <fieldset>
                    <Input placeholder='Senha atual:' type="password" name="oldpassword" minLength={6} maxLength={14} /> 
                </fieldset>
                <br />
                <h4>Alterar Senha</h4>
                <fieldset>
                    <Input placeholder='Nova senha:' type="password" name="newpassword" minLength={6} maxLength={14} /> 
                </fieldset>
                <fieldset>
                    <Input placeholder='Repita a nova senha:' type="password" name="renewpassword" minLength={6} maxLength={14} />
                </fieldset>
                <div className='spacebutton'>
                    <div className='miniaviso'>    
                    
                    </div>                     
                    <button type='submit' disabled={loadsave} className='save'>
                    {loadsave ? 
                        <ActivityIndicatorBtn />
                    :
                        <>Salvar</>
                    }                        
                    </button>
                </div>     
                </Form>
            </div>

            <div className="boxform">            
                <h4>Assinatura <span className="desctitle">Seja assinante e tenha mais vantagens</span></h4>
                <div className="simpleflex">
                    <div>
                    Atualmente você utiliza o plano: 
                    <TipoPlano>
                        {auth.user?.expiredSignature !== true ? <>
                        {Number(auth.user?.planSignature) === 0 || auth.user?.planSignature === undefined || auth.user?.planSignature === null ? 'GRATUITO': ''}
                        {Number(auth.user?.planSignature) === 1 ? 'BÁSICO': ''}
                        {Number(auth.user?.planSignature) === 2 ? 'AVANÇADO': ''}
                        {Number(auth.user?.planSignature) === 3 ? 'PRO': ''}
                        </>
                        :
                        'GRATUITO'}
                    </TipoPlano>
                    {Number(auth.user?.planSignature) !== 3 ? 
                    <Link className='likebtn' to="/upgrade">
                         UPGRADE
                    </Link>
                    : ''}
                    
                    </div>

                    <div>
                        {
                        Number(auth.user?.planSignature) >= 1 && auth.user?.statusSignature !== 'canceled' && 
                        <button onClick={() => setShowConfirm(true)} className='resetbutton'>CANCELAR ASSINATURA</button>                         
                        }
                    </div>
                </div>
                
                {Number(auth.user?.planSignature) >= 1 &&
                    <small><small>
                    <br />
                    <strong>STATUS DA ASSINATURA:</strong> 
                    <TipoStatus situacao={auth.user?.statusSignature}>
                        {auth.user?.statusSignature === 'canceled' ? 'CANCELADO': 'ATIVO'}
                    </TipoStatus><br />
                    <strong>DATA DE ASSINATURA:</strong> {auth.user?.dateSignature}<br />
                    <strong>DATA DE {auth.user?.statusSignature === 'canceled' ? 'ENCERRAMENTO:' : 'RENOVAÇÃO:'}</strong> {auth.user?.dateValSignature}<br />
                    </small></small>
                }
            </div>

            
            <div className="gradfooter"></div>
            </ScrollArea>
        </div>
    </>
    );
}

export default Profile;