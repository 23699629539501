import React, { useContext } from 'react';
import { PageContext } from '../../contexts/PageContext';
import {BgPreviewContainer} from './styles'

const BgPreviewPage: React.FC = () => {
    const page = useContext(PageContext);

    function hexToRgb(hex?: string) {
        if(hex){
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            if(result){
                var r= parseInt(result[1], 16);
                var g= parseInt(result[2], 16);
                var b= parseInt(result[3], 16);
                return r+","+g+","+b;//return 23,14,45 -> reformat if needed 
            } 
            return '';
        }
        return '';
    }

    return (
    <BgPreviewContainer 
        bgColor={hexToRgb(page.page?.colorOne)} 
        topColor={hexToRgb(page.page?.colorTwo)} 
        theme={page.page?.theme} 
        bgImage={page.page?.theme !== undefined && page.page?.theme  > 5 ? page.page?.imageBg : ''}
        >
        <div className="degrade"></div>
        {page.page?.theme !== undefined && page.page?.theme  > 5 && 
        <>
            <div className="bgImgBlur"></div>
            <div className="bgImgNoBlur"></div>
        </>
        }
    </BgPreviewContainer>
    );
}

export default BgPreviewPage;