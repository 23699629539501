import React, {useContext} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { Container } from './styles';

const TopUser: React.FC = () => {

    const auth = useContext(AuthContext);

    const handleLogOut = async () => {
        await auth.signout();
    }

    return (
        <Container>
            <div className='tooltip' data-title='Perfil'>
                <Link to='/profile'>
                    <span className="material-icons-round">manage_accounts</span>
                </Link>
            </div>            
            <div className='tooltip' data-title='Suporte - Contato'>
                <Link to='/contact'>
                    <span className="material-icons-round help">contact_support</span>
                </Link>
            </div>
            <div className='tooltip' data-title='Sair - Logout'>
                <button onClick={handleLogOut}>
                    <span className="material-icons-round">power_settings_new</span>
                </button>
            </div>            
        </Container>
    );
}

export default TopUser;