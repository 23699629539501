import React, { useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import { PageContext } from '../../contexts/PageContext';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import { ListPagesContext } from '../../contexts/ListPagesContext';
import { AuthContext } from '../../contexts/AuthContext';
import { LimitPagesByPlan } from '../../utils/functions';
import LogoImg from '../../assets/navigatetome.png';
import { Logomarca } from '../Aside/styles';

const ListPages: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const page = useContext(PageContext);
    const allPages = useContext(ListPagesContext);
    const auth = useContext(AuthContext);
    const [ limitPages, setLimitPages ] = useState(LimitPagesByPlan(Number(auth.user?.planSignature)));
    const [ maiorq, setMaiorq ] = useState(limitPages > allPages.listPages.length ? limitPages : allPages.listPages.length);

    const handleGoPage = (data: any) => {
        page.choosePage(data);
        if(location.pathname === '/newpage'){
            navigate("../links");
        }
    }

    useEffect(() => {
        setLimitPages(LimitPagesByPlan(Number(auth.user?.planSignature)));        
    }, [auth.user]);

    useEffect(() => {
        setMaiorq(limitPages > allPages.listPages.length ? limitPages : allPages.listPages.length);
    }, [allPages, limitPages]);

    return(
        <Container>
            <div className={`logo ${!page.page ? 'disabledAll' : ''}`}>
                <Link to='/links'>
                    <Logomarca openclosemenu={false}>
                        <span><img src={LogoImg} alt="Navigate To Me" /></span>
                    </Logomarca>
                </Link>
            </div>
            <div>
                <h6><strong>Suas páginas</strong> | clique para selecionar</h6>
                {page.page? 
                <div className="list">

                    { allPages.listPages !== undefined && allPages.listPages.length > 0 ?
                        Array(maiorq).fill(1).map((e, i) =>                        
                                <div 
                                    key={i} 
                                    className={`thumb tooltip${
                                        (i >= 1 && auth.user?.expiredSignature === true) || 
                                        i+1 > limitPages ? ' listblockedByPlan' : ''}${allPages.listPages[i]?.url === page.page?.url ? ' active' : ''}`}                                 
                                    data-title={allPages.listPages[i] ? allPages.listPages[i].url : 'Cadastrar nova página'}
                                    
                                    onClick={
                                        (i >= 1 && auth.user?.expiredSignature === true) || 
                                        i+1 > limitPages ? () => navigate("../upgrade") :
                                        allPages.listPages[i] ? 
                                            () => handleGoPage(allPages.listPages[i]) : 
                                            () => navigate("../newpage")}
                                >                            
                                    { allPages.listPages[i] ? 
                                        allPages.listPages[i].imageThumb && allPages.listPages[i].imageThumb !== '' ?
                                        <div className='boxthumb'><img alt={allPages.listPages[i].url} src={allPages.listPages[i].imageThumb} /></div>
                                        :
                                        <span style={{backgroundColor: allPages.listPages[i].colorTwo, color: allPages.listPages[i].colorThree}} className={`initial ${page.page?.id === allPages.listPages[i].id ? "selected" : ""}`}>{allPages.listPages[i].title ? allPages.listPages[i].title.charAt(0) : allPages.listPages[i].url.charAt(0)}</span>
                                    : 
                                    <span className='initial unblocked'>
                                        <span className="material-icons-round">note_add</span>
                                    </span>
                                    }
                                </div>                        
                        )
                    : 
                        <div className="simpleflex">
                            <small><small><strong>NENHUMA PÁGINA CADASTRADA &nbsp; </strong></small></small>
                            <Link to="/newpage" className='resetbutton'><small><strong>CADATRAR AGORA</strong></small></Link>
                        </div>
                    }

                    {
                    Array(5 - maiorq).fill(1).map((e, i) =>
                        <div 
                            key={i} 
                            className="thumb tooltip"                                 
                            data-title="Libere mais páginas"
                            onClick={() => navigate("../upgrade")}
                        >                            
                            <span className='initial blocked'>
                                <span className="material-icons-round">lock</span>
                            </span>                        
                        </div>                        
                        )
                    }
           
                </div>
                : ''}
            </div>
        </Container>
    );
}

export default ListPages;