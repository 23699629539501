import styled from "styled-components";

export const TipoPlano = styled.div`
    padding: 10px 15px;
    display: inline-block;
    background: var(--background);
    margin: 0 5px;
    font-weight: 700;
    color: #2d70fa;
`

export const TipoStatus = styled.div<{situacao?: string}>`
    padding: 2px 7px;
    display: inline-block;
    background: ${props => props.situacao === 'canceled' ? '#ffc9c9' : '#b2f2bb'};
    margin: 0 5px;
    font-weight: 700;
    color: ${props => props.situacao === 'canceled' ? '#f53f38' : '#02c054'};
    border-radius: 4px;
    font-size: 0.7rem;
`