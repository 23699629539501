import { useState} from "react";
import { Page } from "../types/Page";
import { PageContext } from "./PageContext";

export const PageProvider = ({children} : { children: JSX.Element}) => {

    const [page, setPage] = useState<Page | null>(null);
    
    const choosePage = async (e:any) => {        
        setPage(e);
    }   

    return(
        <PageContext.Provider value={{page, choosePage}}>
            {children}
        </PageContext.Provider>
    )
};