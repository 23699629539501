import styled from 'styled-components';

export const Container = styled.div`
    display: flex;

    .logo{
        display: none;        
    }
    h6{
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 200;
        font-family: Montserrat;
        margin: 5px 0;
        display: block;
    }

    .list{
        display: flex;
        align-items: center;
        width: 100%;        
    }

    
    .thumb{
        display: inline-block;        
        margin-right: -7px;        
        cursor: pointer;
        transition: top .5s;
        transition: .5s;
        top: 0;
        width: 45px;
        height: 45px;
        transition: 0.5s ease-in-out;

        .boxthumb{
            border-radius: 50%;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                min-width: 45px;
                min-height: 45px;
                object-fit: cover;   
                
            }
        }

        div{
            display: none;            
        }
        
        .initial{
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.3rem;
            background: var(--purpleBase);
            color: rgba(255,255,255, .7); 
            border-radius: 50%;
            width: 45px;
            height: 45px;
            box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
            transition: 0.5s ease-in-out;   
            text-shadow : 2px 2px 5px rgba(0,0,0,.05), 1px 1px 0 rgba(0,0,0,.15);
        }
        .blocked, .unblocked{
            background: #DEDEDE;
            color: #fff;
        }
        .unblocked{
            background: rgb(238,12,89);
            background: -moz-linear-gradient(-45deg,rgba(238,12,89,1) 0%,rgba(253,124,14,1) 100%);
            background: -webkit-linear-gradient(-45deg,rgba(238,12,89,1) 0%,rgba(253,124,14,1) 100%);
            background: linear-gradient(135deg,rgba(238,12,89,1) 0%,rgba(253,124,14,1) 100%);
        }
        .material-icons-round{ font-size: 21px; line-height: 17px; display: inline-block}
        .selected{
            border: solid 3px rgba(255,255,255,0.22);
        }
    }
    
    .list .thumb:first-child{ z-index: 5}
    .list .thumb:nth-child(2){ z-index: 4}
    .list .thumb:nth-child(3){ z-index: 3}
    .list .thumb:nth-child(4){ z-index: 2}
    .list .thumb:nth-child(5){ z-index: 1}

    .list .thumb:hover, .list .thumb.active{top: 10px}

    @media only screen and (max-width: 550px){
        .logo {
            display: block;
            margin-right: 10px;
            span{
                margin: 0 10px;
            }
        }
        .thumb{       
            margin-right: -17px;
        }
    }
`
