import styled from 'styled-components';
//import mulher1 from '../../assets/w1.jpg';
import mulher2 from '../../assets/w2.jpg';
import mulher3 from '../../assets/w4.jpg';

export const Container = styled.div`
    display: block;
    height: 100%;
    width: 100%;
`;
export const FormLogin = styled.div`
    display: block;
    width: 52%;
    height: 100%;
    overflow: hidden;
    scroll-behavior:  smooth;
    float: left;


    .scrollforms{
        width: 200%;
        display: block;        
        height: 100%;
        white-space: nowrap;
    }

    .boxform {
        float: left;
        white-space: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        flex: 1;
        padding: 11% 8%;

        > span {
            font-weight: 400;
            font-size: 90%;
            display: block;
            margin:  20px 0;
            text-align: center;
        }
    }
    @media only screen and (max-width:890px){
        width: 100%;
    }
`;
export const ImageSide = styled.div`
    display: block;
    width: 48%;
    height: 100%;
    background-image: url(${mulher3});
    background-size: cover;
    background-position: center center;
    float: right;

    @media only screen and (max-width:890px){
        display: none;
    }

`;

export const ImageSide2 = styled.div`
    display: block;
    width: 48%;
    height: 100%;
    background-image: url(${mulher2});
    background-size: cover;
    background-position: center center;
    float: right;
`;