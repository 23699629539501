import React, { useContext } from 'react';
import { ScrollArea } from '../../styles/global';
import { PageContext } from '../../contexts/PageContext';

import FormThemes from './formThemes';

interface TypeScreen {
    prevTheme: (e:any) => void;
}

const Themes: React.FC<TypeScreen> = ({prevTheme}) => {
    const page = useContext(PageContext);

    return(<>
    <div className="contentpage">        
        <h1>
        Tema da sua página
        <span>VOCÊ ESTA EDITANDO A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
        </h1>
        <ScrollArea size={63}>
        <FormThemes prevTheme={(e:any) => prevTheme(e)} />
        <div className="gradfooter"></div>
        </ScrollArea>

    </div>
    </>);
}


export default Themes;