import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { IThemes } from '../../types/Themes';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { NamesPlans } from '../../utils/functions';

interface TypeScreen {
    prevTheme: (e:any) => void;
    theme : IThemes
}

const ThemeItem: React.FC<TypeScreen> = ({prevTheme, theme}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);

    const namePlan = NamesPlans(theme.planForUse);
    const planUser = auth.user?.planSignature ? Number(auth.user?.planSignature) : 0;
    const unlocked = theme.planForUse <= planUser ? true : false;

    const handleChangeTema = async (e:IThemes) => {     
        if(unlocked) { 
            try {            
            setLoadSave(true);
            const datasend = {
                ...page.page,
                'theme': e.id,
                'colorOne': e.colorOne,
                'colorTwo': e.colorTwo,
                'colorThree': e.colorThree,
                'fontName': e.font,
                'idUser': auth.user?.id
            }

            const resp = await api.post('/page_update.php', datasend);
            
            if(resp.status === 200){
                const newpage = { 
                ...page.page,
                'theme': e.id,
                'colorOne': e.colorOne,
                'colorTwo': e.colorTwo,
                'colorThree': e.colorThree,
                'fontName': e.font
                };          
                prevTheme(false);
                page.choosePage(newpage);
                toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
            
        } catch (error: any) {
            if(error.response.status === 500){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
            } 
            toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);

        } else {
            toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
        }
    }
  

    return (
    <>
    {loadsave && <ActivityIndicatorBtn estilo="bgbranco" color="#F64434" /> }
    <li key={theme.id}>
        {!unlocked && 
        <div className="tooltip" data-title={`DISPONÍVEL A PARTIR DO PLANO ${namePlan}`}>
            <div className="simbolBlock"><span className="material-icons-round">lock</span></div>
        </div>
        }

        
        <img src={theme.img} alt={`Tema ${theme.id}`} />
        <div className="buttons">
        {Number(page.page?.theme) === theme.id ?                   
            <button onClick={() => handleChangeTema(theme)}>Resetar Tema</button>                    
            : 
            <>
            {unlocked && <button className='green' onClick={() => handleChangeTema(theme)}>Usar Tema</button>}
            <button onClick={() => prevTheme(theme)}>Preview</button>  
            </>               
        }
        </div>
    </li>    
    </>);
}

export default ThemeItem;