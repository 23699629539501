import styled from "styled-components";

export const Container = styled.div<{openPreview?: boolean}>`
    position: relative;
    z-index: 998;    
    height: 100%;
    min-width: 360px;
    transition:  0.5s ease-in-out;

    #prevFrame {
        -ms-zoom: 0.77;
        -moz-transform: scale(0.77);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.77);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.77);
        -webkit-transform-origin: 0 0;
        transform: scale(0.77);
        width: 130%;
        height: 130%;
    }

    .aviso{
        z-index: 9;
        position: absolute;
        font-size: 0.45rem;
        padding: 5px 8px;
        background: var(--orangeBase);
        color: #fff;
        margin: 10px;
        border: solid rgba(0,0,0,0.1) 1px;
        border-radius: 10px;
        cursor: pointer;
        text-decoration: none;
    }  

    a, button{
        background: none;
        color: #ccc;
        margin: 0 5px;
        display: flex;
        align-items: center;
        

        :hover, :hover span{
            color: var(--orangeBase);
        }

        span{
            font-size: 2.5rem;
            color: var(--purpleBase);
        }
        span.help{
            font-size: 2.2rem;
            margin-bottom: 0.3rem;
        }
    }
    
    .toglePreview{
        display: none;
        transition:  0.5s ease-in-out;
    }

    @media only screen and (max-width:1000px){
        position: absolute;
        right: ${props => !props.openPreview ? '-360px' : '0px'}; 

        .toglePreview{
            display: inline-block;
            position: absolute;
            z-index: 9;
            left: -58px;
            top: 52px;
        }
    }
    @media only screen and (max-width:749px){
        a, button{
            span{                
                color: ${props => !props.openPreview ? 'inherith' : '#fff'};
            }
        }
        .toglePreview{
            top: ${props => !props.openPreview ? '52px' : '10px'};
            left: ${props => !props.openPreview ? '-58px' : '7px'}; 
        }
    }
    @media only screen and (max-width:550px){
        a, button{
            span{                
                color: ${props => !props.openPreview ? 'inherith' : '#fff'};
            }
        }
        .toglePreview{
            left: ${props => !props.openPreview ? '-109px' : '7px'}; 
            top: ${props => !props.openPreview ? '41px' : '10px'};
        }
        #prevFrame {
          -ms-zoom: 1;
          -moz-transform: scale(1);
          -moz-transform-origin: 0 0;
          -o-transform: scale(1);
          -o-transform-origin: 0 0;
          -webkit-transform: scale(1);
          -webkit-transform-origin: 0 0;
          transform: scale(1);
          width: 100%;
          height: 100%;
        }
    }
`

export const BgPreviewContainer = styled.div<{bgColor?: string, topColor?: string, theme?: string, bgImage?:string}>`
    position: relative;
    z-index: 1;    
    height: 100%;
    width: calc(50% - 440px);
    min-width: 390px;
    position: absolute;
    right: 0;
    overflow: hidden;
    background: ${props => props.bgImage ? '' : 'rgba('+props.topColor+', 0.8)' };
    
    .bgImgNoBlur{        
        background-image: url(${props => props.bgImage ? props.bgImage : ''});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        opacity: 0.5;
    }
    .bgImgBlur{
        background-image: url(${props => props.bgImage ? props.bgImage : ''});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        filter: blur(8px);
        -webkit-filter: blur(8px);        
        opacity: 0.75;
        width: 100%;
        height: 100%;
        z-index: 2;
        position: absolute;
        top: 0;
    }
    .degrade{        
        background: -moz-linear-gradient(top,  rgba(${props => props.bgColor},0) 0%, rgba(${props => props.bgColor},0) 7%, rgba(${props => props.bgColor},1) 90%, rgba(${props => props.bgColor},1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(${props => props.bgColor},0) 0%,rgba(${props => props.bgColor},0) 7%,rgba(${props => props.bgColor},1) 90%,rgba(${props => props.bgColor},1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(${props => props.bgColor},0) 0%,rgba(${props => props.bgColor},0) 7%,rgba(${props => props.bgColor},1) 90%,rgba(${props => props.bgColor},1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        width: 100%;
        height: 100%;
        z-index: 3;
        position: absolute;
        top: 0;
        opacity: 0.9;
    }

    @media only screen and (max-width:1100px){
        display: none;
    }
`
