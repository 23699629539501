import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import Logonav from '../../assets/navigatetome2.png';
import ToggleSwitch from '../../components/Elements/ToggleSwitch';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { BlockResourcesByPlan } from '../../utils/functions';

const FormLogoFoter: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ hide, setHide ] = useState(Number(page.page?.logoFooter));
    const [ loadsave, setLoadSave ] = useState(false);

    const unlocked = BlockResourcesByPlan('formLogofooter', Number(auth.user?.planSignature));

    useEffect(() => {
        setHide(Number(page.page?.logoFooter));
    }, [page]);

    const handleChangelogoFooter = async () => {
        if(unlocked) {
            try {    
                setLoadSave(true);
                const datasend = {
                ...page.page,
                'logoFooter': hide,
                'idUser': auth.user?.id
                }
                const resp = await api.post('/page_update.php', datasend);
        
                if(resp.status === 200){
                const newpage = { 
                    ...page.page,
                    'logoFooter': hide
                };          
                page.choosePage(newpage);
                toast.success('Dados atualizados com sucesso', { theme: 'colored' });
                }
                
            } catch (error: any) {             
                if(error.response.status === 500){
                    toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                    localStorage.setItem('@navtome:token', '');
                    window.location.href = '/';
                } else {
                    toast.error('Erro ao atualizar', { theme: 'colored' }); 
                }; 
            }
            setLoadSave(false);
        } else {
        toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
        }
    }

    return (
        <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Disponível a partir do plano pro">
        <div className="simpleflex">
        <h4>Logo rodapé <span className="desctitle">Logomarca do navto.me</span></h4>    
        {loadsave && <ActivityIndicatorBtn color="#F64434" /> }
        </div>
        <br />
        <div className="simpleflex">
            <ToggleSwitch changeHide={(e:any) => setHide(e)} toggled={hide === 1 ? false : true} />
            <img src={Logonav} alt='Navigate to Me' height="25" />
        </div>
        
        <br />
        <div className='spacebutton'>
            <div className='miniaviso'>    
                Decida se deseja exibir ou não nossa logo em sua página
            </div> 
            <button type='submit' onClick={handleChangelogoFooter} disabled={loadsave} className='save'>
                    {loadsave ? 
                        <ActivityIndicatorBtn />
                    :
                        <>Salvar</>
                    }                        
            </button>
        </div>
        </div>
    );
}

export default FormLogoFoter;