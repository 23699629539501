import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "./contexts/AuthContext";
import { ListPagesContext } from "./contexts/ListPagesContext";
import { PageContext } from "./contexts/PageContext";
import { DashContext } from "./contexts/DashContext";
import { GlobalStyle, Main, SectionContent, SectionNoPreviewContent } from './styles/global';
import { ToastContainer } from 'react-toastify';

import Routes from "./routes";
import BgPreviewPage from "./components/PreviewPage/bgPreview";
import ASide from "./components/Aside";
import ListPages from "./components/ListPages";
import TopUser from "./components/TopUser";
import PreviewPage from "./components/PreviewPage";

export function App() {
  const auth = useContext(AuthContext);
  const allPages = useContext(ListPagesContext);
  const page = useContext(PageContext);
  const dash = useContext(DashContext);

  const location = useLocation();
  const [ prevTheme, setPrevTheme ] = useState<any>(false);
  
  useEffect(() => {
    let mounted = true;
    if(mounted){
      allPages.getAllPages();       
    }
    return function cleanup() {
        mounted = false
    }
  }, [auth]); // eslint-disable-line

  const pagesNoPreview = ["/upgrade", "/terms", "/privacy", "/profile", "/contact", "/confirmation"];

  return (
    <>
      <div className="gradient1"></div>
      <div className="gradient2"></div>
      <Main>
        { auth.user && page.page?.theme !== null && 
        !pagesNoPreview.includes(location.pathname) && <BgPreviewPage />}
        
        {!auth.user ? 
        <div className="boxmain logout">       
          <Routes prevTheme={(e:any) => setPrevTheme(e)} />            
        </div>
        :
        <div className="boxmain login"> 
          <ASide />
            {!pagesNoPreview.includes(location.pathname) ?
            <>
              <SectionContent openclosemenu={dash.opened}>
                <div className='simpleflex'>
                  <ListPages />
                  <TopUser />
                </div>     
                <Routes prevTheme={(e:any) => setPrevTheme(e)} />
              </SectionContent>
              <PreviewPage previewTheme={prevTheme} />
            </>
            :
            <SectionNoPreviewContent openclosemenu={dash.opened}>
              <Routes prevTheme={(e:any) => setPrevTheme(e)} />
            </SectionNoPreviewContent>
            }            
        </div>
        }
        <GlobalStyle /> 
      </Main>
      <ToastContainer position="top-center" />
    </>
  );
}