import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import TextArea from '../../components/Elements/TextArea';
import getValidationErros from '../../utils/getValidationErros';
import { Page } from '../../types/Page';
import { useLocation } from 'react-router-dom';
import { BlockResourcesByPlan } from '../../utils/functions';


const FormSeo: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);
    const [ dataPage, setDatapage ] = useState<any>(page.page);
    const location = useLocation();
    const formSeoDesc = useRef<FormHandles>(null);

    const unlocked = BlockResourcesByPlan('formSeo', Number(auth.user?.planSignature));
    
    useEffect(() =>{
      const iniData= {
        seoTitle: page.page?.seoTitle ? page.page?.seoTitle : '',
        seoDescription: page.page?.seoDescription ? page.page?.seoDescription : '',
        seoTags: page.page?.seoTags ? page.page?.seoTags : ''
      }
      setDatapage(iniData);
    }, [page, location.pathname]);

    const handleChangeSeo = useCallback(async (data: Page) => { 
      if(unlocked) {
        try {
            setLoadSave(true);
            formSeoDesc.current?.setErrors({});
            const schema = Yup.object().shape({
                seoTitle: Yup.string().max(150, 'Máximo de 150 caracteres'),
                seoDescription: Yup.string().max(150, 'Máximo de 150 caracteres'),
                seoTags: Yup.string().max(150, 'Máximo de 150 caracteres'),
            });
            await schema.validate(data, { abortEarly: false, });

            const datasend = {
              ...page.page,
              'seoTitle': data.seoTitle,
              'seoDescription': data.seoDescription,
              'seoTags': data.seoTags,
              'idUser': auth.user?.id
            }
           
            const resp = await api.post('/page_update.php', datasend);
            if(resp.status === 200){
              const newpage = { ...page.page, 'seoTitle': data.seoTitle, 'seoDescription': data.seoDescription, 'seoTags': data.seoTags };          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
        } catch (error: any) { 
            if(error instanceof Yup.ValidationError){
                const errors = getValidationErros(error);
                formSeoDesc.current?.setErrors(errors);
                setLoadSave(false);
                return;
            }
            if(error.response.status === 500){
              toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
              localStorage.setItem('@navtome:token', '');
              window.location.href = '/';
            } 
            toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
      } else {
        toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
      }
    }, [page, auth.user?.id, unlocked]);

    return(
        <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Disponível a partir do plano avançado">            
        <h4>SEO <span className="desctitle">Informações seo da página</span></h4>    
        <Form initialData={dataPage} ref={formSeoDesc}  onSubmit={handleChangeSeo}>
          <fieldset>
            <TextArea placeholder='Título SEO:' name='seoTitle' rows={2} maxLength={150} />
          </fieldset> 
          <fieldset>
            <TextArea placeholder='Descrição SEO:' name='seoDescription' rows={3} maxLength={150} />
          </fieldset>
          <fieldset>            
            <TextArea placeholder='Meta Tags (separe as palavras por virgula):' name='seoTags' rows={2} maxLength={150} />
          </fieldset>
          <div className='spacebutton'>
            <div className='miniaviso'>    
              Adicione configurações SEO para um melhor posicionamento nos sites de busca. Caso estes campos
              estejam vazios usaremos as informações básicas.
            </div>                     
            <button type='submit' disabled={loadsave} className='save'>
              {loadsave ? 
                <ActivityIndicatorBtn />
              :
                <>Salvar</>
              }                        
            </button>
          </div>     
        </Form>
        </div>
    );
}

export default FormSeo;