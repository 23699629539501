import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Container, ErroInput } from '../styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement>{
    name: string;
    mask?: string;
}

const Input: React.FC<InputProps> = ({name, mask, placeholder, ...rest}) => {
    const inputRef = useRef<any>(null);
    const [content, setContent] = useState('');
    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
        inputRef.current.value = defaultValue;
        chekInput();
    }, [fieldName, registerField, defaultValue]) // eslint-disable-line

    const chekInput = () =>{
        if(inputRef.current.value){
            setContent('comconteudo');
            if(mask === 'fone'){ mtel(); }
        } else {
            setContent('');
            if(mask === 'fone'){ mtel(); }
        }
    }

    /* mascara de tefone */
    function mtel(){
        let v = inputRef.current.value;
        v=v.replace(/\D/g,""); //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v=v.replace(/(\d)(\d{4})$/,"$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
        inputRef.current.value = v;
    }

    return (
    <Container>
        <input className={`${content}`} onChange={chekInput} id={name} defaultValue={defaultValue} ref={inputRef} {...rest} />
        {error && <ErroInput desktitle={error}><span className="material-icons-round">warning</span></ErroInput> }
        <label className={`${content}`} htmlFor={name}>{placeholder}</label>
    </Container>
    );
}

export default Input;