import styled from "styled-components";


export const Item = styled.div`
    margin-bottom: 10px;


    .box{
        box-shadow:  0 3px 3px rgba(0,0,0,0.03); 
        border-radius: 8px; 
        z-index: 1;
        cursor: pointer;

    .drag{
        padding: 0;
        margin: -14px 14px 0 -5px;

        button{
            width: 18px;
            word-break: break-word;
            line-height: 6px;
            background: none;
            letter-spacing: -1px;
            color: rgba(0,0,0,0.8);
            cursor: grab;
        }
    }

    .accordion-link{
        width: calc(100% - 36px);    
        background: #fff;
        border: solid 1px #fff;
        padding: 17px 0 17px 17px;
        border-radius: 8px 2px 2px 8px;           
        z-index: 1;
        display: flex;
        align-items: center;
    }
    .tipoIco{
        position: relative;
        left: -8px;
        top: 2px;
        color: var(--orangeBase)  ;
        
        span{
            font-size: 1.5rem;
        }
    }
    .data{
        width: calc(100% - 50px);
    }
    
    h2, .urllink{
        font-size: 0.9rem;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 45px);
    }
    .urllink{
        margin-top: 0px;
        font-size: 0.8rem;        
    }
    .linksordem{
        background: #FCFCFC;
        border-radius:  0 8px 8px 0;
        border-top: solid rgba(0,0,0,0.02) 1px;
        border-left: solid rgba(0,0,0,0.02) 1px;
        border-bottom: solid rgba(255,255,255,0.5) 1px;
        border-right: solid rgba(255,255,255,0.5) 1px;
        position: absolute;
        right: 0;
        height: 100%;
        padding: 5px;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button{
            padding: 0px;
            background: none;
            :hover span{
                color: var(--orangeBase)
            }
        }
    }
    .centered{
        justify-content: center;
    }
    }
`

export const EditItem = styled.div<{colorOne?: string, colorTwo?: string, colorThree?: string, coloritem?: string}>`
    background: rgba(0,0,0,0.05);
    border-radius: 0 0 8px 8px;
    box-shadow:  inset 0 3px 6px rgba(0,0,0,0.04);
    margin-bottom: 10px;
    top: -5px;
    z-index: 0;
    

    .padform{
        padding: 15px;
    }
    input{
        transition:  0.5s ease-in-out;
    }
    input:focus, input:hover{
        background: #fff;
        border: #fff solid 1px;
        box-shadow:  inset 0 3px 3px rgba(0,0,0,0.04), 0 3px 6px rgba(0,0,0,0.05);
    }

    legend{
        font-size: 11px;
        font-family: Inconsolata, Montserrat, Calibri;
        position: absolute;
        z-index: 1;
        padding-left: 8px;
        color: var(--orangeBase);
    }
    .spacebutton{ 
        display: flex;
        justify-content: space-between;  
        align-items: center;
    }
    .del{
        background: none;
        display: flex;
        align-items: center;
        color: #999;

        span{
            margin-bottom: 2px;
        }

        :hover{
            color: var(--purpleBase);
        }
    }
    .save{
        background: #00A56E;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        padding: 8px 13px;
        border-radius: 5px;
    }

    hr{
        width: 100%;
        height: 5px;
        background: ${props => props.coloritem ? props.coloritem : props.colorOne};
        margin: 20px 0 26px 0;
        border-radius: 21px
    }
    .usecolortheme{
        display: flex;
        align-items: center;
        
        .cor{
            background-color: #000;
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-right: 2px;
            cursor: pointer;
        }
        .cor1{
            background-color: ${props => props.colorOne ? props.colorOne : '#000'};
        }
        .cor2{
            background-color: ${props => props.colorTwo ? props.colorTwo : '#000'};
        }
        .cor3{
            background-color: ${props => props.colorThree ? props.colorThree : '#000'};
        }
        button{
            padding: 5px;
            font-size: .8rem;
            border: solid 1px #999;
            color: #999;
            background: none;
            border-radius: 5px;
            cursor: default;

            :hover{
                color: var(--purpleBase)
            }
        }
    }
    .flex2{
        display: flex;
        margin-bottom: 10px;
        fieldset{
            width: calc(100% - 257px);
            margin-right: 12px;
        }

        .compact-picker{
            width: 275px !important;

            > div > span > div{
                margin: 0 !important;
            }
        }              
    }

@media only screen and (max-width: 525px){
    .flex2{
        flex-direction: column;
        fieldset{
            width: auto;
            margin-right: 0px;
            margin-bottom: 20px;
        }

        .compact-picker{
            width: auto !important;            

            > div > span > div{
                margin: 0 !important;
            }
        }              
    }
}
`

export const Hr = styled.hr<{colorTheme?: string, coloritem?: string}>`
    width: 100%;
    height: 5px;
    background: ${props => props.coloritem ? props.coloritem : props.colorTheme};
    margin: 2px 0 0 0;
    border-radius: 21px
`