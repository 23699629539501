import styled  from 'styled-components';

export const Planos = styled.section<{tipopagamento: string}>`

    .planos{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        margin: 20px -5px 0 -5px;

        .boxform{
            margin: 4px;
        }
        .freeplan{
            opacity: 0.8;
        }
        .destack{
            border: solid 1px var(--orangeBase);
        }
    }
    .flag{
        background: var(--orangeBase);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 77%, 49% 100%, 0 77%);
        clip-path: polygon(0 0, 100% 0%, 100% 77%, 49% 100%, 0 77%);
        position: absolute;
        font-size: 0.45rem;
        padding: 5px 8px 10px 8px;
        top: -21px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        right: 0;
        border-radius: 2px;    
    }

    .titleplan{ 
        font-weight: 700;
        display: block;
        padding-bottom: 10px;
        border-bottom: dotted 1px rgba(0,0,0,0.15);
        margin-bottom: 15px;
        color: var(--orangeBase);

        span{
            font-weight: 100;
            font-size: 11px;
            color: rgba(0,0,0,0.8);
            font-family: 'Inconsolata',Calibri,sans-serif,monospace;
            position: absolute;
            left: 0;
            bottom: 2px;
        }
    }
    .flexplan{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .price-details{
        padding: 23px 0 11px 0;
        text-align: center;
        background: #f7f7f7;
        border-radius: 11px;
        margin-bottom: -11px;
        z-index: 2;
    }
    .price-details .price{
        font-size: 55px;
        font-weight: 700;
        position: relative;
        line-height: 1.6rem;
        text-shadow: 1px 1px #f53f38;
    }
    .price-details .price span{ letter-spacing: -3px; }
    .price-details .price span:first-child{ 
        display: ${props => props.tipopagamento === 'ano' ? 'none' : 'inline-block'};
    }
    .price-details .price span:last-child{ 
        display: ${props => props.tipopagamento === 'ano' ? 'inline-block' : 'none'};
    }

    .botao{ display: block; width: 100%;}
    .botao div:first-child{ 
        display: ${props => props.tipopagamento === 'ano' ? 'none' : 'block'};
    }
    .botao div:last-child{ 
        display: ${props => props.tipopagamento === 'ano' ? 'block' : 'none'};
    }

    .price-details .price::before,
    .price-details .price::after{
        position: absolute;
        font-weight: 700;
        text-shadow: none;
    }
    .price-details .price::before{
        content: "R$";
        left: -25px;
        top: 17px;
        font-size: 18px;
    }
    .price-details .price::after{
        content: "/mês";
        right: -35px;
        bottom: 7px;
        font-size: 13px;
    }
    .price-details p{
        font-size: 0.75rem;
        margin: 5px 18% 0 18%;
    }
    .features li{
        display: flex;
        font-size: 0.8rem;
        list-style: none;
        margin-bottom: 6px;
        align-items: center;
    }
    .features li i{
        background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    .features li span{
        margin-left: 27px;
    }
    #tick-mark {
        position: relative;
        display: inline-block;
        width: 0px;
        height: 16px;
    }

    #tick-mark::before {
        position: absolute;
        display: block;
        left: 0;
        top: 5px;
        height: 7px;
        width: 3px;
        background-color: #f53f38;
        content: "";
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
    }

    #tick-mark::after {
        position: absolute;
        left: 0;
        top: 9px;
        bottom: 0;
        height: 3px;
        width: 12px;
        background-color: #336699;
        content: "";
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
    }

    .subtit{
    font-weight: 700;
    text-align: center;
    font-size: 1.1rem;
    }
    .tipopag > div{
    color: var(--orangeBase);
    font-weight: 400;
    font-size: 0.85rem;
    padding: 15px 10px;
    text-transform: uppercase;
    }
    .tipopag{
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    }
    /**** toggle mensal anual *****/
    .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    }
    #ckswitch {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    }

    .tick {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3D2D5E;
    -webkit-transition: .4s;
    transition: .4s;
    }

    .tick:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    }

    .tick {
    background-color: ${props => props.tipopagamento === 'ano' ? '#f53f38' : '#3D2D5E'};
    }

    #ckswitch:focus + .tipopag .switch .tick {
    box-shadow: 0 0 1px #f53f38;
    }

    .tick:before {
    -webkit-transform: translateX(${props => props.tipopagamento === 'ano' ? '26px' : '0'});
    -ms-transform: translateX(${props => props.tipopagamento === 'ano' ? '26px' : '0'});
    transform: translateX(${props => props.tipopagamento === 'ano' ? '26px' : '0'});
    }

    .tick.round {
    border-radius: 34px;
    }

    .tick.round:before {
    border-radius: 50%;
    }
    /**** fim toggle mensal anual *****/

    .centro{ text-align: center }
    .flexpayments{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            margin: 0 10px;
        }
    }
    .economia{
        background: var(--orangeBase);
        color: #fff;
        font-size: 0.75rem;
        font-weight: 400;
        text-align: center;
        border-radius: 0 0 11px 11px;
        margin-top: -11px;
        z-index: 1;
        max-height: ${props => props.tipopagamento === 'ano' ? 'auto' : '0px'};
        padding: ${props => props.tipopagamento === 'ano' ? '15px 5px 5px 5px' : '0px'};
        overflow: hidden;

        span{
            font-weight: 700;
        }
    }

    @media only screen and (max-width:940px){
        .planos {
            grid-template-columns: calc(50% - 20px) calc(50% - 20px);
            gap: 20px;
        }
    }
    @media only screen and (max-width: 550px){
        .planos {
            grid-template-columns: calc(50% - 10px) calc(50% - 10px);
            gap: 10px;
        }
    }
    @media only screen and (max-width: 400px){
        .planos {
            grid-template-columns: calc(100% - 10px);
            gap: 10px;
        }
    }
`

export const DegradeButton = styled.button<{cor1: string, cor2: string}>`
    background: rgb(${props => props.cor1});
    background: -moz-linear-gradient(left,  rgba(${props => props.cor1},1) 0%, rgba(${props => props.cor2},1) 100%); 
    background: -webkit-linear-gradient(left,  rgba(${props => props.cor1},1) 0%,rgba(${props => props.cor2},1) 100%);
    background: linear-gradient(to right,  rgba(${props => props.cor1},1) 0%,rgba(${props => props.cor2},1) 100%); 
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    margin-top: 7px;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 900;
    text-transform: uppercase;


    :hover{
        filter: brightness(130%);
        color: var(--purpleBase)
    }
    span{
        font-size: 0.78rem;
        display: block;
        color: rgba(255,255,255,0.7);
        text-transform: lowercase;
    }
`

export const DegradeLink = styled.a<{cor1: string, cor2: string}>`
    background: rgb(${props => props.cor1});
    background: -moz-linear-gradient(left,  rgba(${props => props.cor1},1) 0%, rgba(${props => props.cor2},1) 100%); 
    background: -webkit-linear-gradient(left,  rgba(${props => props.cor1},1) 0%,rgba(${props => props.cor2},1) 100%);
    background: linear-gradient(to right,  rgba(${props => props.cor1},1) 0%,rgba(${props => props.cor2},1) 100%); 
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    margin-top: 7px;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Inconsolata', Calibri, sans-serif, monospace;
    text-decoration: none;
    display: block;
    text-align: center;

    :hover{
        filter: brightness(130%);
        color: var(--purpleBase)
    }
    span{
        font-size: 0.78rem;
        display: block;
        color: rgba(255,255,255,0.7);
        text-transform: lowercase;
    }
`
