import React, { useContext } from 'react';
import { ScrollArea } from '../../styles/global';
import { PageContext } from '../../contexts/PageContext';
import FormUrl from './formUrl';
import FormDescription from './formDescription';
import FormContact from './formContact';

const Info: React.FC = () => {
  const page = useContext(PageContext);

  return(
    <div className="contentpage">
      <h1>
        Informações da página
        <span>VOCÊ ESTA EDITANDO A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
      </h1>

      <ScrollArea size={63}>
        <FormUrl />
        <FormDescription />
        <FormContact />
        <div className="gradfooter"></div>
      </ScrollArea>
    </div>
  );
}

export default Info;