import React from 'react';
import { Route, Routes as AllRoutes } from "react-router-dom";
import { RequireAuth } from '../contexts/RequireAuth';

import SignIn from '../pages/SignIn';
import ResetPass from '../pages/ResetPass';

import Links from '../pages/Links';
import Info from '../pages/Info';
import Config from '../pages/Config';
import Design from '../pages/Design';
import Upgrade from '../pages/Upgrade';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import Contact from '../pages/Contact';
import Profile from '../pages/Profile';
import Themes from '../pages/Themes';
import NewPage from '../pages/NewPage';
import Analitcs from '../pages/Analitcs';
import Confirmation from '../pages/Upgrade/confirmation';

interface TypeScreen {
    prevTheme: (e:any) => void;
}

const Routes : React.FC<TypeScreen> = ({prevTheme}) => (
    <AllRoutes>
        <Route path="/" element={<SignIn />} />
        <Route path="/resetpass" element={<ResetPass />} />
        <Route path="*" element={<SignIn />} />

        <Route path="/links" element={<RequireAuth><Links /></RequireAuth>} />
        <Route path="/info" element={<RequireAuth><Info /></RequireAuth>} />
        <Route path="/config" element={<RequireAuth><Config /></RequireAuth>} />
        <Route path="/design" element={<RequireAuth><Design prevTheme={(e:any) => prevTheme(e)} /></RequireAuth>} />
        <Route path="/theme" element={<RequireAuth><Themes prevTheme={(e:any) => prevTheme(e)} /></RequireAuth>} />
        
        <Route path="/upgrade" element={<RequireAuth><Upgrade /></RequireAuth>} />
        <Route path="/terms" element={<RequireAuth><Terms /></RequireAuth>} />
        <Route path="/privacy" element={<RequireAuth><Privacy /></RequireAuth>} />
        <Route path="/contact" element={<RequireAuth><Contact /></RequireAuth>} />
        <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
        <Route path="/newpage" element={<RequireAuth><NewPage /></RequireAuth>} />
        <Route path="/analitcs" element={<RequireAuth><Analitcs /></RequireAuth>} />
        <Route path="/confirmation" element={<RequireAuth><Confirmation /></RequireAuth>} />
    </AllRoutes>        
);

export default Routes;
