import {createGlobalStyle} from 'styled-components';
import styled from "styled-components";
import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = createGlobalStyle`

    :root{
        --background: #F2F0F6;
        --purpleBase: #31244B;
        --purpleLive: #AB00A8;
        --orangeBase: #F64434;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        position: relative;
        border: none;
    }
    a, a span{    
        color: var(--orangeBase);
    }
    a, a span, button, button span, .economia{    
        -moz-transition:  0.5s ease-in-out;
        -o-transition:  0.5s ease-in-out;
        -webkit-transition:  0.5s ease-in-out;
        transition:  0.5s ease-in-out;
    }

    a:hover{    
        color: var(--purpleLive);
    }
    a.likebtn{
        padding: 10px 15px;
        display: inline-block;
        background: #00A56E;
        margin: 0 5px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
    }
    a.likebtn:hover{ color: #fff; background: var(--purpleLive); }
    body, html, .gradient1, .gradient2, main{
        height: 100%;
        width: 100%;    
    }
    html{

        @media (max-width: 1080px) {
            font-size: 93.75%; 
        }
        @media (max-width: 1080px) {
            font-size: 87.5%; 
        }
    }
    body{
        background: var(-background);
        --webkit-font-smoothing: antialiased;
        font-smooth: always;
        color: var(--purpleBase);
    }
    body, input, textarea, button, .Toastify__toast-body{
        font-family: 'Inconsolata', Calibri, sans-serif, monospace;
        font-weight: 400;
    }
    .Toastify__toast-body{
        text-transform: uppercase;
        font-size: 85.5%
    }
    .gradient1, .gradient2{ display: flex; position: absolute; }
    .gradient1{
        z-index: 0;
        height: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d4c9da+0,d4c9da+70&1+0,0+70 */
        background: -moz-linear-gradient(-38deg,  rgba(212,201,218,1) 0%, rgba(212,201,218,0) 70%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-38deg,  rgba(212,201,218,1) 0%,rgba(212,201,218,0) 70%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(142deg,  rgba(212,201,218,1) 0%,rgba(212,201,218,0) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4c9da', endColorstr='#00d4c9da',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    }
    .gradient2{
        z-index: 1;
        height: 100%;
        bottom: 0;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d8d6c3+37,d8d6c3+50,d8d6c3+50,c6cfde+100&0+39,1+99 */
        background: -moz-linear-gradient(top,  rgba(216,214,195,0) 37%, rgba(216,214,195,0) 39%, rgba(216,214,195,0.18) 50%, rgba(198,207,221,1) 99%, rgba(198,207,222,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(216,214,195,0) 37%,rgba(216,214,195,0) 39%,rgba(216,214,195,0.18) 50%,rgba(198,207,221,1) 99%,rgba(198,207,222,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(216,214,195,0) 37%,rgba(216,214,195,0) 39%,rgba(216,214,195,0.18) 50%,rgba(198,207,221,1) 99%,rgba(198,207,222,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d8d6c3', endColorstr='#c6cfde',GradientType=0 ); /* IE6-9 */
    }

    .boxmain{
        background: rgba(255,255,255,0.6);
        border-radius: 11px;
        z-index: 2;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(20px);
        box-shadow: 2px 2px 3px rgba(61, 45, 94, 0.05);
        overflow: hidden;
        justify-content: space-between;
    }
    .logout{
        background: #fff;
        height: 68%;
        margin: auto 20%;
        min-width: 882px;
        max-width: 1200px;
        min-height: 520px;
    }

    .login{
        display: flex; 
        font-family: Montserrat, 'Inconsolata', Calibri, sans-serif, monospace;
        max-width: 1600px;
        max-height: 800px;
        min-height: 660px;
        border-radius: 11px 0px 0px 11px;
    }
    .login::before{
        content: '';
        position: absolute;
        //background: rgba(246,68,52,0.05);
        background: rgba(255,247,227,0.8);
        width: 50%;
        height: 500px;
        top: -38%;
        border-radius: 5px 30px 70px 70px; 
        transform: rotate(-40deg);    
        box-shadow: 50px 10px 0px #fff;
    }

    button{
        cursor: pointer;
    }
    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    input, select, textarea, .rsw-ce{
        background: var(--background);
        border: none;
        font-size: 0.9rem;
        font-family: 'Inconsolata', Calibri, Arial, monospace;
        padding: 15px;
        margin: 6px 0;
        border-radius: 8px;
        width: 100%;
        font-weight: 400;
        color: #3D2D5E;
        outline: none;
        border: solid rgba(0,0,0,0.051) 1px;    
    }
    input:focus{
        color: #000;  
    }
    textarea{ resize: none; }
    .rsw-ce{ 
        border: none; 
        margin: 0; 
        border-radius: unset; 
        height: 100px;
        overflow-x: auto;

        ul{
            margin-left: 16px;
        }
    
    }
    .rsw-toolbar {        
        background-color: rgba(255,255,255,0.8);        
    }

    ::placeholder { color: rgba(0,0,0,0.40);}
    :-ms-input-placeholder { color: #8164b9; }
    ::-ms-input-placeholder { color: #8164b9;}
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: #000;
        box-shadow: 0 0 0px 1000px var(--background) inset;
    }
    input[type=checkbox]
    {
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.5); /* IE */
        -moz-transform: scale(1.5); /* FF */
        -webkit-transform: scale(1.5); /* Safari and Chrome */
        -o-transform: scale(1.5); /* Opera */
        transform: scale(1.5);
        margin: 0px 12px 0 4px;
        accent-color: #06a74c;
        width: auto;
    }
    .compact-picker input{
        margin: unset;
    }

    form{
        width: 100%;
    }

    .contentpage{
        padding: 17px 0 0 0;
        height: calc(100% - 55px);
    }
    h1{ 
        margin-bottom: 15px;
        color: var(--purpleBase);

        span{
            display: block;
            font-size: 9.5px;
            font-weight: 200;
            text-transform: uppercase;
        }
    }
    .gridbuttons{
        display: grid;
        margin-bottom: 15px;
        grid-template-columns: calc(50% - 42px) calc(50% - 42px) auto;
        column-gap: 22px;
        justify-content: space-between;
        align-items: stretch;

        #divSubItem{
            display: flex;
        }
    }
    @media only screen and (max-width:550px){
        .gridbuttons{
            grid-template-columns: calc(50% - 28px) calc(50% - 28px) auto;
            column-gap: 12px;
        }
    }


    .tooltip {
    display: inline;
    position: relative;
    z-index: 9;
    }

    .tooltip:hover:after {
        background: var(--orangeBase); 
        box-shadow:   0 1px 0 #B0554E, 0 10px 15px rgba(0,0,0,0.2);    
        font-size: 11px;
        border-radius: 5px;
        bottom: -39px;
        content: attr(data-title);
        text-decoration: none;
        padding: 10px 15px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        white-space: nowrap;
        z-index: 9;
        color: #fff;
        font-weight: 700;
    }

    .tooltip:hover:before {
        border: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent var(--orangeBase) transparent;
        bottom: -5px;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        z-index: 10;
    }
    .plus{
        background: none;
        color: var(--orangeBase);

        span{ 
            font-size: 2.3rem;
            background: none;
            border-radius: 50%;
            margin-top: 4px;
        }
        :hover span{
            opacity: 0.7;
        }
    }

    .subitems{
        position: absolute;
        z-index: 9;
        display: none;
        top: 82%;
        left: 83%;

        ul{ 
            list-style: none;
            background: #fff;
            border-radius: 8px;
            padding: 5px;
            width: 100%;
            box-shadow: 0px 8px 8px rgba(0,0,0,0.1);
            border: solid 1px rgba(0,0,0,0.05);
            left: -86%;
            transform: translateX(-9%);
        }
        ul li{ display: block; white-space: nowrap; text-align: center}
        ul li button, ul li a{ 
            padding: 10px ;
            display: block;
            width: 100%;
            text-decoration: none;
            font-weight: 700;
            font-size: .8rem;
            color: var(--purpleBase);
            background: none;
            font-family: Montserrat;
            border-bottom: 1px rgba(0,0,0,0.04) solid;
        }
        ul li button:hover, ul li a:hover{
            color: var(--orangeBase);
        }
        ul li:last-child button, ul a:last-child li{
            border: none;
        }
    }

    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background: rgba(0,0,0,.05); 
    border-radius:  5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #fff; 
    border: solid rgba(0,0,0,.05) 1px;
    border-radius:  5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: var(--orangeBase); 
    }

    .accordion-link{
    text-decoration: none;
    color: inherit;
    display: block;
    }
    .accordion-link:hover{color: inherit;}
    .answer{  
    overflow: hidden;
    position: relative;
    display: none;
    }
    .accordion-item:target .answer, .opened{
    display: block;
    animation: fade-in 1s;
    }
    @keyframes fade-in {
    0% {
        opacity: 0;
        //max-height: 0;
    }
    100% {
        opacity: 1;
        //max-height: 1950px;
    }
    }

    .simpleflex{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .marg0{
        margin: 0;
    }
    .minHsimpleflex{
        height: 67px;
    }


    .noitems{
        width: 100%;
        text-align: center;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            h4{
                margin-bottom: 0;
            }
            small{ font-size: 0.6rem; }
        }
    }

    .semlink{
        width: 80% !important;
        margin: 0 10%;
    }

    .spacesugestoes{
        text-align: center;
        display: flex;
        flex-direction: column;
        h4{
            margin-bottom: 0;
        }
        small{ font-size: 0.6rem; }
    }
    .sugestoes{
        width: 100%;
        > div {
            padding:  8px 8px;
            background: rgba(255,255,255, 0.5);
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.85rem;
            border-radius: 5px;
            margin: 2px 0;
        }
        button{
            background: var(--orangeBase);
            color: #fff;
            border-radius: 5px;
            padding: 7px;
            font-size: 0.7rem;
            font-weight: bold;
            margin: -3px;
        }
    }

    h4{ margin-bottom: 10px}
    .boxform{
        box-shadow:  0 3px 3px rgba(0,0,0,0.03);
        background: #fff;
        border: solid 1px #fff;
        padding: 17px;
        border-radius: 8px;           
        z-index: 1;
        display: block;
        align-items: center;
        margin-bottom: 20px;

        input, textarea{
            transition:  0.5s ease-in-out;
        }
        input:focus, input:hover, textarea:focus, textarea:hover {
            background: rgba(242,240,246,0.7);
            box-shadow:  inset 0 3px 3px rgba(0,0,0,0.06);
        }

        legend{
            font-size: 11px;
            font-family: Inconsolata, Montserrat, Calibri;
            position: absolute;
            z-index: 1;
            margin-left: 8px;
            padding: 1px 2px 1px 4px;
            border-radius: 3px;
            color: var(--orangeBase);
            background: #fff;
        }
        .spacebutton{ 
            display: flex;
            justify-content: space-between;  
            align-items: center;
        }
        .del{
            background: none;
            display: flex;
            align-items: center;
            color: #999;

            span{
                margin-bottom: 2px;
            }

            :hover{
                color: var(--purpleBase);
            }
        }
        .save, .delete{
            background: #00A56E;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 13px;
            padding: 8px 13px;
            border-radius: 5px;
        }
        .delete{
            background: red;
        }
        
    }
    .btntransp{
        background: none;
        cursor: pointer;
    }

    .urlpage{
        background: var(--orangeBase);
        display: inline-block;
        padding: 1px 3px;
        color: #fff;
        border-radius: 2px;
        font-weight: 700;
        text-decoration: none;
        letter-spacing: 1px;
    }
    .urlpage:hover{
        background: #fff;
        color: var(--orangeBase)
    }
    .desctitle{
        font-size: 12px;
        font-weight: 400;
        border-left: #ccc solid 1px;
        padding: 1px 0 2px 8px;    
        margin: 0 0 1px 5px;
        top: -1px;
        color: rgba(0,0,0,0.7);
        font-family: Inconsolata, Montserrat, Calibri;
    }
    .miniaviso{
        font-size: 11px;
        max-width: 75%;
        font-family: Inconsolata, Montserrat, Calibri;
        color: rgba(0,0,0,0.7);
    }

    .minilinks{
        display: flex;
        justify-content: space-around;
        margin-top: 10px;

        a, button{ 
            font-size: 0.81rem; 
            display: inline-block; 
            text-decoration: none; 
            padding:5px; 
            margin: 0 2px;
            white-space: nowrap;
            background:  none;
            color: var(--orangeBase);

            :hover{
                color: var(--purpleBase);
            }
        } 
    }
    .trocaurl{
        padding-left: 80px;
    }
    .labelurl{
        position: absolute;
        z-index: 2;
        font-family: Inconsolata, Montserrat, Calibri;
        font-size: 0.9rem;
        color: var(--orangeBase);
        padding: 15px 0 0 15px;
        margin-top: 7px;
    }
    /* selected das cores */
    .selected{
        box-shadow: 0 0 1px 3px #ccc;
    }
    .w100{
        max-width: 100%;
    }
    .w100b{
        width: 100%;
    }
    .orangecolor{
        color: var(--orangeBase)
    }
    .resetbutton{
        padding: 7px 10px;
        font-size: .8rem;
        border: solid 1px #999;
        color: #999;
        background: none;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 10px 0 0;
        white-space: nowrap;
        text-decoration: none;
    }
    .eyedropper{
        border: none;
        padding: 2px 3px 0 3px;
    }
    .styleiframe{
        border:0;
        width: 100%;
        height: calc(100% - 4px);
        background: transparent;
    }
    .centerall{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-height: 88%;
    }

    .chrome-picker {
        width: 244px;
        background: rgb(255, 255, 255);
        border-radius: 2px;
        box-shadow: unset;
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: unset !important;
        /* height: 57px;*/
    }
    .chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) { display: none; }
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > label, 
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > label, 
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) > label,
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div:nth-child(1) > label {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        margin-top: 0 !important;
    }
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) { display: none; }
    .chrome-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
        margin-top: -1px !important;
    }
    .chrome-picker > div:nth-child(2) {
        padding: 10px 2px 0 3px !important;    
    }
    .chrome-picker > div:nth-child(2) > div:nth-child(2) {
        padding-top: 0px !important;
    }
    /*
    .chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: baseline;
    }*/
    .hue-horizontal{
        border-radius: 9px !important;
    }

    .slider-picker > div:nth-child(2) > div{
        margin-top: 10px !important;
        margin-bottom: 12px;
    }

    .popover { position: absolute; z-index: 2;}
    .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .disabledAll{
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }
    .blockedByPlan{
        pointer-events: none;
        cursor: default;
        background: rgba(255,255,255,0.6);
        *{opacity:0.8; filter: grayscale(1);}

        .save{
            background-color: #bec8da;
        }
        
        :after{
            content: attr(data-title);        
            position: absolute;
            background: #fff;
            z-index: 2;
            top: 0;
            right: 10px;
            padding: 5px 8px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 0.55rem;
            color: var(--orangeBase);
            border-radius: 0 0 3px 3px;
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        }

        
    }
    .listblockedByPlan{
        *{opacity:0.8; filter: grayscale(1);}
    }

    @media only screen and (max-width:1100px){
        .login{
            max-height: unset;
            border-radius: 0;
        }
    }

    @media only screen and (max-width:890px){
        .logout {
            background: #fff;
            height: 100%;
            margin: 0;
            min-width: unset;
            max-width: unset;
            min-height: unset;
            border-radius: 0;
        }
    }

    @media only screen and (max-width:660px){ 
        .formcolor .miniaviso{
            display: none;
        }
        .colordesign{ 
            display: block !important; 
            > div:first-child {
                width: 100% !important ;
            }  
            > div:last-child {
                display: flex;
                justify-content: center;
            }         
        } 
    }

    .noPage{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
    }
`
export const FormSeparador = styled.div<{coloritem?: string, colorTheme?: string}>`

    hr{
        width: 100%;
        height: 5px;
        background: ${props => props.coloritem ? props.coloritem : props.colorTheme};
        margin: 20px 0 26px 0;
        border-radius: 21px
    }
    .flex2{
        display: flex;
        margin-bottom: 10px;
        fieldset{
            width: calc(100% - 257px);
            margin-right: 12px;
        }

        .compact-picker{
            width: 275px !important;
            > div > span > div{
                margin: 0 !important;
            }
        }

        @media only screen and (max-width:550px){            
            display: block;
            fieldset{
                width: 100%;
                margin-right: 0px;
                margin-bottom: 20px;
            }
        }  
    }
    .usecolortheme{
        display: flex;
        align-items: center;
        
        .cor{
            background-color: ${props => props.colorTheme ? props.colorTheme : '#000'};
            width: 24px;
            height: 24px;
            border-radius: 5px;
            margin-right: 2px;
        }
        button{
            padding: 5px;
            font-size: .8rem;
            border: solid 1px #999;
            color: #999;
            background: none;
            border-radius: 5px;
            cursor: pointer;

            :hover{
                color: var(--purpleBase)
            }
        }
    }

`

export const Main = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    @media only screen and (max-width:1100px){
        padding: 0px;
    }
`

export const SectionContent = styled.section<{openclosemenu?: boolean}>`
    width: ${props => props.openclosemenu ? 'calc(100% - 581px)' : 'calc(100% - 482px)'};
    padding: 25px 30px 5px 1px;

    @media only screen and (max-width:1000px){
        width: calc(100% - 122px);
    }

    @media only screen and (max-width:550px){
        width: calc(100% - 0px);
        padding: 15px;
    }

`

export const SectionNoPreviewContent = styled.section<{openclosemenu?: boolean}>`
    width: ${props => props.openclosemenu ? 'calc(100% - 221px)' : 'calc(100% - 122px)'};
    padding: 25px 30px 5px 8px;

    @media only screen and (max-width:550px){
        width: calc(100% - 0px);
        padding: 15px;
    }
`

export const ScrollArea = styled.div<{size?: number}>`
    height: ${props => props.size ? 'calc(100% - '+props.size+'px)' : 'calc(100% - 145px)'};
    overflow: auto;
    margin: 0 -14px 0px -10px;
    padding: 1px 11px 5px 10px;

    .gradfooter{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#edf0f4+0,edf0f4+100&0+0,1+81 */
        background: -moz-linear-gradient(top,  rgba(237,240,244,0) 0%, rgba(237,240,244,1) 81%, rgba(237,240,244,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(237,240,244,0) 0%,rgba(237,240,244,1) 81%,rgba(237,240,244,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(237,240,244,0) 0%,rgba(237,240,244,1) 81%,rgba(237,240,244,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00edf0f4', endColorstr='#edf0f4',GradientType=0 ); /* IE6-9 */
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 30px;
        z-index: 2;
        margin-top: -9px;
        left: 0px;
        border-radius: 5px 0 0 0;
    }    
    .sortable-chosen{
        .box .accordion-link{
            background: #F4F4F4;
            border: solid 1px #D4D4D4;
        }
    }
`

export const FramePayment = styled.div`
    position: absolute;
    background: #fff;
    width: 90%;
    max-width: 360px;
    z-index: 99999;
    right: 0;
    top: 0;
    height: 100%;
    box-shadow: 0 0 20px rgba(0,0,0,0.2);
`