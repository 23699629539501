import React, { useRef, useState, useContext, useEffect } from 'react';
import ToggleSwitch from '../Elements/ToggleSwitch';
import { Item, EditItem } from './styles';
import { typeShowItem } from '../../types/ShowItem';

import { AuthContext } from "../../contexts/AuthContext";
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { ActivityIndicatorBtn } from '../Elements/ActivityIndicator';
import { CheckTitle, IsEmpty } from '../../utils/functions';

import parse from 'html-react-parser';
import { 
    BtnBold, BtnItalic, BtnUnderline, BtnBulletList, 
    BtnUndo, BtnRedo, Editor, Toolbar, EditorProvider 
  } from 'react-simple-wysiwyg';
import { PageContext } from '../../contexts/PageContext';

const ItemTituloDescricao: React.FC<typeShowItem> = ({idx, totalitens, data, arrangeItems, updateItem, scrollIfOpen}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ title, setTitle ] = useState(data.title);
    const [ description, setDescription ] = useState(data.newItem ? data.description : String(parse(data.description)));
    const [ partOfItem, setPartOfItem ] = useState(Number(data.partOfItem) === 1 ? true : false);
    const [ hide, setHide ] = useState(Number(data.hide));
    const [ loadsave, setLoadSave ] = useState(false);
    
    
    const formedit = useRef<any>(null);
    const collapse = () => {
        formedit.current.classList.toggle('opened');
        scrollIfOpen(idx); 
    }
    
    /** REFERENTE AO EDITOR DE TEXTO **/
    const removeHtmlFromPast = function (event : any) {
        event.preventDefault();
        let paste = event.clipboardData.getData('text');
        changeDescricao(paste.trim());
    };

    useEffect(() => {
        setTitle(data.title);
        setDescription(data.newItem ? data.description : String(parse(data.description)));
        setPartOfItem(Number(data.partOfItem) === 1 ? true : false);
        setHide(Number(data.hide));
    }, [ data, page ]);


    useEffect(() => {
        let mounted = true;    
        if(mounted){
            document.getElementById('textarea'+idx)?.addEventListener('paste', removeHtmlFromPast, false); 
        }
        return function cleanup() { 
            document.getElementById('textarea'+idx)?.removeEventListener('paste', removeHtmlFromPast, false);
            mounted = false;
        }
    });
  
    function changeDescricao(e: string){
        let semformatacao = e.replace(/<[^>]*>?/gm, ''); 
        if(semformatacao.length <= 1200){
            setDescription(e);
        } else {
            setDescription(limitDescricao(e, 1200));
        }
    }
    function limitDescricao (string = '', limit = 0) {  
        return string.substring(0, limit)
    }

    async function handleUpdateItem(){        
        if(partOfItem && !CheckTitle(title.trim())){
            toast.error('No campo Títuo, digite entre 3 e 50 caracteres', { theme: 'colored' });
            return;
        }
        if(IsEmpty(description.trim())){
            toast.error('Descrição não pode ficar em branco', { theme: 'colored' });
            return;        
        }    
        setLoadSave(true); 
        try {
            const dataupdated = {
                idUser : auth.user?.id,
                id : Number(data.id),
                idpage : data.idpage,
                title : title,
                url : data.url,
                color : data.color,
                hide : hide,
                image : data.image,
                partOfItem: partOfItem ? 1 : 0,
                description: description
            }
            await api.post('/item_update.php', dataupdated);
            
            const newitem = {
                id : data.id, 
                title : title,
                hide : hide,
                partOfItem: partOfItem ? 1 : 0,
                description: description
            }   

            updateItem(newitem, 'update');
            
            setLoadSave(false);
            toast.success('Atualização realizada com sucesso');

        } catch (error:any) {
            if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            } else {
                toast.error('Erro ao atualizar', { theme: 'colored' }); 
            };
        }
        setLoadSave(false);
    }

    return (
    <Item draggable={false} className="accordion-item"> 
        <div className="box">
            <div onClick={collapse} className="accordion-link" title="Clique para editar">
                <div className="drag" title="Arrastar">                    
                    <button>...............</button>                    
                </div>
                <div className="tipoIco">
                    <span className="material-icons-round">description</span>
                </div>                
                <div className='data'>
                    <h2>{ data.title ? data.title : '* Descrição sem título'}</h2>
                </div>
            </div>
            <div className="linksordem">
                <button 
                    disabled={idx > 0 ? false : true} 
                    onClick={() => arrangeItems(idx, idx-1)}>
                        <span className="material-icons-round">expand_less</span>
                </button>
                <button 
                    disabled={idx === totalitens-1 ? true : false}  
                    onClick={() => arrangeItems(idx, idx+1)}>
                        <span className="material-icons-round">expand_more</span>
                </button>
            </div>
        </div>
        <EditItem ref={formedit} className="answer">
            <div className="padform">
                <fieldset>
                    <legend>Titulo:</legend>
                    <input onChange={(e:any) => setTitle(e.target.value)} type="text" value={title} minLength={3} maxLength={49} />
                </fieldset>
                <fieldset>
                    <legend>Descrição:</legend>
                    <br />
                    <EditorProvider>
                    <Editor id={`textarea`+idx} value={description} onChange={(e:any) => changeDescricao(e.target.value)}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnUndo />
                        <BtnRedo />
                      </Toolbar>
                    </Editor>
                    </EditorProvider>                    
                    <div style={{textAlign: 'right', fontSize: '10px', margin: '0 5px 0 0'}}>
                      {description.replace(/<[^>]*>?/gm, '').length}/1200
                    </div>
                  </fieldset>
                  <fieldset style={{marginBottom: '10px', display: 'flex', lineHeight: '0.9rem', alignItems: 'center'}}>
                    <input type="checkbox" id="partofitem" name="partofitem" onClick={() => setPartOfItem(!partOfItem)} defaultChecked={partOfItem} />
                    <label htmlFor="partofitem">
                      <small>Utilizar modo Collapsible</small><br />
                      <small><small style={{lineHeight: '9px'}}>No modo Collapsible, o usuário precisa clicar no título para abrir a descrição. (Título obrigatório)</small></small>
                    </label>
                  </fieldset>

                <div className='spacebutton'>
                    <div className='spacebutton'>
                        <ToggleSwitch changeHide={(e) => setHide(e)} toggled={hide === 0 ? true : false} />
                        <button onClick={() => updateItem(data, 'delete')} className='del' title='Deletar pra sempre'>
                            <span className="material-icons-round">delete_forever</span>Deletar
                        </button>
                    </div>
                    
                    <button onClick={() => handleUpdateItem()} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                    </button>
                </div>
            </div>
        </EditItem>           
    </Item>
    );
}

export default ItemTituloDescricao;