import React, { useRef, useState, useContext, useEffect } from 'react';
import ToggleSwitch from '../Elements/ToggleSwitch';
import { Item, EditItem } from './styles';
import { typeShowItem } from '../../types/ShowItem';

import { AuthContext } from "../../contexts/AuthContext";
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { ActivityIndicatorBtn } from '../Elements/ActivityIndicator';
import { CheckTitle } from '../../utils/functions';
import { PageContext } from '../../contexts/PageContext';

const ItemTitulo: React.FC<typeShowItem> = ({idx, totalitens, data, arrangeItems, updateItem, scrollIfOpen}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ title, setTitle ] = useState(data.title);
    const [ hide, setHide ] = useState(Number(data.hide));
    const [ loadsave, setLoadSave ] = useState(false);
    
    const formedit = useRef<any>(null);
    const collapse = () => {
        formedit.current.classList.toggle('opened');
        scrollIfOpen(idx); 
    }

    useEffect(() => {
        setTitle(data.title);
        setHide(Number(data.hide));
        //formedit.current.classList.remove('opened');          
    }, [data, page])
    
    async function handleUpdateItem(){        
        if(!CheckTitle(title.trim())){
            toast.error('No campo Títuo, digite entre 3 e 25 caracteres', { theme: 'colored' });
            return;
        }
        setLoadSave(true); 
        try {
            const dataupdated = {
                idUser : auth.user?.id,
                id : Number(data.id),
                idpage : data.idpage,
                title : title,
                url : data.url,
                color : data.color,
                hide : hide,
                image : data.image,
                partOfItem: 0,
                description: '',
            }
            await api.post('/item_update.php', dataupdated);
            
            const newitem = {
                id : data.id, 
                title : title,
                hide : hide,
            }   

            updateItem(newitem, 'update');
            
            setLoadSave(false);
            toast.success('Atualização realizada com sucesso');

        } catch (error:any) {
            if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            } else {
                toast.error('Erro ao atualizar', { theme: 'colored' }); 
            };
        }
        setLoadSave(false);
    }

    return (
    <Item draggable={false} className="accordion-item"> 
        <div className="box">
            <div onClick={collapse} className="accordion-link" title="Clique para editar">
                <div className="drag" title="Arrastar">                    
                    <button>...............</button>                    
                </div>
                <div className="tipoIco">
                    <span className="material-icons-round">title</span>
                </div>                
                <div className='data'>
                    <h2>{data.title}</h2>
                </div>
            </div>
            <div className="linksordem">
                <button 
                    disabled={idx > 0 ? false : true} 
                    onClick={() => arrangeItems(idx, idx-1)}>
                        <span className="material-icons-round">expand_less</span>
                </button>
                <button 
                    disabled={idx === totalitens-1 ? true : false}  
                    onClick={() => arrangeItems(idx, idx+1)}>
                        <span className="material-icons-round">expand_more</span>
                </button>
            </div>
        </div>
        <EditItem ref={formedit} className="answer">
            <div className="padform">
                <fieldset>
                    <legend>Titulo:</legend>
                    <input onChange={(e:any) => setTitle(e.target.value)} type="text" value={title} minLength={3} maxLength={50} />
                </fieldset>
                <div className='spacebutton'>
                    <div className='spacebutton'>
                        <ToggleSwitch changeHide={(e) => setHide(e)} toggled={hide === 0 ? true : false} />
                        <button onClick={() => updateItem(data, 'delete')} className='del' title='Deletar pra sempre'>
                            <span className="material-icons-round">delete_forever</span>Deletar
                        </button>
                    </div>
                    
                    <button onClick={() => handleUpdateItem()} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                    </button>
                </div>
            </div>
        </EditItem>           
    </Item>
    );
}

export default ItemTitulo;