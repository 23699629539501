import React from 'react';

import { Container } from './styles';

interface ConfirmProps {
    closeConfirm: () => void,
    title: string,
    icon: string,
    text: string,
    labelbtn: string,
    returnDialog: (e: boolean) => void,
}

const ConfirmDialog: React.FC<ConfirmProps> = ({closeConfirm, title, icon, text, labelbtn, returnDialog}) => {
  return (
    <Container>
        <div className="boxform">
            <div className='spacebutton'>
                <h2>{title}</h2>
                <button className='btntransp' onClick={closeConfirm}>
                <span className="material-icons-round">close</span>
                </button>
            </div>
            <div className='simpleflex' style={{alignItems: 'flex-start'}}>
                <span className="material-icons-round icodestak">{icon}</span>
                <div dangerouslySetInnerHTML={{ __html: "<p><small>"+text+"</small></p>"}} />
            </div>

            <div className='spacebutton'>
                <button className='del' title='Cancelar' onClick={closeConfirm}>
                    <span className="material-icons-round">cancel</span> &nbsp;Voltar
                </button>

                <button onClick={() => returnDialog(true)} className='delete'>{labelbtn}</button>
            </div>
        </div>
    </Container>
  );
}

export default ConfirmDialog;