import React, { useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from '../../contexts/PageContext';
import { GenerateNewOgImage } from '../../utils/functions';
import { ActivityIndicator } from '../Elements/ActivityIndicator';
import { Container } from './styles';

interface TypePreview {
  previewTheme?: any|boolean
}

const PreviewPage: React.FC<TypePreview> = ({previewTheme}) => {

  const page = useContext(PageContext); 
  
  const frame = useRef() as React.MutableRefObject<HTMLIFrameElement>;
  const [urlpage, setUrlPage ] = useState(`${process.env.REACT_APP_URL_PROFILE}/${page.page?.url}`);
  const [avisoPrev, setAvisoPrev] = useState(false);
  const [ openPrev, setOpenPrev ] = useState(false);
  const ogImage = process.env.REACT_APP_API+'/imgs/ogImages/'+page.page?.id+'.png';

  useEffect(() => {
    const random = Math.random();
    if(page.page){
      if(previewTheme !== false && previewTheme !== undefined){
        setAvisoPrev(true);
        setUrlPage(`${process.env.REACT_APP_URL_PROFILE}/${page.page?.url}?preview=${previewTheme.id}&c1=${previewTheme.colorOne.replace('#', '')}&c2=${previewTheme.colorTwo.replace('#', '')}&c3=${previewTheme.colorThree.replace('#', '')}&fnt=${previewTheme.font}&?rand=${random}`);
      } else {     
        setAvisoPrev(false); 
        setUrlPage(`${process.env.REACT_APP_URL_PROFILE}/${page.page?.url}?random=${random}`);
      }  
    }
  }, [page, previewTheme]);

  async function createOgImage(){
    if(page.page){
      GenerateNewOgImage(page.page);      
    }
  }

  return (
    <Container openPreview={openPrev}>    
      <div className="toglePreview tooltip" data-title='Preview'>
        <button className={!page.page ? 'disabledAll' : ''} onClick={() => setOpenPrev(!openPrev)}>
            <span className="material-icons-round">{openPrev ? 'close' : 'remove_red_eye'}</span>
        </button>
      </div>  
      {avisoPrev &&
        <a className='aviso tooltip' target="prevFrame" onClick={() => setAvisoPrev(false)} href={`${process.env.REACT_APP_URL_PROFILE}/${page.page?.url}`} data-title='voltar ao tema padrão'>
          ESTE NÃO É SEU TEMA ATUAL
        </a>
      }
      {page.page?.url && 
        <>
          <img alt="noimage" style={{display: 'none'}} width={0} height={0} src={ogImage} onError={() => createOgImage()} />
          <iframe id="prevFrame" title='prevFrame' name="prevFrame" ref={frame} src={urlpage} width="360" height="100%" frameBorder="0"></iframe>
          <div style={{zIndex: 0, width: '100%', height: '100%'}}><ActivityIndicator /></div>
        </>
      }
    </Container>
    );
}

export default PreviewPage;