import styled from "styled-components";

export const TotalVisitas = styled.div`
    display: flex;
    background: rgba(229, 232, 236, 0.4);
    padding: 5px 5px 5px 13px;
    border-radius: 7px;
    justify-content:flex-start;
    width: fit-content;
    border-bottom: solid 1px rgba(229, 232, 236, 1);
    border-right: solid 1px rgba(229, 232, 236, 1);
    margin-bottom: 10px;

    .total{
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 400;
        color: var(--orangeBase);
        display: inline-block;
    }
    .label{
        font-size: 0.5rem;
        font-weight: 600;
        width: 80px;
        display: flex;
        align-items: center;
        background: #fff;
        color: rgba(0,0,0,0.6);
        padding:  8px 10px;
        border-radius: 5px;
        margin-left: 8px;
    }
    @media only screen and (max-width:480px){
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        .total{
            text-align: center;
            font-size: 2rem;
        }
        .label{
            margin: 0;
            text-align: center;
            width: auto;
            min-width: 80px;
        }
    }
`

export const TotalClicks = styled.div`
    position: absolute;
    top: 13px;
    right: 43px;
    z-index: 5;
    display: flex;
    background: #fff;
    padding: 5px 5px 5px 10px;
    border-radius: 7px;
    justify-content:flex-start;
    width: fit-content;

    label{
        font-size: 0.5rem;
        text-transform: uppercase;
        max-width: 58px;
        margin-left: 6px;
        padding-top: 4px;
        color: rgba(0,0,0,0.6);
        font-weight: 600;
    }
    .total{
        font-size: 1.5rem;
        margin-right: 5px;
        color: rgba(0,0,0,0.7)
    }
    span{
        color: var(--orangeBase);
        top: 3px;
    }
`;

export const Media = styled.div`
    text-transform: uppercase;
    font-size: 0.7rem;
    top: -8px;
    margin-bottom: 0px;
    line-height: 0.9rem;
`;

export const SpaceChart = styled.div<{showdatafor?: number}>`
    height: 180px;
    display: block;
    margin: 0 -17px -15px -17px;    
    margin-right: ${props => props.showdatafor === 30 ? '-11px' : '-17px'};
`;
export const SpacePieChart = styled.div`
    height: 290px;
    width: 290px;
    margin: 0 auto;    
`;
export const LimitSpacePieChart = styled.div`
    height: 180px;
    width: 100%;  
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Range = styled.div<{showdatafor?: number, bgcolor?:number}>`
    background: ${props => props.bgcolor === 2 ? 'rgba(0, 0, 0, .05)' : 'rgba(222, 218, 226, .13)'};
    
    padding: 3px 3px 2px 3px;
    border-radius: 5px;
    display: flex;

    input{ display: none}
    label{
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        padding: 5px 10px;
        border-radius: 3px;
        justify-content:flex-start;
        width: fit-content;
        border-bottom: solid 1px rgba(229, 232, 236, 1);
        border-right: solid 1px rgba(229, 232, 236, 1);  
        font-size : 0.8rem;
        margin: 2px;
        cursor: pointer;
    }
    label#for30{
        background: ${props => props.showdatafor === 7 && 'rgba(0,0,0,0.1);'};
        opacity: ${props => props.showdatafor === 7 && '0.5'};
    }
    label#for7{
        background: ${props => props.showdatafor === 30 && 'rgba(0,0,0,0.1);'};
        opacity: ${props => props.showdatafor === 30 && '0.5'};
    }
`;