import React from 'react';
import TopUser from '../../components/TopUser';
import { ScrollArea } from '../../styles/global';

const urlsite = process.env.REACT_APP_URL_PROFILE;

const Privacy: React.FC = () => {

    return(
    <>
        <div className='simpleflex minHsimpleflex'>            
            <h1 className='marg0'>
                Políticas de Privacidade
                <span>Atualizado em: 23 de Julho, 2022</span>
            </h1>
            <TopUser />
        </div>

        <div className="contentpage"> 
            <ScrollArea size={7}>
            {urlsite !== undefined &&
                <><iframe title='frameprivacy' className='styleiframe' src={`${urlsite}/inc/termos.html`}></iframe></>
            }
            <div className="gradfooter"></div>
            </ScrollArea>
        </div>        
    </>
    );
}

export default Privacy;