import { useState, useCallback, useContext, useEffect } from "react";
import { api } from "../hooks/useApi";
import { ListPagesContext } from "./ListPagesContext";
import { PageContext } from "./PageContext";
import { AuthContext } from "./AuthContext";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Page } from "../types/Page";

export const ListPagesProvider = ({children} : { children: JSX.Element}) => {

    let navigate = useNavigate();
    const [listPages, setListPages] = useState<any>([]);
    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
        
    const getAllPages = useCallback(async () => {  
        if(auth.user && auth.user.id){
            try{            
                let idUser = auth.user?.id;
                const response = await api.post('/page_getpages.php', { idUser });             
                setListPages(response.data.data);
                page.choosePage(response.data.data[0])        
            } catch (error : any) { 
                if(error.response.status === 404){
                    toast('Nenhuma página encontrada'); 
                    setListPages([]);
                    navigate("../newpage", { replace: true });
                } else if(error.response.status === 500){
                    toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                    localStorage.setItem('@navtome:token', '');
                    window.location.href = '/';
                } else {
                    toast.error('Erro ao buscar páginas', { theme: 'colored' });               
                };
            }
        }
    }, [auth.user]); // eslint-disable-line


    useEffect(() => {     
        const newpages = listPages.map((el:any) => {
            if (el.id === page.page?.id) {
                return {...page.page};
            }      
            return el;
        });
        setListPages(newpages); 
    }, [page.page]);  // eslint-disable-line

    const addPage = (npage: Page) => {
        setListPages([...listPages, npage]);
        navigate("../links");
    }

    const removePage = (id: number) => {
        // removendo do array
        const newPages = listPages.filter((e:Page) => {
            return Number(e.id) !== id;
        });     

        if(newPages.length > 0){
            page.choosePage(newPages[newPages.length - 1])
        } else {
            page.choosePage(null);
            navigate("../newpage");
        }
        setListPages(newPages); 
    }

    return(
        <ListPagesContext.Provider value={{listPages, getAllPages, addPage, removePage }}>
            {children}
        </ListPagesContext.Provider>
    )
};