import styled from 'styled-components';

export const SideMenu  = styled.aside<{openclosemenu: boolean, sizeWindow: number}>`
    width: ${props => props.openclosemenu ? '220px' : '121px'};
    height: 100%;
    margin-right: 1px;
    font-family: 'Montserrat', sans-serif;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9;
    transition:  0.5s ease-in-out;

    button{
        font-family: 'Montserrat', sans-serif;
    }

    nav{
        margin-top:  20px;

        > span{ 
            font-weight: 900; 
            font-size: 0.8rem; 
            margin-bottom: 10px; 
            display: block;
            text-align: center;
            white-space: nowrap;
            width: ${props => props.openclosemenu ? '0' : '100%'};
            transition: width .8s ease;
        }

        ul{ 
            list-style: none;
            background: #fff;
            border-radius: 8px;
            padding: 5px;
            width: 100%;
            box-shadow: 0px 10px 10px rgba(0,0,0,0.05);
            z-index: 2;
        }
        ul li{ display: block;}
        ul li.active{ 
            border-left: solid 5px var(--orangeBase);
            border-radius: 5px;
            left: -10px;
            padding-left: 5px;
            margin-right: -10px;
            box-shadow: inset -5px 5px 7px rgba(0,0,0,0.03);
        }
        ul li.active a, ul li.active button{ 
            color: var(--orangeBase);
        }
        ul li.active a span, ul li.active button span{ color: var(--orangeBase); }

        ul li a, ul li button{ 
            padding: 8px 0 8px 10px ;
            display: flex; 
            width: 100%; 
            height: 100%; 
            align-items: center;
            text-decoration: none;
            font-weight: 700;
            font-size: .8rem;
            color: var(--purpleBase);
            background: none;
        }
        ul li a label, ul li button label{
            visibility: ${props => props.openclosemenu ? 'visible' : 'hidden'};
            background: ${props => props.openclosemenu ? 'transparent' : 'var(--orangeBase)'};
            color: ${props => props.openclosemenu ? 'inherit' : '#fff'};
            border-radius: 25px;
            padding: 5px 10px;
            box-shadow: ${props => props.openclosemenu ? 'none' : '0px 8px 8px rgba(0,0,0,0.1)'};
            cursor: ${props => props.openclosemenu ? 'pointer' : 'default'};
            margin-left: ${props => props.openclosemenu ? '0' : '8px'};
            opacity: ${props => props.openclosemenu ? '1' : '0'};
            transition:  0.5s ease-in-out;
            white-space: nowrap;
        }        
        ul li a span, ul li button span{
            color: #8d96a7;
            margin-right:  ${props => props.openclosemenu ? '8px' : '0px'};
            margin-left:  ${props => props.openclosemenu ? '0px' : '8px'};
        }
        ul li a:hover, ul li button:hover{
            color: var(--orangeBase)
        }
        ul li a:hover span, ul li button:hover span{
            color: var(--orangeBase)
        }
        
        ul li a:hover label, ul li button:hover label{
            visibility: ${props => props.openclosemenu ? 'inherit' : 'visible'};
            color: ${props => props.openclosemenu ? 'var(--orangeBase)' : '#fff'};
            opacity: 1;
            
        }
        .opclmenu{
            background: #fff;
            position: absolute;
            width: 24px;
            height: 18px;
            border-radius: 0 0 6px 6px;
            border: solid 1px rgba(0,0,0,0.1);
            border-top: none;
            right: 10px;
            z-index: 0;
            margin-top: -25px;
            visibility: ${props => props.sizeWindow < 1160 ? 'hidden' : 'visible'};

            ::before{
                content: '${props => props.openclosemenu ? '❮❮' : '❯❯'}';
                font-size: 10px;
                letter-spacing: -1px;                
                top: -1px;
                right: 1px;
                position: relative;
                color: #999
            }
        }
    }

    :hover nav .opclmenu{
        margin-top: -1px;
    }

    .termlinks{
        background: rgba(255,255,255,0.5);
        text-transform: uppercase;        
        padding: 4px 2px;
        font-size: 11px;
        border-radius: 7px;
        color: #BCBCBC;
        text-align: center;
        margin-top: 20px;

        a{
           font-size: 0.6rem; 
           text-decoration: none;
           display: inline-block;
           padding: 0 7px;
           color: #000
        }
        a:hover{
            color: var(--orangeBase);
        }
    }
    .bol{
        display: ${props => !props.openclosemenu ? 'none' : 'inline-block'};
    }

    @media only screen and (max-width:550px){
        display: none;
    }

`;

export const Logomarca  = styled.div<{openclosemenu: boolean}>`
    background: var(--purpleBase);
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 8px;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    transition: ease-in-out 0.5s;

    span{
       height: 42%;
       display: inline-block;
       overflow: hidden;       
       width: ${props => props.openclosemenu ? '132px' : '47px'};
       transition:  0.5s ease-in-out;
    }
    img{        
        height: 99%;
        width: auto;
        transition: ease-in-out 0.5s;
    }

    :hover{
        background: var(--orangeBase);
        img{
            filter: brightness(20%)
        }
    }
    
`;

export const User = styled.div<{openclosemenu: boolean}>`
    display: flex;
    margin-top: 20px; 
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    transition: 0.5s ease-in-out; 
    margin-left: ${props => props.openclosemenu ? '0' : '9px'};;

    .thumb{
        border-radius: 50%;
        width: 55px;
        height: 55px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
        float: ${props => props.openclosemenu ? 'left' : 'none'};
        transition:  0.5s ease-in-out; 
    }
    .initial{
        background: var(--orangeBase);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 23px;
        border: solid rgba(255,255,255,0.2) 3px;
        text-shadow : 2px 2px 5px rgba(0, 0, 0, .05), 1px 1px 0 rgba(0, 0, 0, .15);
    }
    .boxthumb{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute; 
              

        img{
            min-width: 55px;
            min-height: 55px;
            object-fit: cover;    
               
        }
    } 

    .clear{ clear: both; }
    .nomes{
        width: 111px;
        right: 0;
        //display: ${props => props.openclosemenu ? 'block' : 'none'};
        visibility: ${props => props.openclosemenu ? 'visible' : 'hidden'};
        position: absolute;
        opacity: ${props => props.openclosemenu ? '1' : '0'};
        transition:  0.5s ease-in-out;

        .tooltip{
            display: inline-block;
            width: 100%;
        }

        button{
            background: none;
            border: dotted 1px rgb(254,68,52,0.2);
            border-radius: 5px;
            padding: 1px 4px 2px 5px;
            width: 100%;
            animation: animatebord 0.5s infinite;
        }
        @keyframes animatebord {
            0%   {border: dotted 1px rgb(254,68,52,0.3);}
            50%  {border: dashed 1px rgb(254,68,52,0.3);}
            100%  {border: dotted 1px rgb(254,68,52,0.3);}            
        }
    }
    .nomes span{
        display: block;
        word-break: break-all;
    }
    .nomes span:first-child{
        font-weight: 700;
        font-size: 0.85rem;
        display: flex;
        margin-top: 4px;
    }
    .nomes button span:first-child{
        font-weight: 700;
        font-size: 0.7rem;
        display: flex; 
        line-height: 0.6rem;
        color: var(--orangeBase);
    }
    .nomes button span:nth-child(2){
        display: block; 
        color: var(--orangeBase);
        font-size: 0.8rem;  
        word-break: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
`

export const UpgradePlan = styled.button<{location?: string; openclosemenu: boolean}>`
    background: #DEE1E7;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    text-align: left;
    box-shadow: 0px 4px 10px rgba(0,0,0,.06), 0px 1px 0 rgba(0,0,0,0.15);    

    :hover{
        box-shadow: inset 0px 4px 10px rgba(0,0,0,.05), 0px 1px 0 #fff;

        .rocket{            
            animation: ${props => props.location !== '/upgrade' ? 'launch 1.8s ease-in' : ''};
        }
        @keyframes launch {
            0%   {
            }
            15%   {
                right: -15px;
                top: -20px;
            }
            50%  {
                right: -300px;
                top: -669px;
            }
            51%  {
                right:-15px;
                top: -20px;
                opacity: 0;
            }   
            100%  {
                opacity: 1;
            }          
        }
    }
    .rocket{
        position: absolute;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        right:  -15px;
        top: -20px;
        transform: rotate(20deg);
        display: flex;
        align-items: center;
        justify-content: center;
        img{ width: 115%; height: auto;}
        pointer-events: none;
    }

    .tit{
        width: 75%;
        font-weight: 700;
        color: var(--orangeBase);
        display: block;
        line-height: 1.1rem;
        opacity: ${props => props.openclosemenu ? '1' : '0'};
        font-size: ${props => props.openclosemenu ? '1.2rem' : '0'};
    }
    .desc{
        line-height: 0.8rem;
        display: block;
        margin: 8px 0 -2px 0;
        opacity: ${props => props.openclosemenu ? '1' : '0'};
        font-size: ${props => props.openclosemenu ? '0.85rem' : '0'};
        color: #000
    }
    .call{
        color: var(--purpleBase);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 0.8rem;
        line-height: 0.5rem;
        bottom: -5px;
        left: ${props => props.openclosemenu ? 'auto' : '-5px'};
        bottom: ${props => props.openclosemenu ? '-5px' : '-8px'};
    }
`

export const MenuMobile = styled.div<{menumobile?: boolean}>`
    display: none;
    z-index: 99999999999999999;

    width: ${(props) => props.menumobile ? '100%' : 'auto'};
    height: ${(props) => props.menumobile ? '100%' : 'auto'};

    *{
        transition:  0.5s ease-in-out;
    }

    @media only screen and (max-width:550px){
        display: block;
        position: absolute;        
        right: 0;

        width: ${(props) => props.menumobile ? '100%' : 'auto'};
        height: ${(props) => props.menumobile ? '100%' : 'auto'};

        .logo{
            width: 120px;
            position: absolute;
            top: 37px;
            right: 80px;
        }

        .hamburguer{
            position: absolute;
            right: 18px;
            top: 44px;
            cursor: pointer;

            span{ 
                width: 30px; 
                height: 5px; 
                margin: 3px 0; 
                background: var(--orangeBase); 
                display:block;
                border-radius: 5px;
            }
            :hover {
                span{
                    background: var(--purpleLive);
                }
            }
        }
        
        .bgmenu{
            display: ${(props) => props.menumobile ? 'block' : 'none'};
            background: rgba(255,255,255,.7);
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .menu{
            background: var(--purpleBase);
            position: absolute;
            width: 65%;
            height: 100%;
            right: 0;

            .cross{
                color: var(--orangeBase);
                font-size: 80px;
                font-weight: 400;
                line-height: 46px;
                display: inline;
                transform: rotate(45deg);
                margin: 10px;
                float: right;
                cursor: pointer;

                :hover{
                    color: #fff;
                }
            }

            ul{
                list-style: none;
                padding: 0 20px;
            }
            ul li a, ul li .link{
                font-size: 16px;
                text-align: center;
                padding: 11px 20px;
                display: block;
                text-decoration: none;
                text-align: center;
                color: #fff;
                border-top: solid rgba(255,255,255,.04) 1px;
                border-bottom: solid rgba(0,0,0,.1) 1px;
                cursor: pointer;
                background: transparent;
                width: 100%;

            }
            ul li a div{cursor: pointer;}
            ul li a:hover, ul li .link:hover{
                background: rgba(255,255,255,.04);
            }  
            ul li:first-child a{ border-top: none }
            ul li:last-child a, ul li:last-child .link{ border-bottom: none }

            ul li.active a{ color: var(--orangeBase) }
        }
    }
`