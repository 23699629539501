import React, {useRef, useState, useContext, useEffect} from 'react';

import Button from '../../components/Elements/Button';
import ListLinks from '../../components/ListLinks';

import { FormSeparador } from '../../styles/global';
import { CompactPicker } from 'react-color';

import { AuthContext } from "../../contexts/AuthContext";
import { PageContext } from "../../contexts/PageContext";
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { typeItem } from '../../types/Item';
import { ActivityIndicator, ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { CheckTitle, FormatUrl, UrlPatternValidation, GettUrlFromIframe, StripTags, IsEmpty } from '../../utils/functions';
import ConfirmDialog from '../../components/Elements/ConfirmDialog';

import { 
  BtnBold, BtnItalic, BtnUnderline, BtnBulletList, 
  BtnUndo, BtnRedo, Editor, Toolbar, EditorProvider 
} from 'react-simple-wysiwyg';

const Links: React.FC = () => {
  const auth = useContext(AuthContext);
  const page = useContext(PageContext);
  let navigate = useNavigate();

  const subList = useRef<any>(null);
  const [ icon, setIcon ] = useState('add_circle');

  const colorTheme = page.page?.colorOne || '#000';
  const [ color, setColor ] = useState(colorTheme);
  const [ title, setTitle ] = useState('');
  const [ url, setUrl ] = useState('');

  const [ description, setDescription ] = useState('');
  const [ partOfItem, setPartOfItem] = useState(true); 
  /* partOfItem é uma coluna no banco usado em 2 situações:            */
  /* If true em links a url será suprimida na página do usário          */
  /* If true em titulo + descricao o item funciona como um Collapsible  */                           

  const [ load, setLoad ] = useState(true);
  //const [ showlist, setShowlist ] = useState(true);
  //const [ showForm, setShowForm ] = useState('');
  const [ showWhat, setShowWhat ] = useState('list');
  const [ items, setItems ] = useState<typeItem[]>([]);

  const [ loadsave, setLoadSave ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ itemForDel, setItemForDel ] = useState<any>([]);

  const [ autoadd, setAutoadd ] = useState(false);

  const showSubs = () => {
    const e = subList.current.style;
    if(e.display === 'none' || e.display === ''){
      e.display = 'inline-block';
      setIcon('remove_circle');
    } else {
      e.display = 'none';
      setIcon('add_circle');
    }
  }

  const handleShowForm = (e:string) => {
    //setShowlist(false);
    //setShowForm(e);   
    setShowWhat(e);
    setTitle('');
    setUrl('');
    setDescription('');
    setPartOfItem(e === 'tituloDescricao' ? true : false);
    setColor(colorTheme);
  }

  function handleChange(color: any) {handleUseColorTheme(color.hex);}
  function handleUseColorTheme(color: string){setColor(color);}

  useEffect(() => {
    let mounted = true;
    if(mounted){               
      if(page.page && page.page.id){          
        getItems();  
        setShowWhat('list');  
        //setShowForm('');
        setTitle('');
        setUrl('');                        
      } 
    }
    return function cleanup() {
        mounted = false
    }
  }, [page]); //eslint-disable-line


  async function getItems(){
    try {            
        const data = await api.post('/item_getitems.php', {
            'idUser': page.page?.idUser, 
            'idpage': page.page?.id
        });
        //console.log(data.data.data)
        setItems(data.data.data);
        setLoad(false);
    } catch (error :any) {
        if(error.response.status === 500 || error.response.status === 0){
          toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
          localStorage.setItem('@navtome:token', '');
          window.location.href = '/';
        };        
    }
    setLoad(false);
  }

  async function handleAddLink(typeitem: number){

    if(typeitem === 1){ // enviando link
      if(IsEmpty(title.trim()) || IsEmpty(url.trim())){
        toast.error('Título e Url não podem ser nulos', { theme: 'colored' });
        return;
      }
      if(!CheckTitle(title.trim())){
        toast.error('No campo Títuo, digite entre 3 e 50 caracteres', { theme: 'colored' });
        return;
      }
      if (!UrlPatternValidation(url.trim())) {
        toast.error('Url não é válida', { theme: 'colored' });
        return;
      } 
    }
    if(typeitem === 2){ // enviando embed
      if(IsEmpty(url.trim())){
        toast.error('Url não pode ser nulo', { theme: 'colored' });
        return;
      }
      if (!UrlPatternValidation(url.trim())) {
        toast.error('Url não é válida', { theme: 'colored' });
        return;
      } 
    }
    if(typeitem === 4){ // enviando titulo
      if(IsEmpty(title.trim())){
        toast.error('Título não pode ser nulo', { theme: 'colored' });
        return;
      }
      if(!CheckTitle(title.trim())){
        toast.error('No campo Títuo, digite entre 3 e 50 caracteres', { theme: 'colored' });
        return;
      }
    }
    if(typeitem === 5){ // enviando titulo e descrição
      if(partOfItem && !CheckTitle(title.trim())){
        toast.error('No campo Títuo, digite entre 3 e 50 caracteres', { theme: 'colored' });
        return;
      }
      if(IsEmpty(description.trim())){
        toast.error('Descrição não pode ficar em branco', { theme: 'colored' });
        return;        
      }       
    }

    setLoadSave(true);
    try {
      const data = {
        idUser : auth.user?.id, 
        idpage : page.page?.id,
        typeitem : typeitem,
        title : StripTags(title.trim()),
        url : typeitem !== 2 ? FormatUrl(url) : GettUrlFromIframe(url), // GettUrlFromIframe é pro caso da pessoa digitar o embed com iframe junto, nessa a função retorna somente a url.
        color : typeitem === 3 ? color : '',
        hide : 0,
        ord : items.length+1,
        image: "",
        partOfItem: partOfItem ? 1 : 0,
        description: description
      }

      //console.log(data);
      
      const resp = await api.post('/item_create.php', data);
 
      //atualizando list de itens
      const newitem = {
        id : resp.data.iditem, 
        typeitem : typeitem,
        title : StripTags(title.trim()),
        url : typeitem !== 2 ? FormatUrl(url) : GettUrlFromIframe(url),
        color : typeitem === 3 ? color : '',
        hide : 0,
        ord : items.length+1,
        image: "",
        partOfItem: partOfItem ? 1 : 0,
        description: description,
        idpage : page.page?.id,
        newItem : true
      }      
      setItems(state => [ newitem, ...state ]);
      
      // resetando form
      setTitle('');
      setUrl('');
      setColor(colorTheme);
      setLoadSave(false);
      toast.success('Cadastro realizado com sucesso');
      setShowWhat('list');
      
      // para atualizar o preview da pagina
      const newpage = {...page.page};          
      page.choosePage(newpage);

    } catch (error:any) {
      if(error.response.status === 500 || error.response.status === 0){
        toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
        localStorage.setItem('@navtome:token', '');
        window.location.href = '/';
      };
    }
    setLoadSave(false);
  }

  function updateItem(e:any, type: string){
    if(type === 'update'){
      const newlist = items.map(el => {
        if (el.id === e.id) {
          return {...el, ...e};
        }      
        return el;
      });
      //const newlist = items.map(el => (el.id === e.id ? {e, ...el} : el));
      setItems(newlist);
    }
    if(type === 'delete'){
      setItemForDel(e);
      setShowConfirm(true);
    }
    if(type === 'confirmdelete'){      
      const newlist = items.filter(el => { return el.id !== e.id; })
      setItems(newlist);
    }
    // para atualizar o preview da pagina
    const newpage = {...page.page};          
    page.choosePage(newpage);
  }

  async function handleDelItem(e:boolean){
    if(e){
      try {
        const delitem = {
          idUser: page.page?.idUser, 
          idpage: page.page?.id,
          id: itemForDel.id
        }
        const resp = await api.delete('/item_delete.php', { data: delitem });
        setShowConfirm(false);
        updateItem(itemForDel, 'confirmdelete');
        toast.success(resp.data.message);
      } catch (error:any) {
        if(error.response.status === 401 || error.response.status === 0){
          toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
          localStorage.setItem('@navtome:token', '');
          window.location.href = '/';
        };
      }
    }
  }

  /** REFERENTE AOS LINKS DE SUGESTÕES **/
  async function addSugestion(title: string, url: string){
    setUrl(url);
    setTitle(title);
    setPartOfItem(false)
    setAutoadd(true);
  }
  useEffect(() => {
    if(autoadd === true){
      handleAddLink(1);
      setAutoadd(false);
    }
  },[autoadd]) // eslint-disable-line


  /** REFERENTE AO EDITOR DE TEXTO **/
  const removeHtmlFromPast = function (event : any) {
    event.preventDefault();
    let paste = event.clipboardData.getData('text');
    changeDescricao(paste.trim());
  };
  const adlistener = (showform: string) => {
    if(showform === 'tituloDescricao'){
      document.getElementById('textarea')?.addEventListener('paste', removeHtmlFromPast, false); 
    } else {
      document.getElementById('textarea')?.removeEventListener('paste', removeHtmlFromPast, false);
    }
  };
  useEffect(() => {
    let mounted = true;    
    if(mounted){
      adlistener(showWhat);
    }
    return function cleanup() { 
      mounted = false;
    }
  }, [showWhat]); // eslint-disable-line  
  function changeDescricao(e: string){
    let semformatacao = e.replace(/<[^>]*>?/gm, ''); 
    if(semformatacao.length <= 1200){
      setDescription(e);
    } else {
      setDescription(limitDescricao(e, 1200));
    }
  }
  function limitDescricao (string = '', limit = 0) {  
    return string.substring(0, limit)
  }


 
  return (
    <> 
      { 
        showConfirm && 
        <ConfirmDialog 
          closeConfirm={() => setShowConfirm(false)} 
          title="Confirme" 
          icon="warning_amber"
          text="Tem certeza que deseja apagar? Você não poderá reverter esta ação."
          labelbtn={'Sim quero deletar'}
          returnDialog={(e: boolean) => handleDelItem(e)}
        /> 
      }
    
        <div className="contentpage">
          <h1>
            Adicione seus links
            <span>VOCÊ ESTA EDITANDO A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
          </h1>
          
          <div className="gridbuttons">
          <Button onClick={() => handleShowForm('link')} color2={true} >Adicionar Link</Button>
          <Button onClick={() => handleShowForm('embed')}>Incorporar Vídeo ou Música</Button>
          <div id="divSubItem" onClick={showSubs}>
            <button className='plus'><span className="material-icons-round">{icon}</span></button>
            <div ref={subList} className='subitems'>
              <ul>
              <li><button onClick={() => handleShowForm('tituloDescricao')}>Adicionar Título + Descrição</button></li>                
                <li><button onClick={() => handleShowForm('titulo')}>Adicionar Título</button></li>                
                <li><button onClick={() => handleShowForm('separador')}>Adicionar Sepadador</button></li>
              </ul>
            </div>
          </div>
          </div>

          {showWhat === 'list' ? 
            !load ?
              <ListLinks 
                data={items} 
                //loadlist={load} 
                reorderList={(e) => setItems(e)} 
                updateItem={(e: any, type: string) => updateItem(e, type)} 
                addSugestion={(a: string, b: string) => addSugestion(a, b)} />
            : 
              <ActivityIndicator />
          :
            <div className="spaceform">
              {showWhat === 'link' &&
              <div className="boxform">
                  <h4>Adicionar link</h4>                
                  <fieldset>
                      <legend>Titulo:</legend>
                      <input placeholder='Adicione um Título' onChange={e => setTitle(e.target.value)} type="text" value={title} maxLength={50} />
                      <legend>Url do link:</legend>
                      <input placeholder='https://seulink.com' onChange={e => setUrl(e.target.value)} type="text" value={url} maxLength={200} />
                  </fieldset>

                  <fieldset style={{marginBottom: '10px', display: 'flex', lineHeight: '0.9rem', alignItems: 'center'}}>
                    <input type="checkbox" id="partofitem" name="partofitem" onClick={() => setPartOfItem(!partOfItem)} defaultChecked={partOfItem} />
                    <label htmlFor="partofitem">
                      <small>Mostrar somente o Título</small><br />
                      <small><small style={{lineHeight: '9px'}}>Não irá exibir a url junto do título</small></small>
                    </label>
                  </fieldset>
                  <div className='spacebutton'>
                      <div className='spacebutton'>
                          <button onClick={() => setShowWhat('list')} className='del' title='Cancelar cadastro'>
                              <span className="material-icons-round">highlight_off</span>cancelar
                          </button>
                      </div>                      
                      <button onClick={() => handleAddLink(1)} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                      </button>
                  </div>              
              </div>
              } {showWhat === 'embed' &&
              <div className="boxform">
                  <h4>Incorporar vídeo ou música</h4>                
                  <fieldset>
                      <legend>Url do vídeo:</legend>
                      <input onChange={e => setUrl(e.target.value)} type="text" value={url} maxLength={2000} />
                  </fieldset>

                  <div className='miniaviso w100'><br />
                    A incorporação de vídeo ou de músicas é compatível com os seguintes serviços: <strong>Spotify</strong>, <strong>Sound Cloud</strong>, <strong>Youtube</strong>, <strong>Vimeo</strong>, <strong>Rumble</strong>, <strong>Odysee</strong> e <strong>Daily Motion</strong>.<br /><br />
                  
                    Atenção:<br />
                    Para o <strong>Rumble</strong> utilize somente a url do iframe, (embed iframe url).<br/>
                    Para o <strong>Sound Cloud</strong> utilize o código iframe, (em compartilhar, vá na aba incorporado).<br/>
                    Para o <strong>Spotify</strong> utilize o código iframe, (em compartilhar, vá em incorporar).
                    <br /><br />
                  </div>

                  <div className='spacebutton'>
                      <div className='spacebutton'>
                          <button onClick={() => setShowWhat('list')} className='del' title='Cancelar cadastro'>
                              <span className="material-icons-round">highlight_off</span>cancelar
                          </button>
                      </div>                      
                      <button onClick={() => handleAddLink(2)} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                      </button>
                  </div>              
              </div>
              } {showWhat === 'separador' &&
              <div className="boxform">
                  <h4>Adicionar linha de separação</h4> 
                  <FormSeparador colorTheme={colorTheme} coloritem={color}>
                  <div className='flex2'>
                    <fieldset>
                        <legend>Linha:</legend>
                        <hr />
                        <div className='usecolortheme'> 
                        <div className="cor cor1" style={{background: page.page?.colorOne ? page.page?.colorOne : '#000'}} onClick={() => handleUseColorTheme(page.page?.colorOne ? page.page?.colorOne : '#000')}></div>     
                        <div className="cor cor2" style={{background: page.page?.colorTwo ? page.page?.colorTwo : '#000'}} onClick={() => handleUseColorTheme(page.page?.colorTwo ? page.page?.colorTwo : '#000')}></div>    
                        <div className="cor cor3" style={{background: page.page?.colorThree ? page.page?.colorThree : '#000'}} onClick={() => handleUseColorTheme(page.page?.colorThree ? page.page?.colorThree : '#000')}></div>                      
                        <button>Cores do tema</button>
                        </div>
                    </fieldset>
                    <CompactPicker onChange={(hex) => handleChange(hex) } />
                  </div>
                  <div className='spacebutton'>

                      <div className='spacebutton'>
                        <button onClick={() => setShowWhat('list')} className='del' title='Cancelar cadastro'>
                          <span className="material-icons-round">highlight_off</span>cancelar
                        </button>
                      </div>
                      
                      <button onClick={() => handleAddLink(3)} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                      </button>
                  </div>  
                  </FormSeparador>        
              </div>
              } {showWhat === 'titulo' &&
              <div className="boxform">
                  <h4>Adicionar Título</h4>                
                  <fieldset>
                    <legend>Titulo:</legend>
                    <input onChange={e => setTitle(e.target.value)} type="text" value={title} maxLength={50} />
                  </fieldset>
                  <div className='spacebutton'>
                      <div className='spacebutton'>
                          <button onClick={() => setShowWhat('list')} className='del' title='Cancelar cadastro'>
                              <span className="material-icons-round">highlight_off</span>cancelar
                          </button>
                      </div>                      
                      <button onClick={() => handleAddLink(4)} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                      </button>
                  </div>              
              </div>
              } {showWhat === 'tituloDescricao' &&
              <div className="boxform">
                  <h4>Adicionar Título e Descrição</h4>                
                  <fieldset>
                    <legend>Titulo:</legend>
                    <input onChange={e => setTitle(e.target.value)} type="text" value={title} maxLength={49} />
                  </fieldset>
                  <fieldset>
                    <legend>Descrição:</legend>
                    <br />
                    <EditorProvider>
                    <Editor id="textarea" value={description} onChange={(e:any) => changeDescricao(e.target.value)}>
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnUndo />
                        <BtnRedo />
                      </Toolbar>
                    </Editor>
                    </EditorProvider>                    
                    <div style={{textAlign: 'right', fontSize: '10px', margin: '0 5px 0 0'}}>
                      {description.replace(/<[^>]*>?/gm, '').length}/1200
                    </div>
                  </fieldset>
                  <fieldset style={{marginBottom: '10px', display: 'flex', lineHeight: '0.9rem', alignItems: 'center'}}>
                    <input type="checkbox" id="partofitem" name="partofitem" onClick={() => setPartOfItem(!partOfItem)} defaultChecked={partOfItem} />
                    <label htmlFor="partofitem">
                      <small>Utilizar modo Collapsible</small><br />
                      <small><small style={{lineHeight: '9px'}}>No modo Collapsible, o usuário precisa clicar no título para abrir a descrição. (Título obrigatório)</small></small>
                    </label>
                  </fieldset>
                  
                  <div className='spacebutton'>
                      <div className='spacebutton'>
                          <button onClick={() => setShowWhat('list')} className='del' title='Cancelar cadastro'>
                              <span className="material-icons-round">highlight_off</span>cancelar
                          </button>
                      </div>                      
                      <button onClick={() => handleAddLink(5)} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                      </button>
                  </div>              
              </div>
              }

              <button onClick={() => setShowWhat('list')} style={{backgroundColor: 'transparent', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                <span className="material-icons-round">chevron_left</span>
                VOLTAR PARA A LISTA
                </button>
            </div>
          }

        </div>

    </>
  );
}

export default Links;