import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import getValidationErros from '../../utils/getValidationErros';
import { Page } from '../../types/Page';
import { useLocation } from 'react-router-dom';
import { BlockResourcesByPlan } from '../../utils/functions';


const FormAnalitcs: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);
    const [ dataPage, setDatapage ] = useState<any>(page.page);
    const location = useLocation();
    const formAnalitcs = useRef<FormHandles>(null);

    const unlocked = BlockResourcesByPlan('formAnalitcs', Number(auth.user?.planSignature));

    useEffect(() =>{
      const iniData= {
        analitcsCode: page.page?.analitcsCode ? page.page?.analitcsCode : ''
      }
      setDatapage(iniData);
    }, [page, location.pathname])

    const handleChangeSeo = useCallback(async (data: Page) => { 
      if(unlocked) {
        try {
            setLoadSave(true);
            formAnalitcs.current?.setErrors({});
            const schema = Yup.object().shape({
              analitcsCode: Yup.string().max(20, 'Máximo de 20 caracteres')
            });
            await schema.validate(data, { abortEarly: false, });

            const datasend = {
              ...page.page,
              'analitcsCode': data.analitcsCode,
              'idUser': auth.user?.id
            }
           
            const resp = await api.post('/page_update.php', datasend);
            if(resp.status === 200){
              const newpage = { ...page.page, 'analitcsCode': data.analitcsCode};          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
        } catch (error: any) { 
          if(error instanceof Yup.ValidationError){
              const errors = getValidationErros(error);
              formAnalitcs.current?.setErrors(errors);
              setLoadSave(false);
              return;
          }
          if(error.response.status === 500){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
          } 
          toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);

      } else {
        toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
      }
    }, [page, auth.user?.id, unlocked]);

    return(
        <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Disponível a partir do plano pro">            
        <h4>Google Analytics <span className="desctitle">Integre o seu próprio código do analytics.</span></h4>    
        <Form initialData={dataPage} ref={formAnalitcs}  onSubmit={handleChangeSeo}>
          <fieldset>                   
            <Input placeholder='Código Google Analytics:' type="text" name="analitcsCode" maxLength={150} /> 
          </fieldset> 
          <div className='spacebutton'>
            <div className='miniaviso'>    
              Você vai encontrar este código no se painel do Analytics, dentro do painel vá em administração, na 
              coluna do meio, vá em configurações da propriedade, copie o <strong>código de acompanhamento</strong> e cole aqui.
            </div>                     
            <button type='submit' disabled={loadsave} className='save'>
              {loadsave ? 
                <ActivityIndicatorBtn />
              :
                <>Salvar</>
              }                        
            </button>
          </div>     
        </Form>
        </div>
    );
}

export default FormAnalitcs;