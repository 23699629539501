import React from 'react';
import { GridThemes } from './styles';
import { IThemes } from '../../types/Themes';
import { listThemes } from '../../utils/ListThemes';
import ThemeItem from './themeItem';


interface TypeScreen {
  prevTheme: (e:any) => void;
}

const FormThemes: React.FC<TypeScreen> = ({prevTheme}) => {

    return (
      <div>        
        <div className='spacebutton'>
            <div className='miniaviso'>    
                Clique e escolha o tema que você deseja aplicar em sua página.
            </div>
            <br></br>              
        </div>
        <GridThemes>
          <ul>
            {listThemes.map((theme:IThemes) => 
              <ThemeItem key={theme.id} prevTheme={(e:any) => prevTheme(e)} theme={theme} />
            )}
          </ul>
        </GridThemes>
        <br />        
      </div>
    );
}

export default FormThemes;
