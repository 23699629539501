import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/navigatetome2.png';
import { Container, FormLogin, ImageSide2 } from '../SignIn/styles';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import getValidationErros from '../../utils/getValidationErros';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';


interface ResetFormData{
    email: string;
    password: string;
    repassword: string;
}


const ResetPass: React.FC = () => {

    const [load, setload] = useState(false);
    const navigate = useNavigate();
    const formRef = useRef<FormHandles>(null);
    const divscroll = useRef<any>(null);
    const loginData = { email: '',  password: '', repassword: ''};

    const query = new URLSearchParams(window.location.search)
    const token = query.get('tkn');
    
    useEffect(() => {
      if(token === undefined || token === null || token === ''){
          navigate('/');
      }
    });


    const handleResetPass = useCallback(async (data: ResetFormData) => {
      if(data.password === data.repassword){
        try {
          setload(true);
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            email: Yup.string().max(35, "Máximo de 35 caracteres").required('Email obrigatório').email('Insira um email válido'),
            password: Yup.string().required('Nova senha obrigatória'),
            repassword: Yup.string().when('password', {
                is: (value : string ) => value.length > 0,
                then: Yup.string().required('Campo Obrigatório').max(14, 'Máximo 14 caracteres').min(6, 'Mínimo 6 caracteres'),
                otherwise: Yup.string(),
            }).oneOf([Yup.ref('password'), null], 'As novas senhas são diferentes'),
          });
          await schema.validate(data, { abortEarly: false, });

          const send = {
            email: data.email,
            password: data.password,
            chave: token,
          }

          const resp = await api.post('/user_changenewpass.php', send);

          if(resp.status === 200){
            toast.success(resp.data.message, { theme: 'colored' });
          } 
          setload(false);

        } catch (err: any) {        
          if(err instanceof Yup.ValidationError){
            const errors = getValidationErros(err);
            formRef.current?.setErrors(errors);
            setload(false);
            return;
          } else {

            if(err.request.statusText) {
              toast.error(err.request.statusText, { theme: 'colored' });
            } else {
              toast.error('Ocorreu algum erro, por favor entre em contato', { theme: 'colored' });
            }
            
          }           
        }
      } else {
        toast.error('Erro, as novas senhas não conferem', { theme: 'colored' });
        setload(false);
      }

    }, [token]);

    return (
    <Container>
        <FormLogin ref={divscroll}>
            <div className="scrollforms">
            <div className='boxform'>
                <img src={LogoImg} alt="Navigate To Me" />
                <span>
                    Digite seu e-mail e as novas senhas
                    para acessar sua conta <a href='https://navto.me'>navto.me</a>
                </span>

                <Form initialData={loginData} ref={formRef} onSubmit={handleResetPass}>
                    <Input type="text" defaultValue="" name="email" placeholder="seu email" />
                    <Input type="password" defaultValue="" name='password' placeholder="nova senha" maxLength={14} minLength={6} />
                    <Input type="password" defaultValue="" name='repassword' placeholder="nova senha novamente" maxLength={14} minLength={6} />
                    <Button loading={load} type="submit">alterar senha</Button> 
                </Form>


                <div className="minilinks">
                    <div><a href="./">[ acessar agora ]</a></div>
                    <div><a href='https://navto.me'>[ fazer cadastro ]</a></div>
                </div>
            </div>    
            </div>         
        </FormLogin>
        <ImageSide2 />
    </Container>    
    );
}

export default ResetPass;