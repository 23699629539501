import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { ChoseColors } from './styles';
import { ChromePicker  } from 'react-color';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { listThemes } from '../../utils/ListThemes';
import useEyeDropper from 'use-eye-dropper';

interface TypeScreen {
    prevTheme: (e:any) => void;
}

const FormColors: React.FC<TypeScreen> = ({prevTheme}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);

    const [ loadsave, setLoadSave ] = useState(false);

    const [ color, setColor ] = useState(page.page?.colorOne || '#000');

    const [ color1, setColor1 ] = useState(page.page?.colorOne || '#000');
    const [ color2, setColor2 ] = useState(page.page?.colorTwo || '#ccc');
    const [ color3, setColor3 ] = useState(page.page?.colorThree || '#999');
    const [ selectedColor, setSelectedcolor ] = useState('');

        // referente ao coletor de cor (pra verificar se o browser suporta)
    const [ loadIsSupported, setLoadIsSupported ] = useState<any>(false); 
    const { open, isSupported } = useEyeDropper();


    useEffect(() => {        
        setColor1(page.page?.colorOne || '#000');
        setColor2(page.page?.colorTwo || '#ccc'); 
        setColor3(page.page?.colorThree || '#999');
        setColor(page.page?.colorOne || '#000');
    }, [page]); //eslint-disable-line

    useEffect(() => {
        if(selectedColor === 'cor1'){
            setColor(page.page?.colorOne || '#000');
        } else if(selectedColor === 'cor2'){
            setColor(page.page?.colorTwo || '#ccc');
        } else if(selectedColor === 'cor3'){
            setColor(page.page?.colorThree || '#999');
        }
        prevTheme(false) // saindo do preview de outro tema
    }, [selectedColor]) //eslint-disable-line

    useEffect(() => {
        if(typeof isSupported() == 'boolean'){
            setLoadIsSupported(isSupported());
        }
    },[isSupported])
  
    function handleChange(color: any) {
        changeColor(color);        
        setColor(color);
    }
    function changeColor(color: string){
        if(selectedColor === 'cor1'){
            setColor1(color);
        } else if(selectedColor === 'cor2'){
            setColor2(color);
        } else if(selectedColor === 'cor3'){
            setColor3(color);
        }
    }

    const handleResetColors = () => {
        setColor1(page.page?.colorOne || '#000');
        setColor2(page.page?.colorTwo || '#ccc'); 
        setColor3(page.page?.colorThree || '#999');
    }
    const handleUseThemeColors = () => {  
        const themeId = listThemes.findIndex(o => { return o.id === Number(page.page?.theme) });
        const theme = themeId >= 0 ? themeId : 0; 
        setColor1(listThemes[theme].colorOne);
        setColor2(listThemes[theme].colorTwo); 
        setColor3(listThemes[theme].colorThree);  
    }

    const handleChangeColors = async () => { 
        try {
            setLoadSave(true);
            const datasend = {
              ...page.page,
              'colorOne': color1,
              'colorTwo': color2,
              'colorThree': color3,
              'idUser': auth.user?.id
            }
            //console.log(datasend);
            const resp = await api.post('/page_update.php', datasend);
      
            if(resp.status === 200){
              const newpage = { 
                ...page.page,
                'colorOne': color1,
                'colorTwo': color2,
                'colorThree': color3                
              };          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
        } catch (error: any) {             
            if(error.response.status === 500){
              toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
              localStorage.setItem('@navtome:token', '');
              window.location.href = '/';
            } 
            toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
    };


    const pickColor = () => {
        open()
          .then(color => handleChange(color.sRGBHex))
          .catch(() => {
            //console.log('Api EyeDropper not supported in this browser');
          })
    }
    
    
    return(
        <div className="boxform formcolor">
        <div className="simpleflex">
        <h4>Cores <span className="desctitle">Escolha as cores padrão da sua página</span></h4>    
        {loadsave && <ActivityIndicatorBtn color="#F64434" /> }
        </div>

        <div className="simpleflex colordesign">
            <div style={{ width: 'calc(100% - 255px)'}}>
            <div style={{ fontSize: '10px', textAlign: 'center'}}>
                ESCOLHA UMA DAS CORES ABAIXO E ALTERE A COR COMO UTILIZANDO O SELETOR DE COR AO LADO. 
                {loadIsSupported && 
                <div>VOCÊ TAMBÉM PODE USAR A FERRAMENTA DE COLETOR DE COR (<span className="material-icons-round" style={{ fontSize: '15px', position: 'relative', top: '3px', marginTop: '-5px'}}>colorize</span>) ABAIXO.</div>
                }
                <br /><br />
            </div>
            <div style={{display: 'flex'}}>
                <ChoseColors color={color1} onClick={() => setSelectedcolor('cor1')} className={`${selectedColor === 'cor1' ? 'selected' : ''}`} />
                <ChoseColors color={color2} onClick={() => setSelectedcolor('cor2')} className={`${selectedColor === 'cor2' ? 'selected' : ''}`} />
                <ChoseColors color={color3} onClick={() => setSelectedcolor('cor3')} className={`${selectedColor === 'cor3' ? 'selected' : ''}`} />
            </div>
            </div>
            <div>
            <ChromePicker color={color} onChange={(hex) => handleChange(hex.hex)} />
            {/*
            <SliderPicker color={color} onChange={(hex) => handleChange(hex)} />
            <CompactPicker color={color} onChange={(hex) => handleChange(hex)} /> 
            */}
            </div>            
        </div>
        <br />
        <div className='spacebutton'>
            <div className='spacebutton'>
                <div className='miniaviso'>    
                Altere as cores padrão da sua página. Clique na cor para selecionar 
                escolha a nova cor para o seu tema.
                </div>  
            </div>

            <div className='spacebutton'>
            {loadIsSupported &&                
                <button className="resetbutton eyedropper" onClick={pickColor} title="Pegar Cor">
                    <span className="material-icons-round">colorize</span>
                </button>
            }

            <button className="resetbutton" onClick={() => handleResetColors()} title="Cancelar Alterações">RESET</button>
            <button className="resetbutton" onClick={() => handleUseThemeColors()} title="Utilizar cores padrão do tema">CORES DO TEMA</button>
            <button type='submit' onClick={handleChangeColors} disabled={loadsave} className='save'>
                {loadsave ? 
                <ActivityIndicatorBtn />
                :
                <>Salvar</>
                }                        
            </button>
            </div>
            </div>
    </div>
    );
}

export default FormColors;