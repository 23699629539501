import React, { useContext, useEffect, useState } from 'react';
import { TotalVisitas, Media, SpaceChart, Range } from './styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { PageContext } from '../../contexts/PageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { ActivityIndicator, ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
  
export const options = {
responsive: true,
maintainAspectRatio: false,
plugins: {
    legend: {
        position: 'top' as const,
        display: false
    },
    title: {
        display: false,
    },    
    filler: {
        propagate: true
    },
    tooltip: {
        backgroundColor: '#F64434',
        borderColor: '#fff',
        borderWidth: 1,
        intersect: true,
        displayColors: false,
        cornerRadius: 4,
        boxPadding: 10,
        padding: 10,
        titleSpacing: 0,
        titleMarginBottom: 0,
        bodyFont: {
            family: "'Inconsolata', Calibri, sans-serif, monospace"            
        }
    }
},
scales: {
    x: {
        grid: {
            display: true,
            color: 'rgba(243, 244, 247, 0.3)', // cor da linha
            tickColor: '#fff', // cor do traço abaixo de cada grid em X,   
            drawTicks: false, // remove o traço abaixo da grid em X   
            offset: false, // posiciona o traço da grid entre os pontos
        },
        border: { color: '#fff' }, // borda X left
        ticks:{
            color: 'rgba(0,0,0,0.4)',
            font:{
                family: "'Inconsolata', Calibri, sans-serif, monospace"                
            }
        }
    },
    y: {
        grid: {
            display: true,
            color: 'rgba(243, 244, 247, 1)',
            drawTicks: false // remove o traço abaixo da grid em Y
        },
        border: { display: false }, // borda Y bottom
        ticks:{
            display: false, // remove os numeros do lado esquerdo            
        }
    },    
}};
  

const ViewVisitsPage: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ load, setLoad ] = useState(true);
    const [ allData, setAllData ] = useState([]);
    const [ labels, setLabels ] = useState([]);
    const [ totalByDays, setTotalByDays ] = useState([]);
    const [ totalVal, setTotalVal ] = useState(0);
    const [ totalGeral, setTotalGeral ] = useState(0);
    const [ mediaVal, setMediaVal ] = useState(0);
    const [ showDataFor, setShowDataFor ] = useState(30);
    
    const data = {
    labels,
    datasets: [
        {
            label: 'Visitas',    
            data: totalByDays,
            borderColor: '#F64434',
            backgroundColor: 'rgb(246, 68, 52, 0.1)',
            lineTension: 0.4,
            pointRadius: 3,
            pointBorderWidth: 5,
            fill:true
        }
    ]};

    useEffect(() => { 
        let mounted = true;
        if(mounted){
            if(page.page?.idUser) getVisits(); 
        }
        return function cleanup() {
            mounted = false
        }
    }, [page]); //eslint-disable-line

    // Dremove elementos vazios
    function myFilter(elm: any){
        return (elm != null && elm !== false && elm !== "");
    }

    useEffect(() => { 
  

        const labels = allData.slice(Math.max(allData.length - showDataFor, 0)).map((e:any, i: number) => e.labeldays );
        const values = allData.slice(Math.max(allData.length - showDataFor, 0)).map((e:any, i: number) => e.total );

        setLabels(labels.filter(myFilter) as []);
        setTotalByDays(values.filter(myFilter) as []);

        const initialValue = 0;
        const sumWithInitial = values.reduce(
          (accumulator:number, currentValue:number) => Number(accumulator) + Number(currentValue),
          initialValue
        );
        
        setTotalVal(sumWithInitial);
        const media = sumWithInitial/values.length;
        setMediaVal(media);

        setLoad(false);
    }, [allData, showDataFor]);

    function addVacuo(start: string, end :string) {
        for(var arr=[],dt=new Date(start); dt<new Date(end); dt.setDate(dt.getDate()+1)){
            let created = new Date(dt).toISOString().substr(0, 10).replace(/-/g, '-');
            var explode = created.split("-");
            let zerolabel = explode[2]+'/'+explode[1];
            let zeroday = { 
                'created': created,
                'labeldays': zerolabel,
                'total': '0'
            }
            arr.push(zeroday);
        }
        return arr;
    };


    async function getVisits(){
        try {            
            const data = await api.post('/analitcs_get_visits.php', {
                'idUser': auth.user?.id, 
                'idPage': page.page?.id
            });
            
            // veifica se tem vacuos de datas
            const visits = data.data.data[1];
            const completeData = visits.map((e:any, i:number) => {
                const nextday = new Date(e.created);
                nextday.setDate(nextday.getDate() + 1); 
                const nextdayforcompare = nextday.toISOString().substr(0, 10).replace(/-/g, '-');

                if(visits[i+1] && (nextdayforcompare !== visits[i+1].created)){
                    const vacuos = addVacuo(nextdayforcompare, visits[i+1].created);                   
                    const fullarr = [e, ...vacuos];
                    return fullarr;
                }
                return e;
            });
            const flatData = completeData.flat(1);
            setTotalGeral(data.data.data[0][0].totalGeral);
            setAllData(flatData);
         
            
        } catch (error :any) {
            if(error.response.status === 500 || error.response.status === 0){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            };        
        }
    }
       
    return (
    <div className="boxform">
        <div className="simpleflex">
            <div>
                <h4>Visitas a página</h4>
                {!load ?
                    
                    <Media>
                        Média de <strong>{mediaVal.toFixed(1)} visitas</strong> por dia<br />
                        <strong>{totalVal} visitas</strong> nos últimos {totalByDays.length} dias
                    </Media>
                    
                :
                    <Media>Aguarde</Media>
                }
            </div>
            <div>
            <TotalVisitas>
                <div className="total">{!load ? totalGeral : <ActivityIndicatorBtn/>}</div>
                <div className="label">TOTAL GERAL <br />DAS VISITAS</div>
            </TotalVisitas>            
            </div>
        </div>
        {allData.length > 7 && 
        <Range showdatafor={showDataFor}>
            <label id='for30' onClick={() => setShowDataFor(30)}>30 DIAS</label>
            <label id='for7' onClick={() => setShowDataFor(7)}>7 DIAS</label>
        </Range>   
        }     
        {!load ? 
            <>
            {allData.length > 0 ?
                <SpaceChart showdatafor={showDataFor}>
                    <Line data={data} options={options} />
                </SpaceChart>
            :
                <div>
                    <br /><br />
                    <small>Nenhum dado a ser exibido</small>
                    <br /><br />
                </div>
            }
            </>
        :
            <ActivityIndicator />
        }    
    </div>
    );
}

export default ViewVisitsPage;