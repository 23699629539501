import { useState } from "react";
import { DashContext } from "./DashContext";


export const DashProvider = ({children} : { children: JSX.Element}) => {

    const storageData = localStorage.getItem('@navtome:dash') ? true : false; 
    const [opened, setOpened] = useState<boolean>(storageData);
    
    const openDash = () => {        
        setOpened(true);
        localStorage.setItem('@navtome:dash', 'true');
    } 
    const closeDash = () => {        
        setOpened(false);
        if(localStorage.getItem('@navtome:dash')){
            localStorage.removeItem('@navtome:dash')
        };
    }  

    return(
        <DashContext.Provider value={{opened, openDash, closeDash}}>
            {children}
        </DashContext.Provider>
    )
};