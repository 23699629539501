import React, { useEffect, useState }  from 'react';

import { Container, ToggleS, StateActual } from './styles';

interface Types {
    toggled?: boolean;
    changeHide: (e: number) => void
}
const ToggleSwitch: React.FC<Types> = ({toggled, changeHide}) => {
    const [isToggled, setIsToggled] = useState(toggled);
    const onToggle = () => {
        const sethide = isToggled ? 1 : 0;
        changeHide(sethide);
        setIsToggled(!isToggled);
    }
    useEffect(() => {
        setIsToggled(toggled);
    }, [toggled])
    return (
        <Container>
            <ToggleS>
                <input type="checkbox" checked={isToggled} onChange={onToggle} />
                <span className="switch" />
            </ToggleS>
            <StateActual>{isToggled ? 'Habilitado' : 'Desabilitado'}</StateActual>
        </Container>
    );
}

export default ToggleSwitch;