import React from 'react';
import TopUser from '../../components/TopUser';
import { ScrollArea } from '../../styles/global';

const urlsite = process.env.REACT_APP_URL_PROFILE;

const Terms: React.FC = () => {

    return(
    <>
        <div className='simpleflex minHsimpleflex'>            
            <h1 className='marg0'>
                Termos de uso
                <span>Atualizado em: 23 de Julho, 2022</span>
            </h1>
            <TopUser />
        </div>

        <div className="contentpage"> 
            <ScrollArea size={7}> 
            {urlsite !== undefined &&
                <iframe title='frameterms' className='styleiframe' src={`${urlsite}/inc/termos.html`} />
            }
            <div className="gradfooter"></div>
            </ScrollArea>
        </div>        
    </>
    );
}

export default Terms;