import React, { useContext, useEffect, useState } from 'react';

import { PageContext } from '../../contexts/PageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { ActivityIndicator } from '../../components/Elements/ActivityIndicator';
import Graph from '../../components/Graph';

const ViewClicks: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ load, setLoad ] = useState(true);
    const [ allData, setAllData ] = useState([]);
    
    useEffect(() => { 
        let mounted = true;
        if(mounted){
            if(page.page?.id){
                getClicks(); 
            }
        }
        return function cleanup() {
            mounted = false
        }
    }, [page.page?.id]); //eslint-disable-line

   
    async function getClicks(){
        try {            
            const data = await api.post('/analitcs_getitems_for_clicks.php', {
                'idUser': auth.user?.id, 
                'idpage': page.page?.id
            });
            setAllData(data.data.data);
            setLoad(false);
        } catch (error :any) {
            if(error.response.status === 500 || error.response.status === 0){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            };        
        }
    }
       
    return (<>
        <h1>
            Cliques em links
            <span>CONFIRA OS CLIQUES EM TODOS OS SEUS LINKS ATIVOS</span>
        </h1>
        {!load ?
            allData.map((e: any) => <Graph key={e.id} dataItem={e} />)
        :
            <ActivityIndicator />
        }        
        </>
    );
}

export default ViewClicks;