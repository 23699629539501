import React, { useContext } from 'react';
import { ScrollArea } from '../../styles/global';
import { PageContext } from '../../contexts/PageContext';
import FormSeo from './formSeo';
import FormPixelMeta from './formPixel';
import FormAnalitcs from './formAnalitcs';
import FormLogoFoter from './formLogofooter';
import FormDel from './formDel';


const Config: React.FC = () => {
    const page = useContext(PageContext);

    return(<>
    <div className="contentpage">
        
        <h1>
        Configurações da página
        <span>VOCÊ ESTA EDITANDO A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
        </h1>

        <ScrollArea size={63}>
        
        <FormSeo />
        <FormPixelMeta />
        <FormAnalitcs />
        <FormLogoFoter />
        <FormDel />


        <div className="gradfooter"></div>
        </ScrollArea>

    </div>
    </>);
}


export default Config;