import styled from 'styled-components';
import Zap from '../../assets/ico-whats.png';
import Jivo from '../../assets/ico-chat.png';

export const BoxContact = styled.div`
    max-width: 450px;
    text-align: center;

    .opcoesdecontato {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    #jivo_custom_widget, #whatsapp {
        position: relative;
        width: 90px;
        z-index: 300000;
        cursor: pointer;
        background: #f34433 url(${Jivo}) center center no-repeat;
        background-size: 50%;
        height: 90px;
        border-radius: 50%;
        box-shadow: 0 0 20px rgb(243 68 51 / 50%);
        cursor: pointer;
        margin: 0 10px;
    }
    #whatsapp {
        background: #74C567 url(${Zap}) center center no-repeat;
        background-size: 50%;
        box-shadow: 0 0 20px rgb(116 197 103 / 50%);
    }
`