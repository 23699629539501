import styled from "styled-components";


export const GridThemes = styled.div`
    ul{
        margin-left: -3px;
    }
    ul li{
        display: inline-block;
        width: calc(16.6% - 6px);
        margin: 3px;

        img{ 
            width: 100%;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
            z-index: 0;
            transition:  0.5s ease-in-out;
        }
        :hover img{
            opacity: 0.8;
        }

        .buttons{
            display: flex;
            z-index: 1;
            align-items: center;
            justify-content: center;
            top: 0;
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
            align-items: stretch;
            /*
            position: absolute;
            flex-direction: column;
            opacity: 0;
            */
            transition:  0.5s ease-in-out;

            button{
                display: block;
                font-weight: 700;
                text-transform: uppercase;
                margin: 2px 3px;
                padding: 7px 0;
                border-radius: 5px;
                background: transparent;
                border: solid 1px #999;
                color: #999;
                width: 100%;
                font-size: 12px;

                :hover{
                    color: var(--purpleBase);
                    border: solid 1px var(--purpleBase)
                }
            }

            button.green{
                background-color: #3ea357;
                border: solid 1px #3ea357;
                color: #fff;

                :hover{
                    background: #3ea357;
                    filter: brightness(85%);
                    border: solid 1px #3ea357;
                }
            }
        }

        :hover .buttons{
            opacity: 1;
        }
    }

    .tooltip{
        position: absolute;
        width: 100%
    }
    .tooltip:hover:after{
        white-space: normal;
        width: calc(100% - 77px);
        padding: 9px 11px 8px 11px;
        top: 7px;
        left: 48px;
        height: max-content;
        font-size: 0.6rem;
        transform: translateX(0%);
    }    
    .tooltip:hover:before{
        transform: rotate(-90deg);
        top: 18px;
        position: absolute;
        left: 41px;
    }

    .simbolBlock{
        position: absolute;
        z-index: 2;
        padding: 5px;
        background: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 20px 20px 20px;
        margin: 6px;
        text-shadow: 2px 2px 5px rgb(0 0 0 / 5%), 1px 1px 0 rgb(0 0 0 / 15%);
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        cursor: pointer;

        span{ font-size: 1rem; color: var(--orangeBase); margin-left: -1px; margin-top: -2px}        
    }

    @media only screen and (max-width:1550px){
        ul li{
            width: calc(20% - 6px);
        }
    }

    @media only screen and (max-width:1310px){
        ul li{
            width: calc(25% - 6px);
        }
    }

    @media only screen and (max-width:1000px){
        ul li{
            width: calc(20% - 6px);
        }
    }
    @media only screen and (max-width:940px){
        ul li{
            width: calc(25% - 6px);
        }
    }
    @media only screen and (max-width:745px){
        ul li{
            width: calc(33% - 6px);
        }
    }
    @media only screen and (max-width:475px){
        ul li{
            width: calc(50% - 6px);
        }
    }
`