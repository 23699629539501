import styled from "styled-components";

export const Container = styled.div`
    
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;

    .lds-ripple {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
    }
    .lds-ripple div {
    position: absolute;
    border: 4px solid var(--orangeBase);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
    }
    
`
export const Container2 = styled.div<{color?: string, estilo?:string}>`

    display: inline-block;
    position: ${props => props.estilo ? 'fixed' : 'relative'};
    width: ${props => props.estilo ? '47px' : '39px'};
    height: ${props => props.estilo ? '23px' : '11px'};
    
    z-index: ${props => props.estilo ? '9' : 'unset'};
    background: ${props => props.estilo ? '#fff' : 'unset'};
    padding: ${props => props.estilo ? '5px' : 'unset'};
    border-radius: ${props => props.estilo ? '9px' : 'unset'};
    margin: ${props => props.estilo ? '5px' : 'unset'};


    .lds-ellipsis div {
        position: absolute;
        top: 4px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${props => props.color ? props.color : '#fff'};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 4px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 4px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 16px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 28px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
        }
        @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
        }
        @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(12px, 0);
        }
    }
`