import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    //baseURL: 'https://api.navto.me/',
    timeout: 93600,
    headers: {         
        //'Authorization': 'Bearer '+ jwt,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
})
// Set the AUTH token for any request
api.interceptors.request.use(function (config : any) {    
    const jwt = localStorage.getItem('@navtome:token');
    config.headers.Authorization =  jwt ? `Bearer ${jwt}` : '';
    return config;
});


export const apiStripe = axios.create({
    baseURL: 'https://api.stripe.com/',
    headers: {    
        'Content-type': 'application/json',
        'Authorization': 'Bearer '+ process.env.REACT_APP_SECRET_SRTIPE_API,
    }
})


export const useApi = () => ({
    validateToken: async (token: string) => {
        try {
            const response = await api.post('/validate_token.php', { jwt : token });
            return response.data;
        } catch (err) {
            
        }
    },
    signin: async (email: string, password: string) => {
        const response = await api.post('/login.php', { email, password});
        return response.data;
    },
    logout: async () => {
        const response = await api.post('/logout.php');
        return response.data;
    },

});
