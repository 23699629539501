import styled from "styled-components";

export const GridImages = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;

    .boximg{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
    }
    .especifica{
        text-align: center;
        font-size: 0.8rem;
        margin: 0 10%;
    }


    .perfil, .bg, .gravatar{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        justify-content: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 23px;        
        cursor: pointer;        
        z-index: 1;
        box-shadow: 0px 0px 0px 3px rgba(255,255,255,0.2), 0px 5px 10px rgba(0,0,0,0.1), 0px 3px 3px rgba(0,0,0,0.15);

        
        .initial{            
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            font-size: 4rem;
            color: #fff;
            border: solid rgba(255,255,255,0.1) 8px;            
            border-radius: 50%;
            background: var(--orangeBase);
        }
        
        input{
            display: none;
        }
    }   

    .perfil:hover:before, .bg:hover:before{
        content: 'Enviar Imagem';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        background: rgba(0,0,0,0.5);
        border-radius: 50%;
        font-size: 10px;
        z-index: 2;
    }
    .boxthumb{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            min-width: 150px;
            min-height: 150px;
            object-fit: cover;    
               
        }
    } 
    .bg{
        background: var(--orangeBase);
    }
    .bg, .bg:hover:before {
        border-radius: 10px;
    }
    .boxthumbbg{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            min-width: 150px;
            min-height: 150px;
            object-fit: cover;    
               
        }
        .material-icons-round{
            font-size: 3rem;
            color: #fff
        }
    } 

    .gravatar{
        position: absolute;
        z-index: 0;
        background: none;
        padding: 0;
        margin-left: 93px;

        img{
            border-radius: 50%;
        }
    }
    .gravatar:hover{
        animation: frontediv 0.2s linear forwards;
        img{
            animation: fronteimg 0.2s linear forwards; 
        }
    }
    @keyframes frontediv {
        0% { z-index: 0; }
        50% { z-index: 0; }
        100% { z-index: 4; }
    }
    @keyframes fronteimg {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
    .minilinks{
        margin: 0;
    }

`

export const ChoseColors = styled.div<{color: string}>`
    display: flex;
    width: calc(33% - 7px);
    height: 118px;
    border-radius: 8px;
    border: solid 3px #fff;
    border-spacing:15px;
    margin-right: 10px;
    margin-top: 0px;
    font-family: Inconsolata, Montserrat, Calibri;
    text-align: center;
    padding: 35px 0;
    font-size: 10px;
    cursor: pointer;
    background: ${props => props.color ? props.color : '#000'};
    justify-content: center;
    align-items: center;
    white-space: break-spaces;

    :before{
        content: '';        
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        box-shadow: inset 0 0 5px -1px  rgba(0,0,0,0.5);
        justify-content: center;
        align-items: center;
        white-space: break-spaces;
        display: flex;
    }

    @media only screen and (max-width:660px){
        height: 58px;
    }
`

export const SelectFont = styled.select<{font?: string}>`
    font-family: ${props => props.font ? props.font : 'Inconsolata'};
`

export const OptionFont = styled.option<{valueforcss?: string}>`
    font-family: ${props => props.valueforcss ? props.valueforcss : ''};
    font-size: 1.5rem;
    height: 100px;
    display: block;
    font-weight: 700;

    span{
        font-weight: 700;
    }
`
