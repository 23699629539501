import React, { useRef, useState, useContext, useEffect } from 'react';

import ToggleSwitch from '../Elements/ToggleSwitch';
import { Item, EditItem } from './styles';
import { typeShowItem } from '../../types/ShowItem';
import { ActivityIndicatorBtn } from '../Elements/ActivityIndicator';

import { AuthContext } from "../../contexts/AuthContext";
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { CheckTitle, FormatUrl, UrlPatternValidation } from '../../utils/functions';
import { PageContext } from '../../contexts/PageContext';

const ItemLink: React.FC<typeShowItem> = ({idx, totalitens, data, arrangeItems, updateItem, scrollIfOpen}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ title, setTitle ] = useState(data.title);
    const [ url, setUrl ] = useState(data.url);
    const [ hide, setHide ] = useState(Number(data.hide));
    const [ partOfItem, setPartOfItem ] = useState(Number(data.partOfItem) === 1 ? true : false);
    const [ loadsave, setLoadSave ] = useState(false);

    const formedit = useRef<any>(null);
    const collapse = () => {
        //const action = formedit.current.classList.includes('opened');
        formedit.current.classList.toggle('opened');
        //if(formedit.current.classList.contains('opened')){
        scrollIfOpen(idx); 
        //};
    }

    useEffect(() => {
        setTitle(data.title);
        setUrl(data.url);
        setPartOfItem(Number(data.partOfItem) === 1 ? true : false);
        setHide(Number(data.hide));
        //formedit.current.classList.remove('opened');          
    }, [data, page])

    async function handleUpdateItem(){        
        if (!UrlPatternValidation(url.trim())) {
            toast.error('Url não é válida', { theme: 'colored' });
            return;
        }

        if(!CheckTitle(title.trim())){
            toast.error('No campo Títuo, digite entre 3 e 25 caracteres', { theme: 'colored' });
            return;
        }
        setLoadSave(true); 
        try {
            const dataupdated = {
                idUser : auth.user?.id,
                id : Number(data.id),
                idpage : data.idpage,
                title : title.trim(),
                url : FormatUrl(url),
                color : data.color,
                hide : hide,
                image : data.image,
                partOfItem: partOfItem ? 1 : 0,
                description: null
            }

            await api.post('/item_update.php', dataupdated);
            
            const newitem = {
                id : data.id, 
                title : title.trim(),
                url : FormatUrl(url),
                hide : hide,
                partOfItem: partOfItem ? 1 : 0
            }   
            // console.log(newitem)
            updateItem(newitem, 'update');
            
            setLoadSave(false);
            toast.success('Atualização realizada com sucesso');

        } catch (error:any) {
            if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            } else {
                toast.error('Erro ao atualizar', { theme: 'colored' }); 
            };
        }
        setLoadSave(false);
    }

    return (

    <Item draggable={false} id="item" className="accordion-item"> 
        <div className="box">
            <div onClick={collapse} className="accordion-link" title="Clique para editar">
                <div className="drag" title="Arrastar">                    
                        <button>...............</button>                    
                </div>
                <div className="tipoIco">
                    <span className="material-icons-round">attach_file</span>
                </div>                
                <div className='data'>
                    <h2>{data.title}</h2>
                    <div className='urllink'>{data.url}</div>
                </div>
            </div>
            <div className="linksordem">                
                <button 
                    disabled={idx > 0 ? false : true} 
                    onClick={() => arrangeItems(idx, idx-1)}>
                        <span className="material-icons-round">expand_less</span>
                </button>
                <button 
                    disabled={idx === totalitens-1 ? true : false}  
                    onClick={() => arrangeItems(idx, idx+1)}>
                        <span className="material-icons-round">expand_more</span>
                </button>
            </div>            
        </div>
        <EditItem ref={formedit} className="answer" >
            <div className="padform">
                <fieldset>
                    <legend>Titulo:</legend>
                    <input onChange={(e:any) => setTitle(e.target.value)} type="text" value={title} minLength={3} maxLength={50} />
                    <legend>Url do link:</legend>
                    <input onChange={(e:any) => setUrl(e.target.value)} type="text" value={url} maxLength={200} />
                </fieldset>
                <fieldset style={{marginBottom: '10px', display: 'flex', lineHeight: '0.9rem', alignItems: 'center'}}>
                    <input type="checkbox" id="partofitem" name="partofitem" onClick={() => setPartOfItem(!partOfItem)} defaultChecked={partOfItem} />
                    <label htmlFor="partofitem">
                      <small>Mostrar somente o Título</small><br />
                      <small><small style={{lineHeight: '9px'}}>Não irá exibir a url junto do título</small></small>
                    </label>
                  </fieldset>
                <div className='spacebutton'>
                    <div className='spacebutton'>
                        <ToggleSwitch changeHide={(e) => setHide(e)} toggled={hide === 1 ? false : true} />
                        <button onClick={() => updateItem(data, 'delete')} className='del' title='Deletar pra sempre'>
                            <span className="material-icons-round">delete_forever</span>Deletar
                        </button>
                    </div>
                    
                    <button onClick={() => handleUpdateItem()} className='save'>
                        {loadsave ? 
                          <ActivityIndicatorBtn />
                        :
                          <>Salvar</>
                        }                        
                    </button>
                </div>
            </div>
        </EditItem>           
    </Item>
    );
}

export default ItemLink;