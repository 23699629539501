import React, { useContext, useEffect, useState } from 'react';
import { SpacePieChart, LimitSpacePieChart } from './styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from 'react-chartjs-2';
import { PageContext } from '../../contexts/PageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { ActivityIndicator } from '../../components/Elements/ActivityIndicator';

ChartJS.register(ArcElement, Tooltip, Legend);
  
export const options = {
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    plugins: {
        tooltip: {
            backgroundColor: '#F64434',
            borderColor: '#fff',
            borderWidth: 1,
            displayColors: false,
            cornerRadius: 4,
            boxPadding: 10,
            padding: 10,
            titleSpacing: 0,
            titleMarginBottom: 0,
            bodyFont: {
                family: "'Inconsolata', Calibri, sans-serif, monospace"            
            }
        },
        legend: {
            display: true,
            position: "right" as const           
        }
    }
};


const ViewDevicesPage: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ load, setLoad ] = useState(true);
    const [allData, setAllData] = useState([]);
    const [ labels, setLabels ] = useState([]);
    const [ totalByDevice, setTotalByDevice ] = useState([]);
    

    useEffect(() => { 
        let mounted = true;
        if(mounted){
            if(page.page?.idUser) getDevices(); 
        }
        return function cleanup() {
            mounted = false
        }
    }, [page]); //eslint-disable-line

    const data = {
    labels: labels,
    datasets: [
        {
        label: 'Dataset 1',
        data: totalByDevice,
        backgroundColor: [
            '#F64434',
            '#59558b',
            '#f3ad2a'
        ]}
    ]
    };

    async function getDevices(){
        try {            
            const data = await api.post('/analitcs_get_visits_device.php', {
                'idUser': auth.user?.id, 
                'idPage': page.page?.id
            });
            
            setAllData(data.data.data);

            const labels = data.data.data.map((e:any) => e.device );
            const values = data.data.data.map((e:any) => e.total );
    
            setLabels(labels as []);
            setTotalByDevice(values as []);
            setLoad(false);
            
        } catch (error :any) {
            if(error.response.status === 500 || error.response.status === 0){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            };        
        }
    }
       
    return (
    <div className="boxform">
        <div className="simpleflex">
            <div>
                <h4>Dispositivos
                <span className="desctitle">Aparelhos mais utilizados para acessar a página</span>
                </h4>
            </div>
        </div> 
        {!load ? 
        <>                    
            {allData.length > 0 ? 
                <LimitSpacePieChart>
                    <SpacePieChart>
                        <Pie data={data} options={options} />
                    </SpacePieChart>
                </LimitSpacePieChart>    
            :
                <div><br /><br /><small>Nenhum dado a ser exibido</small><br /><br /></div>
            }
        </>
        :
            <ActivityIndicator />
        }
        </div>
    );
}

export default ViewDevicesPage;