import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100%;

    label{
        color: rgba(0,0,0,0.35);
        position: absolute;
        z-index: 1;
        font-size: 0.9rem;
        top: 37%;
        left: 13px;
        pointer-events: none;
        -moz-transition:  0.5s ease-in;
        -o-transition:  0.5s ease-in;
        -webkit-transition:  0.5s ease-in;
        transition:  0.5s ease-in;
        font-family: Inconsolata, Montserrat, Calibri;
    }

    input{
        padding-right: 30px;
        border: solid rgba(0,0,0,0.051) 1px;  
        outline: none;
        transition:  0.5s ease-in;

        :focus + label 
        {
            background-color: #FFF;
            color: var(--orangeBase);
            top: 0px;
            padding: 0 2px 0px 2px;
            border-radius: 2px;
            font-size: 0.7rem; 
        }
        :focus{
            border-color: var(--orangeBase);
        }
    }

    label.comconteudo{
        background-color: #FFF;
        top: 0px;
        padding: 0 2px 0px 2px;
        border-radius: 2px;
        font-size: 0.7rem;  
        box-shadow: 0px 1px 2px rgba(0,0,0,0.05);
        color: var(--orangeBase);
    }
    
`

export const SButton = styled.button<{loading?: boolean, color2?: boolean}>`
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ee0c59+0,fd7c0e+100 */
    background: rgb(238,12,89); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgba(238,12,89,1) 0%, rgba(253,124,14,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(238,12,89,1) 0%,rgba(253,124,14,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(238,12,89,1) 0%,rgba(253,124,14,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee0c59', endColorstr='#fd7c0e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 15px 22px;
    font-weight: 700; 
    text-transform: uppercase;
    display: inline-block; 
    border-radius: 7px;   
    width : 100%;
    margin: 6px 0;
    opacity: ${props => !props.loading ? '1' : '0.5' };

    :hover{
        opacity: 0.8;

        box-shadow: ${props => !props.color2 ? 
            '0 8px 20px rgba(0,0,0,0.2), 0 1px 0px var(--orangeBase)'
            : 
            '0 8px 20px rgba(0,0,0,0.2), 0 1px 0px var(--purpleLive)'
            }
        ;
    }

    ${({ color2 }) =>
    color2 &&
    css`
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a800ab+0,ef005e+100 */
        background: rgb(168,0,171); /* Old browsers */
        background: -moz-linear-gradient(-45deg,  rgba(168,0,171,1) 0%, rgba(239,0,94,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg,  rgba(168,0,171,1) 0%,rgba(239,0,94,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg,  rgba(168,0,171,1) 0%,rgba(239,0,94,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a800ab', endColorstr='#ef005e',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    `}

`

export const ErroInput = styled.span<{desktitle?: string}>`
    font-size: 0.82rem;
    color: var(--orangeBase);
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    box-shadow: none;
    width: auto;
    right: 10px;
    margin-top: 18px;
    cursor: pointer;

    :hover:before{
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent #fff;
        border-radius: 2px;  
        position: absolute;
        top: 5px;
        display: ${props => props.desktitle ? 'inline-block' : 'none' };
        z-index: 2;
        left: -9px;
    }
    :hover:after{
        content: '${props => props.desktitle ? props.desktitle : '' }';
        background: #fff;
        display: ${props => props.desktitle ? 'inline-block' : 'none' };
        position: absolute;
        right: 25px;
        padding: 8px 10px;
        border: solid 1px rgba(0,0,0,0.05);
        border-radius: 5px;
        top: -6px;
        box-shadow: -2px 2px 5px rgba(0,0,0,0.05);
        white-space: nowrap;

    }

    span{
        font-size: 1.2rem;
    }
    &:hover{
        background: inherit;
        color: var(--darkblue);
    }
`