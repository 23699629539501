import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ListPagesContext } from '../../contexts/ListPagesContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import ConfirmDialog from '../../components/Elements/ConfirmDialog';
import  axios  from 'axios';
import {sha1} from 'crypto-hash';

// const upload_preset = process.env.REACT_APP_CLOUDNARY_UPLOAD_PRESET;
const cloud_name = process.env.REACT_APP_CLOUDNARY_CLOUD_NAME;
const api_key = process.env.REACT_APP_CLOUDNARY_API_KEY; 
const api_secret = process.env.REACT_APP_CLOUDNARY_API_SECRET;

const FormDel: React.FC = () => {
    const auth = useContext(AuthContext);
    const allPages = useContext(ListPagesContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);    
    const [ showConfirm, setShowConfirm ] = useState(false);

    async function handleDelPage(e:boolean){
        if(e){
            setLoadSave(true);
            try {
                const delpage = {
                    idUser: auth.user?.id, 
                    idpage: page.page?.id
                }
                const resp = await api.delete('/page_delete.php', { data: delpage });
                setShowConfirm(false);
                //updateItem(itemForDel, 'confirmdelete');
                toast.success(resp.data.message);
                //updateListPages(true);
                allPages.removePage(Number(page.page?.id))
                deletePerfilImg();
                deleteBgImg();
            
            } catch (error:any) {
                if(error.response.status === 401 || error.response.status === 0){
                    toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                    localStorage.setItem('@navtome:token', '');
                    window.location.href = '/';
                };
            }
            setLoadSave(false);
        }
    }


    const deletePerfilImg = async () => {
      if(page.page?.imageThumb && api_secret !== undefined && api_key !== undefined){
      const filename = page.page?.imageThumb.substring(page.page?.imageThumb.lastIndexOf('/')+1);
      const filename2 = filename.replace(/\.[^/.]+$/, "");      
      const timestamp = new Date().getTime();
      const string = `public_id=${filename2}&timestamp=${timestamp+api_secret}`;
      const signature = await sha1(string);
      const formData = new FormData();
      formData.append("public_id", filename2);
      formData.append("signature",signature);
      formData.append("api_key", api_key);
      formData.append("timestamp", ''+timestamp+'');
      await axios.post("https://api.cloudinary.com/v1_1/"+cloud_name+"/image/destroy", formData)
      .then((resp : any) => {
          if(resp.status === 200){
            /*
            //console.log('delperfil');
            //console.log(resp);
            setImgPerfil('');
            if(action === 'sendtoserver'){
                const newpage = { ...page.page, 'imageThumb': null };
            }
            */
          } else {
            //toast.error('Ocorreu algum erro, tente novamente mais tarde.');
            //console.log('erro ao deletar');
          }
      });  
      }
  }
  const deleteBgImg = async () => {
      if(page.page?.imageBg && api_secret !== undefined && api_key !== undefined){
      const filename = page.page?.imageBg.substring(page.page?.imageBg.lastIndexOf('/')+1);
      const filename2 = filename.replace(/\.[^/.]+$/, "");      
      const timestamp = new Date().getTime();
      const string = `public_id=${filename2}&timestamp=${timestamp+api_secret}`;
      const signature = await sha1(string);
      const formData = new FormData();
      formData.append("public_id", filename2);
      formData.append("signature",signature);
      formData.append("api_key", api_key);
      formData.append("timestamp", ''+timestamp+'');
      await axios.post("https://api.cloudinary.com/v1_1/"+cloud_name+"/image/destroy", formData)
      .then((resp : any) => {
          if(resp.status === 200){
            /*
            setImgBg('');
            if(action === 'sendtoserver'){
                const newpage = { ...page.page, 'imageBg': null };
            }
            */
          } else {
            //toast.error('Ocorreu algum erro, tente novamente mais tarde.');
            //console.log('erro ao deletar');
          }
      });
      
      }
  }

    return (
        <>
        { 
            showConfirm && 
            <ConfirmDialog 
              closeConfirm={() => setShowConfirm(false)} 
              title="Confirme" 
              icon="warning_amber"
              text={`Você esta prestes a deletar para sempre a página <strong>navto.me/${page.page?.url}</strong>. Tem certeza que deseja prosseguir? Você não poderá reverter esta ação.`}
              labelbtn={'Sim quero deletar'}
              returnDialog={(e: boolean) => handleDelPage(e)}
            /> 
        }
        <div className="boxform" style={{border: '#f64434 solid 1px'}}>
        <div className="simpleflex">
        <h4>Deletar Página<span className="desctitle">Delete a página navto.me/{page.page?.url}</span></h4>    
        {loadsave && <ActivityIndicatorBtn color="#F64434" /> }
        </div>
        <div className='spacebutton'>
            <div className='miniaviso'>    
                Após deletar esta página será impossível recuperá-la
            </div> 
            <button type='submit' onClick={() => setShowConfirm(true)} disabled={loadsave} className='delete'>
                    {loadsave ? 
                        <ActivityIndicatorBtn />
                    :
                        <>DELETAR PÁGINA PRA SEMPRE</>
                    }                        
            </button>
        </div>
        </div>
        </>
    );
}

export default FormDel;