import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Container, ErroInput } from '../styles';

interface TextAProps extends TextareaHTMLAttributes<HTMLTextAreaElement>{
    name: string;
}

const TextArea: React.FC<TextAProps> = ({name, placeholder, ...rest}) => {
    const inputRef = useRef<any>(null);
    const [content, setContent] = useState('');
    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        })
        inputRef.current.value = defaultValue;
        chekInput();
    }, [fieldName, registerField, defaultValue])
    
    const chekInput = () =>{
        if(inputRef.current.value){
            setContent('comconteudo');
        } else {
            setContent('');
        }
    }
    

    return (
    <Container>
        <textarea className={`${content}`} id={name} onChange={chekInput} defaultValue={defaultValue} ref={inputRef} {...rest}></textarea>
        {error && <ErroInput desktitle={error}><span className="material-icons-round">warning</span></ErroInput> }
        <label className={`${content}`} htmlFor={name}>{placeholder}</label>
    </Container>
    );
}

export default TextArea;