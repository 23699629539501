import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import getValidationErros from '../../utils/getValidationErros';
import { Page } from '../../types/Page';
import { useLocation } from 'react-router-dom';
import TextArea from '../../components/Elements/TextArea';


const FormContact: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);
    const [ dataPage, setDatapage ] = useState<any>(page.page);
    const location = useLocation();
    const formContact = useRef<FormHandles>(null);

    useEffect(() =>{
      const iniData= {
        email: page.page?.email ? page.page?.email : '',
        fone: page.page?.fone ? page.page?.fone : '',
        linkContact: page.page?.linkContact ? page.page?.linkContact : ''
      }
      setDatapage(iniData);
    }, [page, location.pathname])

    const handleChangeContact = useCallback(async (data: Page) => { 
        try {
            setLoadSave(true);
            formContact.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string().email('Diigite um email válido').max(45, 'Máximo de 45 caracteres'),
                fone: Yup.string().max(15, 'Máximo de 15 caracteres'),
                linkContact: Yup.string().url('Digite uma URL válida').max(200, 'Máximo de 200 caracteres'),
            });
            await schema.validate(data, { abortEarly: false, });
      
            const datasend = {
              ...page.page,
              'email': data.email,
              'fone': data.fone,
              'linkContact': data.linkContact,
              'idUser': auth.user?.id
            }
            const resp = await api.post('/page_update.php', datasend);
      
            if(resp.status === 200){
              const newpage = { 
                ...page.page, 
                'email': data.email,
                'fone': data.fone,
                'linkContact': data.linkContact
              };          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });
            }
        } catch (error: any) { 
          if(error instanceof Yup.ValidationError){
              const errors = getValidationErros(error);
              formContact.current?.setErrors(errors);
              setLoadSave(false);
              return;
          }
          if(error.response.status === 500){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
          } 
          toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
    }, [page, auth.user?.id]);

    return(
    <div className="boxform">
              <h4>Contato <span className="desctitle">Informações para contato direto com você</span></h4>  
              <Form initialData={dataPage} ref={formContact}  onSubmit={handleChangeContact}>
              <fieldset>
                  <Input placeholder='Email:' type="text" name="email" maxLength={45} /> 
              </fieldset>
              <fieldset>                    
                  <Input mask="fone" placeholder='Fone: (99) 99999-9999' type="text" name="fone" maxLength={15} /> 
              </fieldset>
              <fieldset>  
                  <TextArea placeholder='Link de contato direto (whatsapp, telegram, etc..)' rows={3} name="linkContact" maxLength={200} /> 
                  <div style={{fontSize: '0.8rem', marginTop: '-8px'}}>
                    <small><strong>Exemplo para WhatsApp:</strong> Seu fone com ddd e código do país</small>
                    <br />https://wa.me/5500999999999 
                  </div>
              </fieldset>
              <br />
              <div className='spacebutton'>
                <div className='miniaviso'>    
                  Os dados adicionados aqui será incorporado a sua página como ícones específicos e separado 
                  da sua lista de links.
                </div>                      
                <button type='submit' disabled={loadsave} className='save'>
                  {loadsave ? 
                    <ActivityIndicatorBtn />
                  :
                    <>Salvar</>
                  }                        
                </button>
              </div>
              </Form>
            </div>  
    );
}

export default FormContact;