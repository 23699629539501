import React from 'react';
import { ScrollArea } from '../../styles/global';
import FormNewUrl from './formNewUrl';

const NewPage: React.FC = () => {

  return(
    <div className="contentpage">
      <h1>
        Criar nova página
        <span>adicione novas páginas a sua conta</span>
      </h1>

      <ScrollArea size={63}>
        <FormNewUrl />
        <div className="gradfooter"></div>
      </ScrollArea>
    </div>
  );
}

export default NewPage;