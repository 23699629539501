import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActivityIndicator } from '../Elements/ActivityIndicator';
import { Item, EditItem } from '../ListLinks/styles';
import { TotalClicks, Media, SpaceChart, Range } from '../../pages/Analitcs/styles';
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';
// import { Container } from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { PageContext } from '../../contexts/PageContext';
import { AuthContext } from '../../contexts/AuthContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
responsive: true,
maintainAspectRatio: false,
plugins: {
    legend: {
        position: 'top' as const,
        display: false
    },
    title: {
        display: false,
    },    
    filler: {
        propagate: true
    },
    tooltip: {
        backgroundColor: '#6f6bae',
        borderColor: '#fff',
        borderWidth: 1,
        intersect: true,
        displayColors: false,
        cornerRadius: 4,
        boxPadding: 10,
        padding: 10,
        titleSpacing: 0,
        titleMarginBottom: 0,
        bodyFont: {
            family: "'Inconsolata', Calibri, sans-serif, monospace"            
        }
    }
},
scales: {
    x: {
        grid: {
            display: true,
            color: 'rgba(0, 0, 0, 0.01)', // cor da linha
            tickColor: '#fff', // cor do traço abaixo de cada grid em X,   
            drawTicks: false, // remove o traço abaixo da grid em X   
            offset: false, // posiciona o traço da grid entre os pontos
        },
        border: { color: 'rgba(0, 0, 0, 0.04)' }, // borda X left
        ticks:{
            color: 'rgba(0,0,0,0.4)',
            font:{
                family: "'Inconsolata', Calibri, sans-serif, monospace",
           
            }
        }
    },
    y: {
        grid: {
            display: true,
            color: 'rgba(0, 0, 0, 0.05)',
            drawTicks: false // remove o traço abaixo da grid em Y
        },
        border: { display: false }, // borda Y bottom
        ticks:{
            display: false, // remove os numeros do lado esquerdo            
        }
    },    
}};
    

type typeShowItemClick = {
  dataItem: any;
}

// Graph is just for items clicks view
const Graph: React.FC<typeShowItemClick> = ({dataItem}) => {

  const auth = useContext(AuthContext);
  const page = useContext(PageContext);
  const [ iconExpand, setIconExpand ] = useState('expand_more');
  const [ load, setLoad ] = useState(true);
  const [ allData, setAllData ] = useState([]);
  const [ labels, setLabels ] = useState([]);
  const [ totalByDays, setTotalByDays ] = useState([]);
  const [ totalVal, setTotalVal ] = useState(0);
  const [ mediaVal, setMediaVal ] = useState(0);
  const [ showDataFor, setShowDataFor ] = useState(30);
  const [ getFirstTime, setGetFirstTime ] = useState(false);


  const formedit = useRef<any>(null);
  const collapse = () => {
      formedit.current.classList.toggle('opened');
      if(iconExpand === 'expand_more'){
        setIconExpand('expand_less');
        if(!getFirstTime && dataItem.totalGeral > 0){
          getClicks(); 
          setGetFirstTime(true);
        }
      } else {
        setIconExpand('expand_more');
      }
  }

  const data = {
    labels,
    datasets: [
      {
          label: 'Clicks',    
          data: totalByDays,
          borderColor: '#6f6bae',
          backgroundColor: 'rgba(111, 107, 174, 0.2)',
          lineTension: 0.4,
          pointRadius: 3,
          pointBorderWidth: 5,
          fill:true
      }
  ]};


  // Dremove elementos vazios
  function myFilter(elm: any){
      return (elm != null && elm !== false && elm !== "");
  }

  useEffect(() => { 
      const labels = allData.slice(Math.max(allData.length - showDataFor, 0)).map((e:any, i: number) => e.labeldays );
      const values = allData.slice(Math.max(allData.length - showDataFor, 0)).map((e:any, i: number) => e.total );

      setLabels(labels.filter(myFilter) as []);
      setTotalByDays(values.filter(myFilter) as []);

      const initialValue = 0;
      const sumWithInitial = values.reduce(
        (accumulator:number, currentValue:number) => Number(accumulator) + Number(currentValue),
        initialValue
      );
      
      setTotalVal(sumWithInitial);
      const media = sumWithInitial/values.length;
      setMediaVal(media);

      setLoad(false);
  }, [allData, showDataFor]);

  function addVacuo(start: string, end :string) {
      for(var arr=[],dt=new Date(start); dt<new Date(end); dt.setDate(dt.getDate()+1)){
          let created = new Date(dt).toISOString().substr(0, 10).replace(/-/g, '-');
          var explode = created.split("-");
          let zerolabel = explode[2]+'/'+explode[1];
          let zeroday = { 
              'created': created,
              'labeldays': zerolabel,
              'total': '0'
          }
          arr.push(zeroday);
      }
      return arr;
  };


  async function getClicks(){
      try {            
          const data = await api.post('/analitcs_get_clicks.php', {
              'idUser': Number(auth.user?.id), 
              'idPage': Number(page.page?.id),
              'idItem': Number(dataItem.id)
          });
          
          // veifica se tem vacuos de datas
          const completeData = data.data.data.map((e:any, i:number) => {
              const nextday = new Date(e.created);
              nextday.setDate(nextday.getDate() + 1); 
              const nextdayforcompare = nextday.toISOString().substr(0, 10).replace(/-/g, '-');

              if(data.data.data[i+1] && (nextdayforcompare !== data.data.data[i+1].created)){
                  const vacuos = addVacuo(nextdayforcompare, data.data.data[i+1].created);                   
                  const fullarr = [e, ...vacuos];
                  return fullarr;
              }
              return e;
          });
          const flatData = completeData.flat(1);

          setAllData(flatData);
       
          
      } catch (error :any) {
        if(error.response.status === 500 || error.response.status === 0){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
        };        
      }
  }

  return (
    <Item draggable={false} id="item" className="accordion-item">
      <div className="box">
            <div onClick={collapse} className="accordion-link" title="Clique para editar">                
                <div className="tipoIco">
                    <span className="material-icons-round">attach_file</span>
                </div>                
                <div className='data'>
                    <h2>{dataItem.title}</h2>
                </div>
            </div> 
            
            <TotalClicks title='total geral'>  
                <div className="total">{dataItem.totalGeral}</div>
                <span className="material-icons-round">bar_chart</span>              
                <label>total geral dos clicks</label>    
            </TotalClicks> 
            
            <div onClick={collapse} className="linksordem centered" title="Clique para abrir" >                
                <button>
                        <span className="material-icons-round">{iconExpand}</span>
                </button>
            </div>       
        </div>
        <EditItem ref={formedit} className="answer">
            {!load ?           
            <div className="padform">
              {allData.length > 0 ?
                <>
                <Media><br />
                  Média de <strong>{mediaVal.toFixed(1)} clicks</strong> por dia,  <strong>{totalVal} clicks</strong> nos últimos {totalByDays.length} dias
                </Media>
                {allData.length > 7 &&
                  <Range showdatafor={showDataFor} bgcolor={2}>
                    <label id='for30' onClick={() => setShowDataFor(30)}>30 DIAS</label>
                    <label id='for7' onClick={() => setShowDataFor(7)}>7 DIAS</label>
                  </Range>
                }
                <SpaceChart showdatafor={showDataFor}>
                  <Line data={data} options={options} />
                </SpaceChart>
                </>
              :
                <small>Nenhum dado a ser exibido</small>
              }
            </div>
            :
            <SpaceChart><ActivityIndicator /><br /></SpaceChart>
            }
        </EditItem> 
    </Item>
  );
}

export default Graph;