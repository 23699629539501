import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import TextArea from '../../components/Elements/TextArea';
import getValidationErros from '../../utils/getValidationErros';
import { Page } from '../../types/Page';
import { useLocation } from 'react-router-dom';
import { GenerateNewOgImage } from '../../utils/functions';


const FormDescription: React.FC = () => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const [ loadsave, setLoadSave ] = useState(false);
    const [ dataPage, setDatapage ] = useState<any>(page.page);
    const location = useLocation();
    const formDesc = useRef<FormHandles>(null);

    useEffect(() =>{
      const iniData= {
        title: page.page?.title ? page.page?.title : '',
        subTitle: page.page?.subTitle ? page.page?.subTitle : '',
        bio: page.page?.bio ? page.page?.bio : '',
        location: page.page?.location ? page.page?.location : ''
      }
      setDatapage(iniData);
    }, [page, location.pathname])

    const handleChangeDesc = useCallback(async (data: Page) => { 
        try {
            setLoadSave(true);
            formDesc.current?.setErrors({});
            const schema = Yup.object().shape({
                title: Yup.string().max(40, 'Máximo de 40 caracteres'),
                bio: Yup.string().max(250, 'Máximo de 250 caracteres'),
                location: Yup.string().max(50, 'Máximo de 50 caracteres'),
            });
            await schema.validate(data, { abortEarly: false, });

            const datasend = {
              ...page.page,
              'title': data.title,
              'subTitle': data.subTitle,
              'bio': data.bio,
              'location': data.location,
              'idUser': auth.user?.id
            }
           
            const resp = await api.post('/page_update.php', datasend);
            if(resp.status === 200){
              const newpage = { 
                ...page.page, 
                'title': data.title, 
                'subTitle': data.subTitle,
                'bio': data.bio, 
                'location': data.location 
              };          
              page.choosePage(newpage);
              toast.success('Dados atualizados com sucesso', { theme: 'colored' });

              if(page.page){
                const newpage2 = { ...page.page, 'title': data.title, 'subTitle': data.subTitle};
                GenerateNewOgImage(newpage2);                
              } 
            }
        } catch (error: any) { 
          if(error instanceof Yup.ValidationError){
              const errors = getValidationErros(error);
              formDesc.current?.setErrors(errors);
              setLoadSave(false);
              return;
          }
          if(error.response.status === 500){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
          } 
          toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
    }, [page, auth.user?.id]);

    return(
        <div className="boxform">            
        <h4>Descrição <span className="desctitle">Informações básicas da sua página</span></h4>    
        <Form initialData={dataPage} ref={formDesc}  onSubmit={handleChangeDesc}>
          <fieldset>                               
            <TextArea placeholder='Título:' name='title' rows={2} maxLength={40} /> 
          </fieldset> 
          <fieldset>                   
            <Input placeholder='Ocupação:' type="text" name="subTitle" maxLength={50} /> 
          </fieldset> 
          <fieldset>
            <TextArea placeholder='Bio / Descrição:' name='bio' rows={4} maxLength={250} />
          </fieldset>
          <fieldset>
            <TextArea placeholder='Cidade - UF:' name='location' rows={2} maxLength={50} />            
          </fieldset>
          <div className='spacebutton'>
            <div className='miniaviso'>    
              Adicione suas configurações básicas e deixe sua página ainda mais bonita e informativa.
            </div>                     
            <button type='submit' disabled={loadsave} className='save'>
              {loadsave ? 
                <ActivityIndicatorBtn />
              :
                <>Salvar</>
              }                        
            </button>
          </div>     
        </Form>
        </div>
    );
}

export default FormDescription;