import React, { useContext } from 'react';
import { ScrollArea } from '../../styles/global';
import { PageContext } from '../../contexts/PageContext';
import ViewVisitsPage from './viewVisitsPage';
import ViewDevicesPage from './viewDevicesPage';
import ViewClicks from './viewClicks';

// import { Container } from './styles';

const Analitcs: React.FC = () => {
    const page = useContext(PageContext);

    return (<>
        <div className="contentpage">        
            <h1>
            Métricas de visitas e cliques
            <span>INFORMAÇÕES SOBRE A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
            </h1>
            <ScrollArea size={63}>                
                <ViewVisitsPage />                
                <ViewDevicesPage />
                <ViewClicks />
                <br/>
                <div className="gradfooter"></div>
            </ScrollArea>            
        </div>
        </>);
}

export default Analitcs;