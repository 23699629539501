import React, {useState, useEffect, useContext} from 'react';
import { GridImages } from './styles';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { Md5 } from 'ts-md5';
import Resizer from "react-image-file-resizer";
import  axios  from 'axios';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { sha1 } from 'crypto-hash';
import { BlockResourcesByPlan, GenerateNewOgImage } from '../../utils/functions';


const upload_preset = process.env.REACT_APP_CLOUDNARY_UPLOAD_PRESET;
const cloud_name = process.env.REACT_APP_CLOUDNARY_CLOUD_NAME;
const api_key = process.env.REACT_APP_CLOUDNARY_API_KEY; 
const api_secret = process.env.REACT_APP_CLOUDNARY_API_SECRET;

const FormImages: React.FC = () => {
    const auth = useContext(AuthContext);
    const page = useContext(PageContext);

    const [ loadsave, setLoadSave ] = useState(false);
    const [ imgPerfil, setImgPerfil ] = useState<any>(page.page?.imageThumb);
    const [ imgBg, setImgBg ] = useState<any>(page.page?.imageBg);

    const unlocked = BlockResourcesByPlan('formImageBg', Number(auth.user?.planSignature));

    const email = auth.user?.email ?? '';
    const md5email = Md5.hashStr(email);  
    const linkgravatar = 'https://www.gravatar.com/avatar/'+md5email;

    const [ showgravatar, setShowGratar ] = useState(false);

    useEffect(() =>{        
        setImgPerfil(page.page?.imageThumb);
        setImgBg(page.page?.imageBg);
    }, [page]);

    useEffect(() => { checkImageGravatar(linkgravatar) });

    function checkImageGravatar(url: string) {
        var request = new XMLHttpRequest();
        request.open("GET", url + '.png?d=404', true);
        request.send();
        request.onload = function() {
            //status = request.status;
            if (request.status === 200) { 
                setShowGratar(true); 
            } else { 
                setShowGratar(false); 
                console.clear();
            }
        }
    }
      
    const resizeFilePerfil = (file: File) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer( file, 400, 400, "JPEG", 85, 0, (uri: any) => { 
            resolve(uri);
        },
        "base64", 400, 400
        );
    });
    const resizeFileBg = (file: File) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer( file, 1024, 1024, "JPEG", 85, 0, (uri: any) => { 
            resolve(uri);
        },
        "base64", 800, 800
        );
    });

    const onChangePerfilImg = async (e: any) => {
        if(e.target.files[0]){
        setLoadSave(true);
        try {
            const file = e.target.files[0];
            const image = await resizeFilePerfil(file);
            //console.log(image);
            setImgPerfil(image);
            uploadImgToCloudinary('perfil', image);
        } catch (err) {
            //console.log(err);
        }
        }
    };

    const uploadImgToCloudinary = async (refer:string, image: any) => { 
        if(upload_preset !== undefined){
            const formData = new FormData();
            formData.append('file', image);
            formData.append('upload_preset', upload_preset);
            await axios.post("https://api.cloudinary.com/v1_1/"+cloud_name+"/image/upload", formData)
            .then((resp : any) => {
            if(resp.status === 200){        
                if(refer === 'perfil'){
                    deletePerfilImg('nosendtoserver');
                    const newpage = { ...page.page, 'imageThumb': resp.data.secure_url, 'typeupdate': 'imgperfil' };
                    updateProfile(newpage);
                    
                    if(page.page){
                        const newpage2 = { ...page.page, 'imageThumb': String(resp.data.secure_url)};
                        GenerateNewOgImage(newpage2);
                    }                    
                } else {
                    deleteBgImg('nosendtoserver');
                    const newpage = { ...page.page, 'imageBg': resp.data.secure_url, 'typeupdate': 'imgbg' };
                    updateProfile(newpage);
                }
            } else {
                toast.error('Ocorreu algum erro, tente novamente mais tarde.');        
            }
            });  
        }  
    }
  
    const deletePerfilImg = async (action: string) => {
        if(page.page?.imageThumb && api_secret !== undefined && api_key !== undefined){
        const filename = page.page?.imageThumb.substring(page.page?.imageThumb.lastIndexOf('/')+1);
        const filename2 = filename.replace(/\.[^/.]+$/, "");      
        const timestamp = new Date().getTime();
        const string = `public_id=${filename2}&timestamp=${timestamp+api_secret}`;
        const signature = await sha1(string);
        const formData = new FormData();
        formData.append("public_id", filename2);
        formData.append("signature",signature);
        formData.append("api_key", api_key);
        formData.append("timestamp", ''+timestamp+'');
        await axios.post("https://api.cloudinary.com/v1_1/"+cloud_name+"/image/destroy", formData)
        .then((resp : any) => {
            if(resp.status === 200){
            //console.log('delperfil');
            //console.log(resp);
            //setImgPerfil('');
            if(action === 'sendtoserver'){
                const newpage = { ...page.page, 'imageThumb': null };
                updateProfile(newpage);

                if(page.page){
                    const newpage2 = { ...page.page, 'imageThumb': undefined};
                    GenerateNewOgImage(newpage2);
                }  
            }
            } else {
            //toast.error('Ocorreu algum erro, tente novamente mais tarde.');
            //console.log('erro ao deletar');
            }
        });  
        }
    }
    const deleteBgImg = async (action: string) => {
        if(page.page?.imageBg && api_secret !== undefined && api_key !== undefined){
        const filename = page.page?.imageBg.substring(page.page?.imageBg.lastIndexOf('/')+1);
        const filename2 = filename.replace(/\.[^/.]+$/, "");      
        const timestamp = new Date().getTime();
        const string = `public_id=${filename2}&timestamp=${timestamp+api_secret}`;
        const signature = await sha1(string);
        const formData = new FormData();
        formData.append("public_id", filename2);
        formData.append("signature",signature);
        formData.append("api_key", api_key);
        formData.append("timestamp", ''+timestamp+'');
        await axios.post("https://api.cloudinary.com/v1_1/"+cloud_name+"/image/destroy", formData)
        .then((resp : any) => {
            if(resp.status === 200){
            //setImgBg('');
            if(action === 'sendtoserver'){
                const newpage = { ...page.page, 'imageBg': null };
                updateProfile(newpage);
            }
            } else {
            //toast.error('Ocorreu algum erro, tente novamente mais tarde.');
            //console.log('erro ao deletar');
            }
        });        
        }
    }
    
    const updateProfile = async (newpage: any) => {
        try {      
        await api.post('/page_update.php', newpage);
        delete newpage.typeupdate;
        page.choosePage(newpage); 
        toast.success('Página atualizada com sucesso');
        } catch (error: any) { 
            if(error.response.status === 500){
              toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
              localStorage.setItem('@navtome:token', '');
              window.location.href = '/';
            } 
            toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
        }
        setLoadSave(false);
    }

    const onChangeBgImg = async (e: any) => {
        if(unlocked) {
            if(e.target.files[0]){
            setLoadSave(true);
            try {
                const file = e.target.files[0];
                const image = await resizeFileBg(file);
                //console.log(image);
                setImgBg(image);
                uploadImgToCloudinary('bg', image);
            } catch (err) {
                //console.log(err);
            }
            }
        } else {
            toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
        }
    };
    
    const handleUseImgeGravatar = () => {
        setLoadSave(true);
        setImgPerfil(linkgravatar);
        deletePerfilImg('nosendtoserver');
        const newpage = { ...page.page, 'imageThumb': linkgravatar, 'typeupdate': 'imgperfil'  };
        updateProfile(newpage);
    }

    return(
    <div className="boxform">
        <div className="simpleflex">
        <h4>Imagens <span className="desctitle">Imagens de perfil e background</span></h4>    
        {loadsave && <ActivityIndicatorBtn color="#F64434" /> }
        </div>
        <GridImages>
        <div className='boximg'>
        
            <label className="perfil" htmlFor="imgperfil">
            {imgPerfil ? 
                <div className='boxthumb'><img alt="Imagem Perfil" src={imgPerfil} /></div>
                :
                <div className="initial">{page.page?.title ? page.page?.title.charAt(0) :  page.page?.url.charAt(0)}</div>
            }
            <input id='imgperfil' accept="image/*" type="file" onChange={(e:any) => onChangePerfilImg(e)} />
            </label>
            {
            imgPerfil !== linkgravatar && showgravatar === true &&
            <div onClick={() => handleUseImgeGravatar()} className="gravatar tooltip" data-title='Usar esta imagem'>
            <img alt="Imagem Gravatar" src={`${linkgravatar}+?s=150`} />
            </div>
            }
            
        </div>
        <div className={`boximg ${!unlocked && 'blockedByPlan'}`} data-title="A partir do plano básico">
            
            <label className="bg" htmlFor="imgbg">
            {imgBg ?                       
                <div className='boxthumbbg'><img  alt='Imagem Background' src={imgBg} /></div>
                :
                <div className='boxthumbbg'><span className="material-icons-round">upload_file</span></div>
            }
            <input id="imgbg" accept="image/*" type="file" onChange={(e:any) => onChangeBgImg(e)} />
            </label>

        </div>
        </GridImages>
        <GridImages>
        <div className='boximg'>
            <div className="especifica"><strong>IMAGEM PERFIL</strong><br />
            Utilize imagens quadradas e com no máximo 1 mb<br />
            <div className="minilinks">
                <button onClick={() => deletePerfilImg('sendtoserver')}>[ remover ]</button>
            </div>
            </div>
        </div>
        <div className='boximg'>                  
            <div className="especifica"><strong>IMAGEM DE FUNDO</strong><br />
            Utilize imagens com no máximo 2 mb<br />
            <div className="minilinks">
                <button onClick={() => deleteBgImg('sendtoserver')}>[ remover ]</button>
            </div>
            </div>
        </div>
        </GridImages>
        <br />
        <div className='spacebutton'>
        <div className='miniaviso'>    
            Altere suas imagens de perfil e fundo (background). Temas que não possuem
            opção para imagem de fundo o mesmo não será utilizado.
        </div>                      
        
        </div>
    </div>
    );
}

export default FormImages;