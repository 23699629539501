import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';
import { SelectFont, OptionFont } from './styles'
import { BlockResourcesByPlan } from '../../utils/functions';

interface TypeScreen {
    prevTheme: (e:any) => void;
}

const FormFonts: React.FC<TypeScreen> = ({prevTheme}) => {

    const auth = useContext(AuthContext);
    const page = useContext(PageContext);
    const selectRef = useRef<any>();
    
    const [ loadsave, setLoadSave ] = useState(false);
    const [ font, setFont ] = useState(page.page?.fontName || 'Montserrat');
    
    const unlocked = BlockResourcesByPlan('formFonts', Number(auth.user?.planSignature));
    
    const options = [
        { key: 1, value: 'Montserrat' },
        { key: 2, value: 'Inconsolata' },
        { key: 3, value: 'Roboto' },
        { key: 4, value: 'Raleway' },
        { key: 5, value: 'Quicksand' },
        { key: 6, value: 'PT+Sans+Narrow' },        
        { key: 7, value: 'Nunito' },
        { key: 8, value: 'Cinzel' },
        { key: 9, value: 'Rajdhani' },
        { key: 10, value: 'Yanone+Kaffeesatz' },
        { key: 11, value: 'Fugaz+One' },
        { key: 12, value: 'Cherry+Swash' },
        { key: 13, value: 'Trade+Winds' },
        { key: 14, value: 'Germania+One' },
        { key: 15, value: 'Bayon' },
        { key: 16, value: 'Josefin+Sans' }
    ];


    useEffect(() => {        
        setFont(page.page?.fontName || 'Montserrat');
        selectRef.current.value = page.page?.fontName || 'Montserrat';
    }, [page])
    

    const handleChangeFont = async () => { 
        if(unlocked) {
            try {                
                setLoadSave(true);
                const datasend = {
                ...page.page,
                'fontName': font,
                'idUser': auth.user?.id
                }
                const resp = await api.post('/page_update.php', datasend);
        
                if(resp.status === 200){
                const newpage = { 
                    ...page.page,
                    'fontName': font
                };          
                page.choosePage(newpage);
                toast.success('Dados atualizados com sucesso', { theme: 'colored' });
                }
                
            } catch (error: any) {             
                if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
                } 
                toast.error('Erro ao atualizar dados', { theme: 'colored' }); 
            }
            setLoadSave(false);
        } else {
            toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
        }
    };

    return(
        <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Disponível a partir do plano básico">
            <div className="simpleflex">
            <h4>Fonte <span className="desctitle">Escolha a tipografia padrão da sua página</span></h4>    
            {loadsave && <ActivityIndicatorBtn color="#F64434" /> }
            </div>

            <SelectFont onClick={() => prevTheme(false)} ref={selectRef} font={font.replaceAll('+', ' ')} onChange={(e:any) => setFont(e.target.value)}>
                {options.map((e:any, i:number) => 
                    <OptionFont key={i} value={e.value} valueforcss={e.value.replaceAll('+', ' ')}>{e.value.replaceAll('+', ' ')}</OptionFont>
                )}
            </SelectFont>
        
            <br />
            <div className='spacebutton'>
                <div className='miniaviso'>    
                    Altere a font padrão da sua página, escolha das opçoes acima e salve.
                </div>                      
                <button type='submit' onClick={handleChangeFont} disabled={loadsave} className='save'>
                    {loadsave ? 
                        <ActivityIndicatorBtn />
                    :
                        <>Salvar</>
                    }                        
                </button>
            </div>
        </div>
    );
}

export default FormFonts;