
import { IThemes } from '../types/Themes';
import tema0 from '../assets/exempleTemes/0.jpg';
import tema1 from '../assets/exempleTemes/1.jpg';
import tema3 from '../assets/exempleTemes/3.jpg';
import tema4 from '../assets/exempleTemes/4.jpg';
import tema6 from '../assets/exempleTemes/6.jpg';
import tema7 from '../assets/exempleTemes/7.jpg';
import tema8 from '../assets/exempleTemes/8.jpg';
import tema9 from '../assets/exempleTemes/9.jpg';
import tema15 from '../assets/exempleTemes/15.jpg';
import tema16 from '../assets/exempleTemes/16.jpg';
import tema20 from '../assets/exempleTemes/20.jpg';
import tema21 from '../assets/exempleTemes/21.jpg';
import tema22 from '../assets/exempleTemes/22.jpg';
import tema23 from '../assets/exempleTemes/23.jpg';

export const listThemes: Array<IThemes> = [
    { id: 0, img: tema0,   colorOne: '#f3f3f3', colorTwo: '#feb800', colorThree: '#612a00', font: 'Montserrat', planForUse: 0 },
    { id: 1, img: tema1,   colorOne: '#222A37', colorTwo: '#BA292E', colorThree: '#ffffff', font: 'Montserrat', planForUse: 0 },
    { id: 3, img: tema3,   colorOne: '#ffffff', colorTwo: '#f3f7f6', colorThree: '#e6409e', font: 'Montserrat', planForUse: 0 },
    { id: 4, img: tema4,   colorOne: '#28293D', colorTwo: '#1C1C28', colorThree: '#FFA73C', font: 'Montserrat', planForUse: 0 },
    { id: 6, img: tema6,   colorOne: '#2D2E4A', colorTwo: '#FFBB0F', colorThree: '#ffffff', font: 'Inconsolata', planForUse: 1 },
    { id: 7, img: tema7,   colorOne: '#f1e9de', colorTwo: '#20202a', colorThree: '#795964', font: 'Inconsolata', planForUse: 1 },
    { id: 8, img: tema8,   colorOne: '#191A22', colorTwo: '#baa38f', colorThree: '#63758a', font: 'Rajdhani', planForUse: 1 },
    { id: 9, img: tema9,   colorOne: '#FAF9FE', colorTwo: '#FF6600', colorThree: '#0068C3', font: 'Rajdhani', planForUse: 1 },
    { id: 15, img: tema15, colorOne: '#2E2B52', colorTwo: '#FC6C21', colorThree: '#FFFFFF', font: 'Montserrat', planForUse: 2 },
    { id: 16, img: tema16, colorOne: '#D0DBF1', colorTwo: '#1E56C3', colorThree: '#23314C', font: 'Montserrat', planForUse: 2 },
    { id: 20, img: tema20, colorOne: '#091A2F', colorTwo: '#FD0A1A', colorThree: '#ffffff', font: 'Montserrat', planForUse: 2 },
    { id: 21, img: tema21, colorOne: '#CFD7DA', colorTwo: '#00A56E', colorThree: '#436039', font: 'Montserrat', planForUse: 2 },
    { id: 22, img: tema22, colorOne: '#ffffff', colorTwo: '#FFBE49', colorThree: '#6E6E6E', font: 'Josefin+Sans', planForUse: 2 },
    { id: 23, img: tema23, colorOne: '#20296A', colorTwo: '#E93855', colorThree: '#ffffff', font: 'Josefin+Sans', planForUse: 2 },
]; 
