import React, { useRef, useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { PageContext } from '../../contexts/PageContext';
import { ActivityIndicatorBtn } from '../../components/Elements/ActivityIndicator';
import { toast } from 'react-toastify';
import { AjustaUrl, LimitPagesByPlan } from '../../utils/functions';
import { api } from '../../hooks/useApi';
import { ListPagesContext } from '../../contexts/ListPagesContext';


const FormNewUrl: React.FC = () => {

    const auth = useContext(AuthContext);
    const allPages = useContext(ListPagesContext);
    const page = useContext(PageContext);
    const inputUrl = useRef<HTMLInputElement | null>(null);
    
    const [ loadsave, setLoadSave ] = useState(false);
    
    const limitPages = LimitPagesByPlan(Number(auth.user?.planSignature));    
    const unlocked = allPages.listPages.length < limitPages ? true : false;

    const formataUrl = () => {
        if(inputUrl.current){
          inputUrl.current.value = AjustaUrl(inputUrl.current.value);
        }
    }
    const handleAddUrl = async () => {
      if(unlocked) { 
      formataUrl();
      const newurl = inputUrl.current?.value ? inputUrl.current?.value : '';
      if(newurl.length >= 2 && newurl.length <= 27) {
        setLoadSave(true);
        try {        
          const datasend = {'url': inputUrl.current?.value}
          const resp = await api.post('/page_checkexist.php', datasend);

          if(resp.status === 200){
            const newurl = {
              'idUser': auth.user?.id,
              'url': inputUrl.current?.value
            }

            const respcad = await api.post('/page_create.php', newurl);

            const newpage = { 
              'id': Number(respcad.data.id),
              'idUser' : Number(auth.user?.id),
              'url': String(inputUrl.current?.value),
              'theme' : 0,
              'colorOne' : '#f3f3f3', 
              'colorTwo' : '#feb800', 
              'colorThree' : '#612a00', 
              'fontName' : 'Montserrat',
              'logoFooter' : 0
            };          
            page.choosePage(newpage);
            allPages.addPage(newpage);
            //updatelistPages(true);
            toast.success('Url cadastrada com sucesso', { theme: 'colored' });
          } 
  
        } catch (error: any) {
          if(error.response.status === 500){
            toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
            localStorage.setItem('@navtome:token', '');
            window.location.href = '/';
          } else {
            toast.error('Url indisponível para registro', { theme: 'colored' });
          };            
        }
        setLoadSave(false);
      } else {
        toast.error('Digite entre 2 e 27 caracteres');
      }
      } else {
        toast.error('Espertinho heim... Deixa de besteira e assina, é baratinho.')
      }
    }

    return (
    <div className={`boxform ${!unlocked && 'blockedByPlan'}`} data-title="Assine e tenha mais páginas">
    <h4>Url única <span className="desctitle">Link único da sua nova página</span></h4>    
    <fieldset>
      <legend>Url:</legend>
      <label className='labelurl'>navto.me/</label>
      <input ref={inputUrl} placeholder="nomedapagina" className='trocaurl' defaultValue='' onChange={formataUrl} type="text" minLength={2} maxLength={27} />
    </fieldset>
    <div className='spacebutton'>
        <div className='miniaviso'>    
          Utilize o campo acima para cadastrar novas páginas a sua conta.
        </div>                     
        <button type='submit' onClick={() => handleAddUrl()} disabled={loadsave} className='save'>
            {loadsave ? 
              <ActivityIndicatorBtn />
            :
              <>Salvar</>
            }                        
        </button>
      </div>
    </div>
    );
}

export default FormNewUrl;