import React, { useRef, useState, useContext, useEffect } from 'react';
import ItemEmbed from './ItemEmbed';
import ItemLink from './ItemLink';
import ItemSeparador from './ItemSeparador';
import ItemTitulo from './ItemTitulo';
import ItemTituloDescricao from './ItemTituloDescricao';
import { ScrollArea } from '../../styles/global';
//import { Draggable } from "react-drag-reorder";
import { ReactSortable } from "react-sortablejs";
import { AuthContext } from "../../contexts/AuthContext";
import { PageContext } from '../../contexts/PageContext';

import {arrayMoveImmutable} from 'array-move';
import { api } from '../../hooks/useApi';
import { toast } from 'react-toastify';

interface TypeList{
    data: any;
    //loadlist: boolean;
    reorderList: (e:any) => void;
    updateItem: (e:any, type: string) => void;
    addSugestion: (a: string, b: string) => void;
}

const ListLinks: React.FC<TypeList> = ({data, reorderList, updateItem, addSugestion}) => {

    const page = useContext(PageContext);
    const auth = useContext(AuthContext);

    const [ items, setItems ] = useState(data);
    const scroller = useRef<any>(null);
    //const [ load, setLoad ] = useState(loadlist);
    const allSugestions = ['youtube', 'instagram', 'facebook', 'twitter', 'tiktok'];
    const [ sugestions, setSugestions ] = useState(allSugestions);

    useEffect(() => {
        // console.log(loadlist)
        //setLoad(loadlist);
        setItems(data);        
        checkSugestions(data);
    }, [data, page]); // eslint-disable-line
    
   
    const toastId = useRef<any>();
    const notifytoast = () => toastId.current = toast("Atualizando Ordem", { autoClose: false });  
    const updatetoast = () => toast.update(toastId.current, { render: "Atualização finalizada", type: "success", autoClose: 2000 });
  
    async function saveOrderinDB(neworder: any){
        // enviar nova ordem para o banco e salvar um por um
        try {
            const justIds = neworder.map((e:any) => e.id);

            const data = {
                idUser: auth.user?.id,
                idpage: page.page?.id,
                neworder: justIds.reverse().toString()
            }     
            //const id = toast.loading("Salvando mudanças", { autoClose: 100 });
            notifytoast();
            await api.post('/item_reorder.php', data);
            updatetoast();            
            // console.log(resp);
            //toast.success("posicionamento alterado");
            
            // para atualizar o preview da pagina
            const newpage = {...page.page};          
            page.choosePage(newpage);

        } catch (error: any) {
            if(error.response.status === 500){
                toast.error('Sessão expirada, refaça login', { theme: 'colored' }); 
                localStorage.setItem('@navtome:token', '');
                window.location.href = '/';
            } else {
                toast.error('Erro ao salvar', { theme: 'colored' }); 
            };          
        }
  
        
    }

    function arrangeItems(currentPos : number, newPos: number){
        // console.log(currentPos + ' - ' + newPos)
        if(currentPos !== newPos){
            const oldarr = items;
            const newarr = arrayMoveImmutable(oldarr, currentPos, newPos);
            reorderList(newarr);  
            saveOrderinDB(newarr);    
        } 
    }

    function getComponent(e: any, i: number){
        switch (e.typeitem) {
            case '1':
            case 1: 
                return <ItemLink key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)} updateItem={(e: any, type: string) => updateItem(e, type)} />
            case '2':
            case 2:
                return <ItemEmbed key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)}  updateItem={(e: any, type: string) => updateItem(e, type)} />
            case '3':
            case 3:
                return <ItemSeparador key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)}  updateItem={(e: any, type: string) => updateItem(e, type)} />
            case '4':
            case 4:
                return <ItemTitulo key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)}  updateItem={(e: any, type: string) => updateItem(e, type)} />   
            case '5':
            case 5:
                return <ItemTituloDescricao key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)}  updateItem={(e: any, type: string) => updateItem(e, type)} />   
            default:
                return <ItemLink key={i} idx={i} scrollIfOpen={(idx:number) => scrollIfOpen(idx)} totalitens={data.length} data={e} arrangeItems={(c: number, n:number) => arrangeItems(c, n)}  updateItem={(e: any, type: string) => updateItem(e, type)} />
        }
    }

    function checkSugestions(data: any){        
        if(data.length > 0){
            if(data.length <= 5){
                let remove: string[] = [];
                data.map((e:any) =>  
                    allSugestions.map((i:string) => e.url.includes(i) && remove.push(i)
                    )
                );                      
                const results = allSugestions.filter( function(e) {
                    return remove.indexOf( e ) < 0;
                });                
                setSugestions(results);
            } else {
                //se ja tiver mais de 5 links remove as sugestoes
                setSugestions([]);
            }
        } else {
            //se não tiver links mostra todas as sugestoes
            setSugestions(allSugestions);
        }
    }

    function scrollIfOpen(idx:number){        
        const posElem = document.getElementById('itm-'+idx)?.offsetTop;
        // console.log(posElem);
        // console.log(scroller.current.scrollHeight);
        scroller.current.scroll({ top: posElem, behavior: 'smooth'});
    }

    return (
    <ScrollArea ref={scroller}>    
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
            {data.length > 0 ? 
                
                <ReactSortable 
                    list={items} 
                    setList={setItems}                
                    animation={200}
                    handle=".drag"
                    onEnd={(evt: any) => arrangeItems(evt.oldIndex, evt.newIndex)}                    
                    >
                        {items.map((e: any, i: number) => ( <div id={`itm-${i}`} key={i}>{getComponent(e, i)}</div> ))}
                </ReactSortable>
                
                : 
                <div className='noitems'>
                    <div className='semlink'>Ainda não existem links cadastrados, utilize as opções acima ou sugestões abaixo e cadastre seu primero link agora.</div>
                </div>
            }

            {sugestions.length > 0 &&
                <div className="spacesugestoes">
                    <h4>- SUGESTÃO DE LINKS -</h4>
                    <small>VERIFIQUE E ADICIONE COM UM CLICK OS LINKS ABAIXO<br/><br/></small>

                    <div className="sugestoes">
                        {sugestions.map((e:string) =>                                                         
                            <div key={e}><span>http://{e}.com/{e === 'youtube' ? 'c/' : ''}{e === 'tiktok' ? '@' : ''}{page.page?.url}</span><button onClick={() => addSugestion( e, `http://${e}.com/${e === 'youtube' ? 'c/' : ''}${e === 'tiktok' ? '@' : ''}${page.page?.url}`)}>ADICIONAR</button></div>
                        )}
                    </div>
                    <br/>
                </div>
            }
            </div>
        <div className="gradfooter"></div>
    </ScrollArea>
    );
}

export default ListLinks;
