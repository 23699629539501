import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import LogoImg from '../../assets/navigatetome2.png';
import { Container, FormLogin, ImageSide } from './styles';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web';
import Input from '../../components/Elements/Input';
import Button from '../../components/Elements/Button';
import getValidationErros from '../../utils/getValidationErros';
import { toast } from 'react-toastify';
import { api } from '../../hooks/useApi';


interface SignInFormData{
    email: string;
    password: string;
}
interface ResetFormData{
    emailreset: string;
}

const SignIn: React.FC = () => {

    const [load, setload] = useState(false);
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const formRef = useRef<FormHandles>(null);
    const formRefReset = useRef<FormHandles>(null);
    const divscroll = useRef<any>(null);
    const loginData = { email: '', password: ''}
    const loginResetData = { emailreset: '' }
    const [hideForm, setHideForm] = useState(false);
    
    useEffect(() => {
        if(auth.user){
            navigate('/links');
        }
    });

    /*const prescheDados = () => {
      formRef.current?.setFieldValue('email', '');
      formRef.current?.setFieldValue('password', '');
    }*/

    const handleLogin = useCallback(async (data: SignInFormData) => {        
        try {
            setload(true);
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string().max(35, "Máximo de 35 caracteres").required('Email obrigatório').email('Insira um email válido'),
                password: Yup.string().max(14, 'Máximo de 14 caracteres').min(6, 'Mínimo de 6 caracteres').required('Senha Obrigatória'),
            });
            await schema.validate(data, { abortEarly: false, });

            const isLogged = await auth.signin(data.email, data.password);
            
            if(isLogged){
                navigate('/links');
            }
            setload(false);

        } catch (err) {            
            if(err instanceof Yup.ValidationError){
                const errors = getValidationErros(err);
                formRef.current?.setErrors(errors);
                setload(false);
                return;
            }
            
        }
        
    }, [auth, navigate]);


    const handleReset = useCallback(async (data: ResetFormData) => {
    
        try {
            setload(true);
            formRefReset.current?.setErrors({});
            const schema = Yup.object().shape({
                emailreset: Yup.string().max(30).required('Email obrigatório').email('Insira um email válido')                
            });
            await schema.validate(data, { abortEarly: false, });            
            const send = { email : data.emailreset }            
            const resp = await api.post('/user_resetpass.php', send);
            if(resp.status === 200){
               toast.success('Verifique seu email', {theme: 'colored'}); 
               setHideForm(true);
            } 
            setload(false);

        } catch (err : any) {            
            if(err instanceof Yup.ValidationError){
                const errors = getValidationErros(err);
                formRefReset.current?.setErrors(errors);
                setload(false);
                return;
            } else {
                if(err.response.data.message !== undefined){
                    toast.error(err.response.data.message, {theme: 'colored'});
                } else {
                    toast.error('Erro desconhecido, tente mais tarde ou entre em contato conosco', {theme: 'colored'}); 
                }
            }

        }
    }, []);

    const scroll = (scrollOffset : number) => {
        divscroll.current.scrollLeft += scrollOffset;
    };    

    return (
    <Container>
        <FormLogin ref={divscroll}>
            <div className="scrollforms">
            <div className='boxform'>
                <img src={LogoImg} alt="Navigate To Me" />
                <span>
                    Digite seu e-mail e senha abaixo 
                    para acessar sua conta <a href='https://navto.me'>navto.me</a>
                </span>

                <Form initialData={loginData} ref={formRef} onSubmit={handleLogin}>
                    <Input type="email" autoComplete="true" defaultValue="" name="email" placeholder="email" />
                    <Input type="password" defaultValue="" name='password' placeholder="senha" maxLength={14} minLength={6} />
                    <Button loading={load} type="submit">entrar</Button> 
                </Form>


                <div className="minilinks">
                    <div><button onClick={() => scroll(1000)}>[ esqueci a senha ]</button></div>
                    {/*<div><Link to="/reset">[ esqueci a senha ]</Link></div>*/}
                    <div><a href='https://navto.me'>[ fazer cadastro ]</a></div>
                </div>
            </div>  
            <div className='boxform'>
                <img src={LogoImg} alt="Navigate To Me" />
                <span>
                    Digite seu e-mail para receber um link em sua caixa de correios
                    e realizar a troca de senha. <br /><br />
                    <strong>Não esqueça de verificar a caixa de spam.</strong>
                </span>
                {!hideForm ? 
                    <Form initialData={loginResetData} ref={formRefReset} onSubmit={handleReset}>
                        <Input type="email" defaultValue="" name="emailreset" placeholder="email" autoComplete="true" />                    
                        <Button loading={load} type="submit">recuperar</Button> 
                    </Form>
                :
                    <span style={{ color: '#02c054'}}>
                        RESET SENHA REALIZADO COM SUCESSO, 
                        ACESSE SEU EMAIL E CLIQUE NO LINK, PARA PROSSEGUIR.
                    </span>
                }
                <div className="minilinks">
                    <div><button onClick={() => scroll(-1000)}>[ fazer login ]</button></div>
                    <div><a href='https://navto.me'>[ quero cadastrar ]</a></div>
                </div>
            </div>  
            </div>         
        </FormLogin>
        <ImageSide />
    </Container>    
    );
}

export default SignIn;