import React, { useContext } from 'react';
import { ScrollArea } from '../../styles/global';
import { PageContext } from '../../contexts/PageContext';
import FormImages from './formImages';
import FormColors from './formColors';
import FormFonts from './formFonts';

interface TypeScreen {
    prevTheme: (e:any) => void;
}

const Design: React.FC<TypeScreen> = ({prevTheme}) => {
    const page = useContext(PageContext);

    return(<>
    {/*
    <ASide selected='design'/>
    <SectionContent openclosemenu={dash.opened}>
    <div className='simpleflex'>
        <ListPages />
        <TopUser />
    </div>
    */}
    <div className="contentpage">        
        <h1>
        Elementos do Design
        <span>VOCÊ ESTA EDITANDO A PÁGINA <a href={process.env.REACT_APP_URL_PROFILE+'/'+page.page?.url} target="_blank" rel="noreferrer" className='urlpage'>NAVTO.ME/{page.page?.url}</a></span>
        </h1>
        <ScrollArea size={63}>
        <FormImages />
        <FormColors prevTheme={(e:any) => prevTheme(e)}  />
        <FormFonts prevTheme={(e:any) => prevTheme(e)} />
        <div className="gradfooter"></div>
        </ScrollArea>

    </div>
    {/*
    </SectionContent>
    <PreviewPage previewTheme={prevTheme} />
    */}
    </>);
}


export default Design;