import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
`
export const ToggleS = styled.label`

    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;

    input[type="checkbox"] {
    display: none;
    }
    .switch {
    position: absolute;
    cursor: pointer;
    background-color: #999;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
    }
    .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
    }
    input[type="checkbox"]:checked + .switch::before {
    transform: translateX(18px);
    background-color: #fff;
    }
    input[type="checkbox"]:checked + .switch {
    background-color: #00A56E;
    }

`
export const StateActual = styled.label`
    font-family: Inconsolata;
    color: #999;
    font-size: 0.8rem;
    margin-left: 4px;
    width: 78px;
`