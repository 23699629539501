import React, {useState, useEffect, useContext} from 'react';
import TopUser from '../../components/TopUser';
import { Md5 } from 'ts-md5';
import { sha1 } from 'crypto-hash';
import { api, apiStripe } from '../../hooks/useApi';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/AuthContext';
import { ActivityIndicator } from '../../components/Elements/ActivityIndicator';


const Confirmation: React.FC = () => {

    const auth = useContext(AuthContext);
    const [load, setLoad ] = useState(true);
    
    const queryParams = new URLSearchParams(window.location.search);
    const success = queryParams.get('success');
    const plan = queryParams.get('p');
    const key = queryParams.get('session_id');

    const makehsha1 = async (string: string) => { return await sha1(string) }

    async function getDataInStripe(sessionkey: string){
        
        const { data: reqSession } = await apiStripe.get('v1/checkout/sessions/'+sessionkey);
                
        if(reqSession.payment_status === 'paid'){
            const { data } = await apiStripe.get('v1/subscriptions/'+reqSession.subscription);  
            //console.log(data);
            
            const userUpdated = {
                "idUser": auth.user?.id,
                "email": auth.user?.email, 
                "planSignature": plan,
                "dateSignature": data.current_period_start,
                "dateValSignature": data.current_period_end,
                "idCustomer": reqSession.customer,
                "idSubscription": reqSession.subscription,
                "statusSignature": data.status
            };        

            return userUpdated;
        }
        
        return false;

    }

    async function checkSuccess(){

        // descriptografando
        let date = new Date().toISOString().split('T')[0];
        let makekey = date+auth.user?.email+plan;
        makekey = makekey.replace("@", "").replace(".", "").replace(",", "").replace(" ", "");
        let sha1 = await makehsha1(makekey);
        const srcmd5 = process.env.REACT_APP_SECRET_FOR_MD5;
        sha1 = sha1+Md5.hashStr(srcmd5 ? srcmd5 : '');

        if(key !== undefined && key !== null){
            const explodeKey = key.split('nvtm');
            if(explodeKey[0] === sha1){                
                try {
                    
                    // busca dados do CHECKOUT_SESSION_ID na api da stripe
                    const newSubscribe = await getDataInStripe(explodeKey[1]);
                    
                    await api.post('/user_billingupdate.php', newSubscribe);
                    
                    if(newSubscribe){
                        const newUser = {
                            id: Number(auth.user?.id),
                            email: String(auth.user?.email),
                            planSignature: Number(plan),
                            idCustomer : newSubscribe.idCustomer,
                            idSubscription : newSubscribe.idSubscription,
                            dateSignature: new Date(newSubscribe.dateSignature * 1000).toLocaleDateString().slice(0, 19).replace('T', ' '),
                            dateValSignature: new Date(newSubscribe.dateValSignature * 1000).toLocaleDateString().slice(0, 19).replace('T', ' '),
                            statusSignature: newSubscribe.statusSignature
                        }
                        auth.updateUser({...auth.user, ...newUser});
                        setLoad(false); 
                    }
                } catch (err: any) {                                        
                    const resp = err.response?.statusText ? err.response.statusText : 'ERRO AO TENTAR ATUALIZAR, ENTRE EM CONTATO COM O SUPORTE';
                    toast.error(resp, { theme: 'colored'});                    
                }
                
            }
        } else {
            toast.error('HOUVE ALGUM ERRO NO PROCESSO POR FAVOR ENTRE EM CONTATO', { theme: 'colored'});
        }
    }

    const trynoreaload = async () => { await checkSuccess() };
    useEffect(() =>{        
        let mounted = true;
        if(mounted){
            if(success === 'true' && load){
                trynoreaload();
            }
        }
        return function cleanup() {
            mounted = false
        }
    }) // eslint-disable-line

    return(
    <>
        <div className='simpleflex minHsimpleflex'>            
            <h1 className='marg0'>
                Confirmação
                <span>Muito obrigado</span>
            </h1>
            <TopUser />
        </div>

        <div className="contentpage" style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}> 
        {!load ?
            <div style={{maxWidth: '400px', textAlign: 'center'}}>
                <span className="material-icons-round"  style={{fontSize: '4rem'}}>receipt_long</span>
                <h1 className='orangecolor'>
                    SUCESSO              
                </h1>
                <h3>Assinatura foi realizada com sucesso!</h3> 
                <p>
                Obrigado pela sua confiança em nós, aproveite os seus novos recursos e caso tenha 
                alguma dúvida ou qualquer problema entre em contato conosco agora mesmo.
                <br /><br />
                Obrigado.
                </p>
            </div>
        :
            <ActivityIndicator />
        }

            <div className="gradfooter"></div>
        </div>        
    </>
  );
}

export default Confirmation;