import {useEffect, useState} from "react";
import { toast } from 'react-toastify';
import { useApi } from "../hooks/useApi";
import { User } from "../types/User";
import { AuthContext } from "./AuthContext";


export const AuthProvider = ({children} : { children: JSX.Element}) => {

    const [user, setUser] = useState<User | null>(null);
    const api = useApi();
    
    useEffect(()=>{
        const validateToken = async () => {
            const storageData = localStorage.getItem('@navtome:token');
            if(storageData){
                const data = await api.validateToken(storageData);
                //console.log(data);
                if(data && data.user){
                    setUser(data.user);
                    if(data.user.planSignature){
                        checkPlanValidate(data.user);
                    } else {
                        setUser(data.user);
                    }
                } else {
                    setToken('');
                    window.location.href = '/';
                }
            }
        }        
        validateToken();
    }, []); //eslint-disable-line

    
    function checkPlanValidate(user: User){ 
               
        if(user.statusSignature !== 'active' && user.dateValSignature !== undefined && user.dateValSignature !== null ){
            const today = new Date(new Date().toDateString());            
            let toIso = user.dateValSignature.split("/");
            const validadePlano = new Date(parseInt(toIso[2]),parseInt(toIso[1])-1,parseInt(toIso[0]));
            if (today.getTime() <= validadePlano.getTime()) {               
                setUser(user);                
            } else {              
                setUser({...user, 'expiredSignature': true});
                
            }
        } else { 
            // se entrar aqui é pq é uma assinatura vitalicia 
            // (tem um plano associado + sem data de validade definida )
            setUser(user);
        }        
    }


    const signin = async (email:string, password:string) =>{
        try {
            const data = await api.signin(email, password);
            
            if(data.user && data.jwt){
                if(data.user.planSignature){
                    checkPlanValidate(data.user);
                } else {
                    setUser(data.user);
                }
                setToken(data.jwt);
                return true;
            } 
        } catch (error : any) {
            if(error.message === 'Network Error'){
                toast.error('Erro de conexão com o servidor, tente mais tarde', { theme: 'colored' });
            } else if(error.response.status === 401) {
                toast.error('Email ou senha incorretos, verifique seus dados', { theme: 'colored' });
            } else {
                toast.error('Erro desconhecido, tente mais tarde', { theme: 'colored' });
            }
            return false;
        }        
        return false;
    }   

    const signout = async () =>{
        //await api.logout();
        setToken('');
        setUser(null);
        window.location.href = '/';
    }

    const setToken = (token: string) => {
        localStorage.setItem('@navtome:token', token);
    }

    const updateUser = (e : User) => {
        if(e.planSignature){
            checkPlanValidate(e);
        } else {
            setUser(e);
        }
    }

    return(
        <AuthContext.Provider value={{user, signin, signout, setToken, updateUser}}>
            {children}
        </AuthContext.Provider>
    )
};